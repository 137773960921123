import { t } from 'i18next';
import { useContext, useEffect, useState } from 'react';
import { Grid } from '@mui/material';
import { Controls, Player } from '@lottiefiles/react-lottie-player';
import { Trans } from 'react-i18next';
import { ResponsiveContainer } from 'recharts';
import styles from './styles.module.scss';
import { Header } from '../Header';
import { ProcedureActionsContext } from '../../context/procedureActionsContext';
import { Button } from '../Button';
import { InputText } from '../InputText';
import ProcedureBoxService from '../../services/procedureBoxService';
import { CitationIntimation } from '../../@types/model';
import ActionsService from '../../services/actionsService';
import { QueryContext } from '../../context/queryContext';

const LOADING_ANIMATION = require('../../assets/lotties/loading.json');

export function AttachProcedureCitationIntimation() {
  const {
    proceduresSeleted, setAlert, setCitationIntimation, addToasts, setModal,
  } = useContext(ProcedureActionsContext);
  const { loadData: loadDataProcedure } = useContext(QueryContext);
  const [query, setQuery] = useState('');
  const [loading, setLoading] = useState(false);
  const [queryCitationIntimation, setQueryCitationIntimation] = useState<CitationIntimation[]>([]);
  const [selected, setSelected] = useState<CitationIntimation | undefined>();

  const unmaskProcessNumber = (processNumber: string | undefined) => {
    return processNumber?.replaceAll('.', '')?.replaceAll('-', '')?.replaceAll(' ', '');
  };

  useEffect(() => {
    if (proceduresSeleted[0]?.process_number) {
      setLoading(true);
      ProcedureBoxService.getAllCitationIntimation(`?q[process_number_cont]=${unmaskProcessNumber(proceduresSeleted[0]?.process_number)}&page=1&per_page=20`)
        .then((resp) => {
          setQueryCitationIntimation(resp);
          setLoading(false);
        });
    }
  }, []);

  const tableHeaderKeys: string[] = [
    'process_number',
    'competence',
    'exercise',
    'content',
    'subject',
  ];

  const renderRadio = (cn: CitationIntimation) => {
    return (
      <div onClick={() => { setSelected(cn); } } className={selected === cn ? styles.radioSelected : styles.radio }></div>
    );
  };

  const handleText = () => {
    return (
      <Trans
        i18nKey='procedureBox.actions.attachCitationIntimation.attach.text'
        components={{ br: <br /> }}
        values={{ number: proceduresSeleted[0]?.process_number }}
      />
    );
  };

  const handleAlert = () => {
    setAlert({
      visible: true,
      type: 'warning',
      title: t('procedureBox.actions.attachCitationIntimation.attach.title'),
      text: handleText(),
      confirmText: t('general.yes'),
      confirmType: 'primary',
      cancelText: t('general.no'),
      cancelType: 'default',
      handleConfirm: () => {
        setAlert({
          visible: true,
          title: t('procedureBox.actions.attachCitationIntimation.attach.load.title'),
          text: t('procedureBox.actions.attachCitationIntimation.attach.load.text'),
          type: 'loading',
        });
        ActionsService.attachCitationIntimation(selected as CitationIntimation, proceduresSeleted[0]).then((r) => {
          loadDataProcedure();
          setAlert(undefined);
          setCitationIntimation(undefined);
          setModal(undefined);
          addToasts({
            type: 'success',
            text: t('procedureBox.actions.attachCitationIntimation.attach.toast'),
          });
        }).catch((e) => {
          addToasts({
            type: 'error',
            text: e.data.message,
          });
          setAlert(undefined);
        });
      },
    });
  };

  const renderLoading = () => {
    return (
      <div className={styles.icon}>
        <Player
          loop={true}
          autoplay
          keepLastFrame={true}
          src={LOADING_ANIMATION}
          speed={2}
          className={styles.lottie}
        >
          <Controls visible={false} />
        </Player>
      </div>
    );
  };

  const renderTableHeader = (citationIntimations?: CitationIntimation[]) => {
    return (
      <tr>
        {citationIntimations && <td></td>}
        {tableHeaderKeys.map((headerKey, index) => {
          const fullKey = `procedureBox.actions.attachCitationIntimation.modal.${headerKey}`;
          return <td key={index}>{t(fullKey)}</td>;
        })}
      </tr>
    );
  };

  const renderTableBody = (CitationIntimations?: CitationIntimation[]) => {
    return CitationIntimations ? CitationIntimations.map((cn) => {
      return (
        <tr>
          <td>{renderRadio(cn)}</td>
          <td>{cn.process_number}</td>
          <td>{t(`procedureBox.procedureList.citationIntimation.competence.${cn.competence}`)}</td>
          <td>{cn.exercise}</td>
          <td>{cn.content}</td>
          <td>{cn.subject}</td>
        </tr>
      );
    }) : <></>;
  };

  const loadData = async () => {
    setSelected(undefined);
    const data = await ProcedureBoxService.getAllCitationIntimation(`?q[process_number_cont]=${unmaskProcessNumber(query)}&page=1&per_page=20`);
    setQueryCitationIntimation(data);
    setLoading(false);
  };

  return (
    <div className={styles.container}>
      <Header
          text={t('procedureBox.actions.attachCitationIntimation.select.title')}
          tooltip={t('procedureBox.actions.attachCitationIntimation.select.tooltip')}
        />
      <div className={styles.referenceProcedure}>
        <span className={styles.description}>
          {t('procedureBox.actions.attachCitationIntimation.select.subtitle')}
        </span>

        <Grid className={styles.searchForm}
          container
          columns={{ xs: 1, sm: 8, md: 8 }}>
          <Grid
            item
            xs={1}
            sm={2}
            md={2} >
              <InputText
                label={t('procedureBox.actions.attachCitationIntimation.select.label')}
                placeholder={t('procedureBox.actions.attachCitationIntimation.select.placeholder')}
                value={query}
                onChange={(e) => { setQuery(e.currentTarget.value); }}
                required
              />
          </Grid>
          <Button
            title={t('procedureBox.actions.attachCitationIntimation.modal.search')}
            disabled={(!query || loading)}
            buttonType='primary'
            size='flat'
            onClick={() => { setLoading(true); loadData(); }}
            round
          />
        </Grid>
        {loading ? renderLoading() : (
          <ResponsiveContainer width={'99%'} minWidth={900} minHeight={100}>
            <table>
              <thead>
                {renderTableHeader(queryCitationIntimation)}
              </thead>
              <tbody>
                {renderTableBody(queryCitationIntimation)}
              </tbody>
            </table>
        </ResponsiveContainer>)}
      </div>
      <div className={styles.button}>
        <Button
          buttonType={'primary'}
          round
          disabled={!selected}
          size='flat'
          title={t('procedureBox.actions.attachCitationIntimation.modal.button')}
          onClick={handleAlert}
        />
      </div>
    </div>
  );
}
