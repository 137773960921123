import { Grid } from '@mui/material';
import { t } from 'i18next';
import { useContext, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Button } from '../../../../components/Button';
import { Header } from '../../../../components/Header';
import { InputDate } from '../../../../components/InputDate';
import { InputText } from '../../../../components/InputText';
import { TextArea } from '../../../../components/TextArea';
import { StreetTypes } from '../../../../helpers/enums';
import FormHelper from '../../../../helpers/form';
import NewProcessService from '../../../../services/newProcessService';
import { InterestedParts } from '../InterestedParts';
import styles from './styles.module.scss';
import { ProcedureActionsContext } from '../../../../context/procedureActionsContext';
import { Errors } from '../../../../@types/config';
import Select from '../../../../components/Select';
import { ProcedureType } from '../../../../@types/processRegistration';
import Format from '../../../../helpers/format';

type NewAdministrativeProurmaProcessProps = {
  fluxId?: number;
  closeModal: () => void;
  procedure?: ProcedureType;
  isProurma?: boolean;
  procedureId?: number;
};

export function NewAdministrativeProurmaProcess(props: NewAdministrativeProurmaProcessProps) {
  const {
    handleSubmit,
    control,
    setValue,
    unregister,
  } = useForm();
  const { addToasts } = useContext(ProcedureActionsContext);
  const prourmaProps = props.procedure && props.procedure.administrative_prourma_process;

  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState<Errors | null>(null);
  const [origins, setOrigins] = useState([]);
  const [subjects, setSubjects] = useState([]);
  const [subjectsSpu, setSubjectsSpu] = useState([]);
  const [passiveOptions, setPassiveOptions] = useState([]);
  const [activeOptions, setActiveOptions] = useState([]);
  const [cityAreas, setCityAreas] = useState([]);

  const loadData = () => {
    NewProcessService.getNewAdministrativeProurmaProcessData()
      .then((res) => {
        setActiveOptions(res.data.participation_id_active);
        setPassiveOptions(res.data.participation_id_passive);
        setSubjects(res.data.subject_id);
        setOrigins(res.data.origin_procedure_id);
        setSubjectsSpu(res.data.spu_subject);
        setCityAreas(res.data.city_area);
      });
  };

  useEffect(() => {
    loadData();
    if (props.procedure) {
      const registration = props.procedure.administrative_prourma_process;
      FormHelper.setValueIfExists('process_number', registration?.process_number, setValue);
      FormHelper.setValueIfExists('origin_procedure_id', registration?.origin.value, setValue);
      FormHelper.setValueIfExists('received_at', registration?.received_at, setValue);
      FormHelper.setValueIfExists('observation', registration?.observation, setValue);
      FormHelper.setValueIfExists('cep', registration?.cep, setValue);
      FormHelper.setValueIfExists('street', registration?.street, setValue);
      FormHelper.setValueIfExists('address_number', registration?.address_number, setValue);
      FormHelper.setValueIfExists('allotment', registration?.allotment, setValue);
      FormHelper.setValueIfExists('complement', registration?.complement, setValue);
      FormHelper.setValueIfExists('subject_id', registration?.subject?.value, setValue);
      FormHelper.setValueIfExists('spu_subject_id', registration?.spu_subject?.value, setValue);
      FormHelper.setValueIfExists('city_area', registration?.city_area?.value, setValue);
      FormHelper.setValueIfExists('street_type', registration?.street_type?.value, setValue);
    }
  }, []);

  const submit = (form: any) => {
    setLoading(true);
    const data = {
      ...{ administrative_prourma_process: form },
      flux_id: props.procedure ? props.procedure.flux_id : props.fluxId,
      procedure_id: props.procedureId,
    };

    const editData = {
      ...{ administrative_prourma_process: form },
      flux_id: props.procedure ? props.procedure.flux_id : props.fluxId,
      procedure_id: props.procedure?.administrative_prourma_process.procedure_id,
    };

    if (props.procedure !== undefined) {
      NewProcessService.editAdministrativeProurmaProcess(editData)
        .then((res) => {
          if (res.errors) {
            setErrors(res.errors);
          } else {
            props.closeModal();
            addToasts({
              type: 'success',
              text: props.procedure
                ? t('procedureBox.editProcedure.toasts.success')
                : FormHelper.customToastMessage(res.id),
            });
          }
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
          if (err.status === 400) {
            setErrors(err.data);
          }
        });
    } else {
      NewProcessService.createAdministrativeProurmaProcess(data)
        .then((res) => {
          if (res.errors) {
            setErrors(res.errors);
          } else {
            props.closeModal();
            addToasts({
              type: 'success',
              text: props.procedure
                ? t('procedureBox.editProcedure.toasts.success')
                : FormHelper.customToastMessage(res.id),
            });
          }
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
          if (err.status === 400) {
            setErrors(err.data);
          }
        });
    }
  };

  return (
    <form className={ styles.container } onSubmit={handleSubmit(submit)}>
      <Header text={t('procedureBox.newProcedure.newAdministrativeProurmaProcess.form.data')}/>
      <Grid
        container
        columns={{ xs: 1, sm: 10, md: 12 }}
      >
        <Grid
          item
          xs={ 1 }
          sm={ 5 }
          md={ 4 }
          className={styles.input}
        >
          <Controller
            control={control}
            name="process_number"
            render={({ field }) => (
              <InputText
                {...field}
                label={t('procedureBox.newProcedure.newAdministrativeProurmaProcess.form.process_number.label')}
                placeholder={t('procedureBox.newProcedure.newAdministrativeProurmaProcess.form.process_number.placeholder')}
                required
              />
            )}
          />
          {FormHelper.renderErrorField('process_number', errors)}
        </Grid>

        <Grid
          item
          xs={ 1 }
          sm={ 5 }
          md={ 4 }
          className={styles.input}
        >
          <Controller
            control={control}
            name="origin_procedure_id"
            render={({ field }) => (
              <Select
                {...field}
                dataCy={'origin_procedure_id'}
                label={t('procedureBox.newProcedure.newAdministrativeProurmaProcess.form.origin_procedure_id.label')}
                options={origins}
                required
                returnValue
                defaultValue={prourmaProps && prourmaProps.origin}
              />
            )}
          />
          {FormHelper.renderErrorField('origin_procedure_id', errors)}
        </Grid>

        <Grid
          item
          xs={ 1 }
          sm={ 5 }
          md={ 4 }
          className={styles.input}
        >
          <Controller
            control={control}
            name="received_at"
            render={({ field }) => (
              <InputDate
                {...field}
                dataCy={'received_at'}
                required
                label={t('procedureBox.newProcedure.newAdministrativeProurmaProcess.form.received_at.label')}
                value={prourmaProps && Format.formatDate(prourmaProps.received_at)}
              />
            )}
          />
          {FormHelper.renderErrorField('received_at', errors)}
        </Grid>

        <Grid
          item
          xs={ 1 }
          sm={ 5 }
          md={ 4 }
          className={styles.input}
        >
          <Controller
            control={control}
            name="subject_id"
            render={({ field }) => (
              <Select
                {...field}
                dataCy="subject_id"
                label={t('procedureBox.newProcedure.newAdministrativeProurmaProcess.form.subject_id.label')}
                options={subjects}
                required={!props.isProurma}
                returnValue
                defaultValue={prourmaProps && prourmaProps.subject?.value !== null ? prourmaProps.subject : undefined}
              />
            )}
          />
          {FormHelper.renderErrorField('subject_id', errors)}
        </Grid>

        <Grid
          item
          xs={ 1 }
          sm={ 5 }
          md={ 4 }
          className={styles.input}
        >
          <Controller
            control={control}
            name="spu_subject_id"
            render={({ field }) => (
              <Select
                {...field}
                dataCy="spu_subject_id"
                label={t('procedureBox.newProcedure.newAdministrativeProurmaProcess.form.spu_subject_id.label')}
                options={subjectsSpu}
                required={!props.isProurma}
                returnValue
                defaultValue={prourmaProps && prourmaProps.spu_subject?.value !== null ? prourmaProps.spu_subject : undefined}
              />
            )}
          />
          {FormHelper.renderErrorField('spu_subject_id', errors)}
        </Grid>

        <Grid
          item
          xs={ 1 }
          sm={ 10 }
          md={ 12 }
          className={styles.input}
        >
          <Controller
            control={control}
            name="observation"
            render={({ field }) => (
              <TextArea
                {...field}
                dataCy="observation"
                required
                label={t('procedureBox.newProcedure.newAdministrativeProurmaProcess.form.observation.label')}
              />
            )}
          />
          {FormHelper.renderErrorField('observation', errors)}
        </Grid>
      </Grid>

      <Header text={t('procedureBox.newProcedure.newAdministrativeProurmaProcess.form.address')}/>

      <Grid
        container
        columns={{ xs: 1, sm: 10, md: 12 }}
      >
        <Grid
          item
          xs={ 1 }
          sm={ 5 }
          md={ 4 }
          className={styles.input}
        >
          <Controller
            control={control}
            name="cep"
            render={({ field }) => (
              <InputText
                {...field}
                dataCy="cep"
                label={t('procedureBox.newProcedure.newAdministrativeProurmaProcess.form.cep.label')}
                required={!props.isProurma}
                mask='99.999-999'
              />
            )}
          />
          {FormHelper.renderErrorField('cep', errors)}
        </Grid>

        <Grid
          item
          xs={ 1 }
          sm={ 5 }
          md={ 4 }
          className={styles.input}
        >
          <Controller
            control={control}
            name="street_type"
            render={({ field }) => (
              <Select
                {...field}
                dataCy="street_type"
                options={StreetTypes()}
                label={t('procedureBox.newProcedure.newAdministrativeProurmaProcess.form.street_type.label')}
                required={!props.isProurma}
                returnValue
                defaultValue={prourmaProps ? prourmaProps.street_type : undefined}
              />
            )}
          />
          {FormHelper.renderErrorField('street_type', errors)}
        </Grid>

        <Grid
          item
          xs={ 1 }
          sm={ 5 }
          md={ 4 }
          className={styles.input}
        >
          <Controller
            control={control}
            name="street"
            render={({ field }) => (
              <InputText
                {...field}
                dataCy="street"
                label={t('procedureBox.newProcedure.newAdministrativeProurmaProcess.form.street.label')}
                required={!props.isProurma}
              />
            )}
          />
          {FormHelper.renderErrorField('street', errors)}
        </Grid>

        <Grid
          item
          xs={ 1 }
          sm={ 5 }
          md={ 4 }
          className={styles.input}
        >
          <Controller
            control={control}
            name="address_number"
            render={({ field }) => (
              <InputText
                {...field}
                dataCy="address_number"
                label={t('procedureBox.newProcedure.newAdministrativeProurmaProcess.form.address_number.label')}
              />
            )}
          />
          {FormHelper.renderErrorField('address_number', errors)}
        </Grid>

        <Grid
          item
          xs={ 1 }
          sm={ 5 }
          md={ 4 }
          className={styles.input}
        >
          <Controller
            control={control}
            name="allotment"
            render={({ field }) => (
              <InputText
                {...field}
                dataCy="allotment"
                label={t('procedureBox.newProcedure.newAdministrativeProurmaProcess.form.allotment.label')}
              />
            )}
          />
          {FormHelper.renderErrorField('allotment', errors)}
        </Grid>

        <Grid
          item
          xs={ 1 }
          sm={ 5 }
          md={ 4 }
          className={styles.input}
        >
          <Controller
            control={control}
            name="city_area"
            render={({ field }) => (
              <Select
                {...field}
                dataCy="city_area"
                options={cityAreas}
                label={t('procedureBox.newProcedure.newAdministrativeProurmaProcess.form.city_area.label')}
                required={!props.isProurma}
                returnValue
                defaultValue={prourmaProps && prourmaProps.city_area}
              />
            )}
          />
          {FormHelper.renderErrorField('city_area', errors)}
        </Grid>

        <Grid
          item
          xs={ 1 }
          sm={ 5 }
          md={ 4 }
          className={styles.input}
        >
          <Controller
            control={control}
            name="complement"
            render={({ field }) => (
              <InputText
                {...field}
                dataCy="complement"
                label={t('procedureBox.newProcedure.newAdministrativeProurmaProcess.form.complement.label')}
              />
            )}
          />
          {FormHelper.renderErrorField('complement', errors)}
        </Grid>

        <Grid
          item
          xs={ 1 }
          sm={ 5 }
          md={ 4 }
          className={styles.input}
        >
          <Controller
            control={control}
            name="reference"
            render={({ field }) => (
              <InputText
                {...field}
                dataCy="reference"
                label={t('procedureBox.newProcedure.newAdministrativeProurmaProcess.form.reference.label')}
              />
            )}
          />
          {FormHelper.renderErrorField('reference', errors)}
        </Grid>

      </Grid>

      <InterestedParts
        passiveOptions={passiveOptions}
        activeOptions={activeOptions}
        flux={props.fluxId}
        control={control}
        setValue={setValue}
        errors={errors}
        setErrors={setErrors}
        unregister={unregister}
        procedure={props.procedure}
        isProurma={props.isProurma}
        isUniq
      />

      <div className={styles.submit}>
        <Button
          title={t('procedureBox.newProcedure.submit')}
          textCenter
          round
          buttonType='primary'
          size='flat'
          type='submit'
          disabled={loading}
          dataCy='submit'
        />
      </div>
    </form>
  );
}
