import { Grid } from '@mui/material';
import { t } from 'i18next';
import { useState, useContext, useEffect } from 'react';
import {
  FaChevronDown, FaChevronUp,
} from 'react-icons/fa';
import { Link } from 'react-router-dom';
import { Trans } from 'react-i18next';
import { Procedure } from '../../../@types/model';
import { CheckBox } from '../../CheckBox';
import { DraggableList } from '../DraggableList';
import { Markers } from '../Markers';
import { Expanded } from './Expanded';
import styles from './styles.module.scss';
import { ProcedureActionsContext } from '../../../context/procedureActionsContext';
import { Tooltip } from '../../Tooltip';
import Show from '../../Show';
import { truncate } from '../../../helpers/strings';
import { DocVisualizerButton } from '../../DocVisualizerButton';
import { TramitInfos } from '../TramitInfos';
import { AnotationsInfos } from '../AnotationsInfos';
import { Deadline } from '../../Deadline';
import { ShareInfos } from '../ShareInfos';
import { QueryContext } from '../../../context/queryContext';

type ItemProps = {
  procedure: Procedure;
  selected: boolean;
  origin: 'collection' | 'default';
};

export function Item(props: ItemProps) {
  const { addProcedure, removeProcedure } = useContext(ProcedureActionsContext);
  const { box } = useContext(QueryContext);
  const [open, setOpen] = useState(false);
  const [deadlines, setDeadlines] = useState<any>();
  const display: boolean = props.origin !== 'collection';

  let excludedItem: number;

  useEffect(() => {
    if (props.procedure?.procedure_deadlines) {
      const sortedDeadlines = props.procedure.procedure_deadlines.sort((deadlineA, deadlineB) => {
        const dateA = deadlineA.deadline ? new Date(deadlineA.deadline).getTime() : 0;
        const dateB = deadlineB.deadline ? new Date(deadlineB.deadline).getTime() : 0;
        return dateA - dateB;
      });
      setDeadlines(sortedDeadlines);
    }
  }, []);

  const selectItem = (e: any) => {
    e.stopPropagation();
    if (props.selected) {
      removeProcedure(props.procedure);
    } else {
      addProcedure(props.procedure);
    }
  };

  const getBorderPriorityClass = () => {
    switch (props.procedure.marker_priority) {
      case 'high':
        return styles.danger;
      case 'normal':
        return styles.warning;
      default:
        return 'low';
    }
  };

  const getGridProps = () => {
    if (display) {
      return { xs: 4, sm: 10, md: 16 };
    }
    return { xs: 4, sm: 10, md: 14 };
  };

  const handleInterestedParts = () => {
    if (display) {
      const interestedPartsJoined = props.procedure?.interested_parts?.map((interestedPart: string, index: number, interestedParts: string[]) => (
        index + 1 === interestedParts.length
          ? <>{interestedPart}</>
          : <>{interestedPart},<br/></>
      ));
      return (
        <Tooltip title={interestedPartsJoined}>
          {props.procedure.interested_parts.length > 1 ? (
            <span className={`${!props.procedure.was_seen && styles.unread} ${styles.interestedParts}`}>
              {t('procedureBox.procedureList.interested_parts_title', {
                first_part: props.procedure.interested_parts[0],
                interested_parts_length: props.procedure.interested_parts.length - 1,
              })}
            </span>
          ) : (
            <span className={`${!props.procedure.was_seen && styles.unread} ${styles.interestedParts}`}>{truncate(props.procedure.interested_parts[0], 20)}</span>
          )}
        </Tooltip>
      );
    }

    return <span>{props.procedure.main_field.name}</span>;
  };

  const handleScheduleCollumn = () => {
    if (display) {
      return (
        <DraggableList
          type='schedule'
          isOpen={ open }
          procedure={ props.procedure }
        />);
    }
    return (
      <span className={styles.textInfo}>
        {props.procedure.interested_parts.length === 1
          ? <Tooltip title={props.procedure.interested_parts[0]}><p className={styles.name}>{truncate(props.procedure.interested_parts[0], 20)}</p></Tooltip>
          : <span>
              <Trans
                i18nKey='procedureCollections.procedureList.interested_parts'
                components={{ bold: <strong /> }}
                values={{
                  qnt: props.procedure.interested_parts.length,
                  plural: props.procedure.interested_parts.length > 1 ? 's' : '',
                }}
              />
            </span>}
      </span>

    );
  };

  const handleDataCollumn = () => {
    if (display) {
      return (
        <DraggableList
          type='data'
          isOpen={ open }
          procedure={ props.procedure }
        />);
    }
    return (
      <span className={styles.textInfo}>
        <Tooltip title={props.procedure.subject}>
          <Trans
            i18nKey='procedureCollections.procedureList.subject'
            components={{ bold: <strong /> }}
            values={{ subject: truncate(props.procedure.subject, 20) }}
          />
        </Tooltip>
      </span>
    );
  };

  const returnDeadline = (deadlineList: any) => {
    for (let i = 0; i < deadlineList?.length; i += 1) {
      if (deadlineList[i].individual_id === props.procedure.current_individual_id || deadlineList[i].individual_id === null) {
        excludedItem = deadlineList[i].id;
        return deadlineList[i];
      }
    }
    return null;
  };

  const returnLastProductGenerate = () => {
    if (!display) {
      return (
        <span className={styles.textInfo}>
          <span>{props.procedure.last_product_generated}</span>
        </span>
      );
    }
    return <></>;
  };

  const returnInvolvedPeoples = () => {
    if (!display) {
      return (
        <Tooltip title={props.procedure.involved_people.map((ip, i, arr) => `${ip.name}${i === arr.length - 1 ? '' : ', '} `)}>
          <span className={styles.textInfo}>
            <div className={styles.involvedPeople}>{`${props.procedure.involved_people[0].name}...`}</div>
          </span>
        </Tooltip>
      );
    }
    return <></>;
  };

  return (
    <Grid
      container
      columns={getGridProps()}
      className={ `${styles.row} ${display ? !props.procedure.was_seen ? styles.unread : '' : ''}
      ${props.selected ? styles.selected : ''} ${open ? styles.open : ''} ${getBorderPriorityClass()}` }
      onClick={() => setOpen(!open)}
    >
      <Grid
        item
        className={ styles.procedureData }
        xs={ 2 }
        sm={ 4 }
        md={ 3 }
      >
        <div
          id='processCheckBox'
          className={ styles.selectIcon }
          onClick={ (e) => selectItem(e) }
        >
          <CheckBox
            value={props.selected}
            uncheckedColor='var(--dark)'
            color='var(--primary)'
            isMarker={false}
          />
        </div>
        <span className={styles.numeric}>Nº</span>
        <Link
          className={ styles.linkProcedure }
          to={`/procedure_box/show_procedure/${props.procedure.id}`}
          data-cy={'cypress-process-number-link'}
        >
          {props.procedure.process_number}
        </Link>
        {!open ? <Markers markers={props.procedure.markers} procedureId={props.procedure.id} /> : null}
      </Grid>
      <Grid
        onClick={ open ? (e) => e.stopPropagation() : () => {} }
        item
        className={ `${styles.value} ${!display ? styles.valueCollection : ''}` }
        sm={ 3 }
        md={ display ? 3 : 2 }
        sx={{ display: { xs: 'none', sm: 'block', md: 'block' } }}
      >
        { handleDataCollumn() }
      </Grid>
      <Grid
        onClick={ open ? (e) => e.stopPropagation() : () => {} }
        item
        className={ `${styles.value} ${!display ? styles.valueCollection : ''}` }
        sm={ display ? 3 : 2 }
        md={ display ? 3 : 2 }
        sx={{ display: { xs: 'none', sm: 'none', md: 'block' } }}
      >
        { handleScheduleCollumn() }
      </Grid>
      <Show if={!display}>
        <Grid
          item
          className={ `${styles.value} ${!display ? styles.valueCollection : ''}` }
          sm={ display ? 3 : 2 }
          md={ 2 }
          sx={{ display: { xs: 'none', sm: 'none', md: 'block' } }}
        >
          { returnLastProductGenerate() }
        </Grid>
      </Show>
      <Grid
        item
        className={ `${styles.value} ${!display ? styles.valueCollection : ''}` }
        sm={ display ? 3 : 2 }
        md={ 2 }
        sx={{ display: { xs: 'none', sm: 'none', md: 'block' } }}
      >
        { handleInterestedParts() }
      </Grid>
      <Show if={!display}>
        <Grid
          item
          className={ `${styles.value} ${!display ? styles.valueCollection : ''}` }
          sm={ display ? 3 : 2 }
          md={ 2 }
          sx={{ display: { xs: 'none', sm: 'none', md: 'block' } }}
        >
          { returnInvolvedPeoples() }
        </Grid>
      </Show>
      <Show if={display}>
        <Grid
          item
          className={ `${styles.value} ${styles.deadlineValue} ${!display ? styles.valueCollection : ''}` }
          sm={ 3 }
          md={ 3 }
          sx={{ display: { xs: 'none', sm: 'none', md: 'block' } }}
        >
          <Deadline
            procedure={props.procedure}
            procedureDeadline={
              returnDeadline(deadlines)
            }
          />
        </Grid>
      </Show>
      <Grid
        item
        xs={ display ? 2 : 1 }
        sm={ display ? 3 : 1 }
        md={ display ? 2 : 1 }
        sx={{ display: { xs: 'flex', sm: 'flex', md: 'flex' } }}
        className={ styles.actions }
      >
        <Show if={display}>
          <Show if={box !== 'sharedbox'}>
            <TramitInfos
              instruction={props.procedure.last_tramit}
              procedureId={props.procedure.id}
            />
          </Show>
          <Show if={box === 'sharedbox'}>
            <ShareInfos
              procedure={props.procedure}
              shareList={props.procedure.shared_people}
              box='sharedbox'
            />
          </Show>

          <AnotationsInfos
            notes={props.procedure.procedure_notes || []}
            procedure={props.procedure}
          />
          <DocVisualizerButton procedure={props.procedure} />
          <Show if={props.procedure.shared_people.length > 0 && (box === 'inbox' || box === '')}>
            <ShareInfos
              procedure={props.procedure}
              shareList={props.procedure.shared_people}
              box={box || 'inbox'}
            />
          </Show>
        </Show>
        <div className={styles.container}>
          {open ? <FaChevronUp className={styles.chevron} /> : <FaChevronDown className={styles.chevron} />}
        </div>
      </Grid>
      { open ? <Expanded procedure={ props.procedure } display={display} deadlines={deadlines.filter((item: any) => item.id !== excludedItem)} /> : null }
    </Grid>
  );
}
