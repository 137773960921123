import {
  Autocomplete,
  FormControl,
  MenuItem,
  TextField,
} from '@mui/material';
import {
  FaChevronDown, FaRegQuestionCircle,
} from 'react-icons/fa';
import { useState } from 'react';
import MenuSelect from '@mui/material/Select';
import { t } from 'i18next';
import styles from './styles.module.scss';
import { Label } from '../Label';
import { Tooltip } from '../Tooltip';

type SelectProps = {
  dataCy?: string;
  options: OptionType[];
  className?: string;
  placeholder?: string;
  label: string;
  tooltip?: string;
  value?: OptionType;
  disabled?: boolean;
  defaultValue?: OptionType;
  onChange?: (value: OptionType | null) => void;
  required?: boolean;
  returnValue?: boolean;
  urlToUpdate?: (input: string) => Promise<any>;
  labelBold?: boolean;
  onlySelect?: boolean;
  noOptionsMessage?: string;
};

type OptionType = {
  value: any,
  label: string,
};

type Response = {
  label: string;
  value: string | number;
};

export default function Select(props: SelectProps) {
  const [options, setOptions] = useState(props.options);

  const onChange = (el: any, val: any) => {
    if (props.onChange) {
      if (props.returnValue) {
        props.onChange(val?.value);
      } else {
        props.onChange(val);
      }
    }
  };

  const handleUpdateList = async (value: string) => {
    if (props.urlToUpdate) {
      if (value.length > 2) {
        setOptions([]);
        const response: Response[] = await props.urlToUpdate(value);
        setOptions(response.map((p: Response) => { return { label: p.label, value: p.value }; }));
      } else {
        setOptions([]);
      }
    }
  };

  const handleChange = (event: any) => {
    if (props.onChange) {
      if (props.returnValue) {
        props.onChange(event.target.value);
      } else {
        props.onChange(event);
      }
    }
  };

  return (
    <div className={`${styles.container} ${props.className}`}>
      {
        props.onlySelect
          ? (
            <FormControl
              className={styles.inputSelect}
              data-cy={props.dataCy}
              variant={'standard'}
              size="small"
              fullWidth
            >
              <MenuSelect
                onChange={handleChange}
                disableUnderline
                defaultValue={props.options[0]?.value}
                IconComponent={(iconProps: any) => (
                  <div className={styles.customIcon}>
                    <FaChevronDown {...iconProps} />
                  </div>
                )}
                renderValue={(value: string) => {
                  const optionLabel = props.options.find((option) => option.value === value)?.label;
                  return <span className={styles.optionCustom}>{optionLabel}</span>;
                }}
                classes={{
                  select: styles.select,
                }}
              >
                {
                  props.options.map((option, index) => (
                    <MenuItem
                      value={option.value}
                      className={styles.optionCustom}
                      key={(Math.random() + index).toString(36).substring(1)}
                    >
                        {option.label}
                    </MenuItem>
                  ))
                }
              </MenuSelect>
            </FormControl>
          ) : (
            <>
              <div className={ styles.info }>
                {props.label && <Label text={props.label} required={props.required} bold={props.labelBold} />}
                {props.tooltip
                  ? <Tooltip title={ props.tooltip }>
                      <div className={styles.containerTag}>
                        <FaRegQuestionCircle />
                      </div>
                    </ Tooltip>
                  : null}
              </div>
              <Autocomplete
                options={props.urlToUpdate ? options : props.options}
                disabled={props.disabled}
                defaultValue={props.defaultValue}
                getOptionLabel={(option) => option.label}
                disableListWrap
                popupIcon={props.urlToUpdate ? null : <FaChevronDown size={13}/>}
                classes={{
                  input: `${styles.input} ${styles.inputGroup} ${props.disabled ? styles.disabled : ''}`,
                  inputRoot: styles.inputRoot,
                  option: styles.option,
                  popper: styles.popper,
                }}
                value={ props.value }
                onChange={onChange}
                noOptionsText={ props.noOptionsMessage
                  ? props.noOptionsMessage
                  : t('general.emptyOptions')}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder={props.placeholder}
                    disabled={props.disabled}
                    required={props.required}
                    onChange={(event) => handleUpdateList(event.target.value)}
                    inputProps={{
                      ...params.inputProps,
                      'data-cy': props.dataCy,
                    }}
                  />
                )}
              />
            </>
          )
      }
    </div>
  );
}
