import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './styles.module.scss';
import { QueryContext } from '../../context/queryContext';
import { ItemType, MenuSelect } from '../MenuSelect';

export function SortMenu() {
  const { setOrderBy, orderItem, setOrderItem } = useContext(QueryContext);
  const { t } = useTranslation();
  const itens: ItemType[] = [
    {
      id: 'mostUpdatedAt',
      query: 'updated_at desc',
      translated: `${t('procedureBox.sortMenu.mostUpdatedAt')}`,
      selected: orderItem === 'mostUpdatedAt',
    },
    {
      id: 'olderUpdatedAt',
      query: 'updated_at asc',
      translated: `${t('procedureBox.sortMenu.olderUpdatedAt')}`,
      selected: orderItem === 'olderUpdatedAt',
    },
    {
      id: 'mostRecentCreated',
      query: 'created_at desc',
      translated: `${t('procedureBox.sortMenu.mostRecentCreated')}`,
      selected: orderItem === 'mostRecentCreated',
    },
    {
      id: 'olderCreated',
      query: 'created_at asc',
      translated: `${t('procedureBox.sortMenu.olderCreated')}`,
      selected: orderItem === 'olderCreated',
    },
    {
      id: 'mostRecent',
      query: 'individual_update desc',
      translated: `${t('procedureBox.sortMenu.mostRecent')}`,
      selected: orderItem === 'mostRecent',
    },
    {
      id: 'older',
      query: 'individual_update asc',
      translated: `${t('procedureBox.sortMenu.older')}`,
      selected: orderItem === 'older',
    },
    {
      id: 'mostRecentDeadline',
      query: 'procedure_deadlines.deadline desc',
      translated: `${t('procedureBox.sortMenu.mostRecentDeadline')}`,
      selected: orderItem === 'mostRecentDeadline',
    },
    {
      id: 'olderDeadline',
      query: 'procedure_deadlines.deadline asc',
      translated: `${t('procedureBox.sortMenu.olderDeadline')}`,
      selected: orderItem === 'olderDeadline',
    },
    {
      id: 'unread',
      query: 'was_seen asc',
      translated: `${t('procedureBox.sortMenu.unread')}`,
      selected: orderItem === 'unread',
    },
    {
      id: 'read',
      query: 'was_seen desc',
      translated: `${t('procedureBox.sortMenu.read')}`,
      selected: orderItem === 'read',
    },
    {
      id: 'lowerValue',
      query: 'value asc',
      translated: `${t('procedureBox.sortMenu.lowerValue')}`,
      selected: orderItem === 'lowerValue',
    },
    {
      id: 'highestValue',
      query: 'value desc',
      translated: `${t('procedureBox.sortMenu.highestValue')}`,
      selected: orderItem === 'highestValue',
    },
  ];

  const onChange = (item: ItemType) => {
    setOrderBy(item.query);
    setOrderItem(item.id);
  };

  return (
    <div className={`${styles.sortMenuContainer}`}>
      <span className={`${styles.title}`}>{t('procedureBox.sortMenu.title')}</span>
      <MenuSelect itens={itens} onChange={(item) => onChange(item)} chevron/>
    </div>
  );
}
