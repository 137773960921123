import { useState, useContext } from 'react';
import moment from 'moment';
import {
  FaCheckSquare,
} from 'react-icons/fa';
import { Trans } from 'react-i18next';
import { t } from 'i18next';
import styles from './styles.module.scss';
import { Procedure, ProcedureDeadline } from '../../@types/model';
import { ProcedureActionsContext } from '../../context/procedureActionsContext';
import { Tooltip } from '../Tooltip';

type DeadlineProps = {
  procedure?: Procedure;
  procedureDeadline?: ProcedureDeadline | undefined;
  action?: boolean;
  setLoading?: (value: boolean) => void;
  styleButton?: string;
};

export function Deadline(props: DeadlineProps) {
  const { openDeadline } = useContext(ProcedureActionsContext);
  const [checked, setChecked] = useState(Boolean(props.procedureDeadline?.was_seen));
  const value = () => {
    return props.procedureDeadline
      ? props.procedureDeadline.deadline
      : null;
  };

  const getColor = () => {
    if (value()) {
      const date = moment(value());
      const today = moment().endOf('day');
      const deadline = moment(date.format('YYYY-MM-DD')).endOf('day');

      const difference = deadline.diff(today, 'days', false);
      if (checked) {
        return styles.success;
      }
      if (difference < 0) {
        return styles.danger;
      }
      if (difference <= 3) {
        return styles.warning;
      }
    }
    return '';
  };

  const handleText = () => {
    if (props.procedureDeadline) {
      const message = <Trans
      i18nKey='procedureBox.procedureDeadline.tooltip'
      values={{
        reason: props.procedureDeadline.reason,
        title: props.procedureDeadline.title,
        description: props.procedureDeadline.description,
      }}
      components={{ bold: <strong /> }}
    />;
      return message;
    }
    return '';
  };

  const renderInput = () => {
    return (
      <div className={ styles.inputDeadline }>
        <div className={ styles.deadlineItem } onClick={() => openDeadline(
          props.procedureDeadline,
          props.procedure,
        )}>
          <Tooltip title={handleText()} placement={'bottom'}>
            <div className={`${styles.deadlineInfo} ${!props.procedure?.was_seen && styles.deadlineNotRead}`}>
              <strong>
                {props.procedureDeadline
                  ? `${props.procedureDeadline?.reason}:`
                  : t('procedureBox.procedureDeadline.deadline')}
              </strong>
              <u>
                {value()
                  ? moment(value(), 'YYYY-MM-DD').format('DD/MM/YY')
                  : ' - / - /  - '}
              </u>
            </div>
          </Tooltip>
        </div>
        <div className={ styles.checkIcon }>
          <FaCheckSquare onClick={() => setChecked(!checked)}/>
        </div>
      </div>
    );
  };

  return (
    <div
      className={ `${!props.action ? styles.container : ''} ${getColor()}` }
      onClick={(e) => e.stopPropagation()}
    >
      { renderInput() }
    </div>
  );
}
