import { t } from 'i18next';
import {
  FaChevronDown,
  FaClock,
  FaArchive,
  FaFileAlt,
  FaFolder,
  FaHands,
  FaPaperclip,
  FaPaperPlane,
  FaPenNib,
  FaTag,
  FaTrashAlt,
  FaBoxOpen,
  FaStickyNote,
  FaListAlt,
  FaLink,
  FaSitemap,
  FaTimes,
  FaShareAlt,
  FaShoePrints,
} from 'react-icons/fa';
import { BsShuffle } from 'react-icons/bs';
import { useContext, useState, useEffect } from 'react';
import Menu from '@mui/material/Menu';
import {
  ThemeProvider,
} from '@mui/material/';
import styles from './styles.module.scss';
import { Icon } from './Icon';
import { menuTheme } from '../../../../styles/customThemes/menuTheme';
import { ProcedureActionsContext } from '../../../../context/procedureActionsContext';
import Show from '../../../Show';
import { isVisible } from '../../../../helpers/actionsMenu';
import { SubProcedure } from '../SubProcedure';
import { checkSendSpu } from '../../../../helpers/spu';
import { judicialFluxes } from '../../../../helpers/menuItems';
import { Procedure } from '../../../../@types/model';
import { isProcedureCollection } from '../../../../helpers/routeTranslate';
import ProcedureBoxService from '../../../../services/procedureBoxService';

type ResponsiveMenuProps = {
  procedure?: Procedure;
};

export function ResponsiveMenu(props: ResponsiveMenuProps) {
  const {
    openSigner,
    openTramit,
    openCreateDocument,
    openChangeFlux,
    openAttachFile,
    openDeleteFile,
    openArchiveProcesses,
    openDeleteProcesses,
    handleForceTramit,
    openForwardIpm,
    openSpu,
    openNotesModal,
    openJoinDocuments,
    openMarkerModal,
    openInitialPetition,
    openIntermediaryPetition,
    openMultipleActions,
    proceduresSeleted,
    openAttachProcedure,
    openDeadline,
    openDistributeProcesses,
    openShareProcedure,
    openRegistrationSpu,
    openEditProcedure,
    openUnarchiveModal,
    openCosigner,
    openReadyForProgress,
  } = useContext(ProcedureActionsContext);
  const box = proceduresSeleted[0]?.box;
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const [disabledInitialPetition, setDisabledInitialPetition] = useState(true);
  const [disabledDeadline, setDisabledDeadline] = useState(true);
  const [disabledIntermediatePetition, setDisabledIntermediatePetition] = useState(true);
  const disabledCosigned = true;

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleOpenSpu = () => {
    if (proceduresSeleted.length === 1 && checkSendSpu(proceduresSeleted[0].flux.code)) {
      openSpu(undefined);
    }
  };

  const handleOpenRegistrationSpu = () => {
    if (proceduresSeleted.length === 1 && checkSendSpu(proceduresSeleted[0].flux.code)) {
      openRegistrationSpu(undefined);
    }
  };

  const getCurrentProcedure = () => {
    return props.procedure || proceduresSeleted[0];
  };

  const handleDeleteProcesses = () => {
    if (proceduresSeleted.length === 1) {
      openDeleteProcesses();
    }
  };

  const verifyFluxes = () => {
    const filingFlux = props.procedure?.flux.code === 'FLU0020';
    const executionFlux = props.procedure?.flux.code === 'FLU0003';

    if (filingFlux || proceduresSeleted[0]?.flux.code === 'FLU0020') {
      setDisabledInitialPetition(false);
    } else {
      setDisabledInitialPetition(true);
    }

    if (executionFlux || proceduresSeleted[0]?.flux.code === 'FLU0003') {
      setDisabledIntermediatePetition(false);
    } else {
      setDisabledIntermediatePetition(true);
    }
  };

  const showDeadline = (boxParam: string) => {
    return boxParam === 'inbox' || proceduresSeleted.length === 1;
  };

  useEffect(() => {
    const procedureIds = proceduresSeleted.map((procedure: Procedure) => procedure.id);

    verifyFluxes();

    ProcedureBoxService.verifyPetitionInPje(procedureIds).then((res: boolean) => {
      setDisabledInitialPetition(res);
    });

    if (proceduresSeleted.length === 1) {
      ProcedureBoxService.canViewDeadline(proceduresSeleted[0].id, proceduresSeleted[0].box).then((res: boolean) => {
        setDisabledDeadline(res);
      });
    }
  }, [proceduresSeleted]);

  const isDisabled = (disabled: boolean) => {
    return disabled ? styles.disabledItem : '';
  };

  return (
    <div
      className={`${styles.container}`}
      onClick={open ? handleClose : handleClick}
    >
      <ThemeProvider theme={menuTheme}>
      <span className={`${open && styles.selectedItem}`}>{t('procedureBox.actions.title')}</span>
      <FaChevronDown className={ styles.chevron } />
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        <Show if={isVisible(box, 'multipleActions')}>
          <div className={`${styles.itemOption}`} onClick={() => openMultipleActions()} data-cy={'cypress-button-deadline-menu'}>
            <FaListAlt />
            <p>{t('procedureBox.actions.multipleActions.title')}</p>
          </div>
        </Show>
        <Show if={isVisible(box, 'deadline') && showDeadline(box)}>
          <div
            onClick={() => disabledDeadline && openDeadline(undefined, getCurrentProcedure())}
            className={`${styles.itemOption} ${isDisabled(!disabledDeadline)}`}
          >
            <FaClock />
            <p>{t('procedureBox.actions.deadline.title')}</p>
          </div>
        </Show>
        <Show if={isVisible(box, 'addOrRemoveMarker')}>
          <div className={`${styles.itemOption}`} onClick={() => openMarkerModal('create')}>
            <FaTag />
            <p>{t('procedureBox.actions.add_or_remove_marker.title')}</p>
          </div>
        </Show>
        <hr className={`${styles.solid}`} />
        <Show if={isVisible(box, 'notes')}>
          <div className={`${styles.itemOption}`} onClick={() => openNotesModal()}>
            <FaStickyNote />
            <p>{t('procedureBox.actions.notes.title')}</p>
          </div>
        </Show>
        <Show if={isVisible(box, 'tramit')}>
          <div className={`${styles.itemOption}`} onClick={() => openTramit(undefined)} data-cy={'cypress-menu-button-tramit'}>
            <FaPaperPlane />
            <p>{t('procedureBox.actions.tramit.title')}</p>
          </div>
        </Show>
        <Show if={isVisible(box, 'distributeProcess') || isProcedureCollection(window.location.pathname)}>
          <div className={`${styles.itemOption}`} onClick={() => openDistributeProcesses(undefined)}>
            <FaSitemap/>
            <p>{t('procedureBox.actions.distributeProcesses.title')}</p>
          </div>
        </Show>
        <Show if={isVisible(box, 'shareProcedure')}>
          <div className={`${styles.itemOption}`} onClick={() => openShareProcedure()}>
            <FaShareAlt/>
            <p>{t('procedureBox.actions.shareProcedure.title')}</p>
          </div>
        </Show>
        <Show if={isVisible(box, 'forceTramit')}>
          <div className={`${styles.itemOption}`} onClick={() => handleForceTramit()}>
            <FaHands />
            <p>{t('procedureBox.actions.forceTramit.title')}</p>
          </div>
        </Show>
        <Show if={isVisible(box, 'prepareDocument')}>
          <div className={`${styles.itemOption}`} onClick={() => openCreateDocument(undefined)}>
            <FaFileAlt />
            <p>{t('procedureBox.actions.prepareDocument.title')}</p>
          </div>
        </Show>
        <Show if={isVisible(box, 'attachFiles')}>
          <div className={`${styles.itemOption}`} onClick={() => openAttachFile()}>
            <FaPaperclip />
            <p>{t('procedureBox.actions.attachFiles.title')}</p>
          </div>
        </Show>
        <Show if={isVisible(box, 'signDocument')}>
          <hr className={`${styles.solid}`} />
          <div className={`${styles.itemOption}`} onClick={() => openReadyForProgress('signer', openSigner)}>
            <FaPenNib />
            <p>{t('procedureBox.actions.signer.signer')}</p>
          </div>
          <div
            className={`${styles.itemOption} ${styles.disabledItem}`}
            onClick={() => !disabledCosigned && openReadyForProgress('cosigner', openCosigner)}>
            <FaPenNib />
            <p>{t('procedureBox.actions.signer.cosigner')}</p>
          </div>
          <hr className={`${styles.solid}`} />
        </Show>
        <Show if={isVisible(box, 'attachProcedure')}>
          <div className={`${styles.itemOption}`} onClick={() => openAttachProcedure()}>
            <FaLink />
            <p>{t('procedureBox.actions.attach_procedure.title')}</p>
          </div>
        </Show>
        <Show if={isVisible(box, 'removeDocument')}>
          <div className={`${styles.itemOption}`} onClick={() => openDeleteFile(undefined)}>
            <FaTrashAlt />
            <p>{t('procedureBox.actions.removeDocument.title')}</p>
          </div>
        </Show>
        <Show if={isVisible(box, 'unArchive')}>
          <div className={`${styles.itemOption}`} onClick={() => openUnarchiveModal()}>
            <FaBoxOpen />
            <p>{t('procedureBox.actions.unArchive.title')}</p>
          </div>
        </Show>
        <Show if={isVisible(box, 'archive')}>
          <div className={`${styles.itemOption}`} onClick={() => openArchiveProcesses()}>
            <FaArchive />
            <p>{t('procedureBox.actions.archive.title_procedure')}</p>
          </div>
        </Show>
        <Show if={isVisible(box, 'delete')}>
          <div
            className={`
              ${styles.itemOption}
              ${proceduresSeleted.length > 1 && styles.disabledItem}`
            }
            onClick={() => handleDeleteProcesses()}>
            <FaTimes />
            <p>{t('procedureBox.actions.delete.title_procedure')}</p>
          </div>
        </Show>
        <Show if={isVisible(box, 'joinDocuments')}>
          <div className={`${styles.itemOption}`} onClick={() => openJoinDocuments(undefined)}>
            <FaFolder />
            <p>{t('procedureBox.actions.joinDocuments.title')}</p>
          </div>
        </Show>
        <Show if={isVisible(box, 'editProcedure')}>
          <div className={`${styles.itemOption}`} onClick={() => openEditProcedure()}>
            <FaShoePrints />
            <p>{t('procedureBox.actions.editProcedure.title')}</p>
          </div>
        </Show>
        <Show if={isVisible(box, 'changeFlux')}>
          <div className={`${styles.itemOption}`} onClick={() => openChangeFlux()}>
            <BsShuffle />
            <p>{t('procedureBox.actions.changeFlux.title')}</p>
          </div>
        </Show>
        <Show if={isVisible(box, 'createSubprocess')}>
          <SubProcedure
            responsive
            onlyRequestCalculation={judicialFluxes.slice(0, -1).includes(getCurrentProcedure().flux.code)}
            disabled={proceduresSeleted.length > 1 || !judicialFluxes.includes(getCurrentProcedure().flux.code)}
          />
        </Show>
        <Show if={isVisible(box, 'court')}>
          <hr className={`${styles.solid}`} />
          <div
            className={`${styles.itemOption} ${isDisabled(disabledInitialPetition)}`}
            onClick={() => !disabledInitialPetition && openInitialPetition()}>
            <Icon text='court' />
            <p>{t('procedureBox.actions.court.initialPetition')}</p>
          </div>
          <div
            className={`${styles.itemOption} ${isDisabled(disabledIntermediatePetition)}`}
            onClick={() => !disabledIntermediatePetition && openIntermediaryPetition()}>
            <Icon text='court' />
            <p>{t('procedureBox.actions.court.intermediatePetition')}</p>
          </div>
        </Show>
        <Show if={isVisible(box, 'spu')}>
          <div
            className={`
              ${styles.itemOption}
              ${proceduresSeleted.length > 1 || !checkSendSpu(getCurrentProcedure().flux.code || '') ? styles.disabledItem : ''}`
            }
            onClick={() => handleOpenRegistrationSpu()}
          >
            <Icon text='spu' />
            <p>{t('procedureBox.actions.spu.registrationOficialLetter')}</p>
          </div>
          <div
            className={`
            ${styles.itemOption}
            ${proceduresSeleted.length > 1 || !checkSendSpu(getCurrentProcedure().flux.code || '') ? styles.disabledItem : ''}`
          }
            onClick={() => handleOpenSpu()}
          >
            <Icon text='spu' />
            <p>{t('procedureBox.actions.spu.sendOficialLetter')}</p>
          </div>
        </Show>
        <Show if={isVisible(box, 'ipm')}>
          <div className={`${styles.itemOption}`}>
            <Icon text='ipm' />
            <p>{t('procedureBox.actions.ipm.requestDueDiligence')}</p>
          </div>
          <div className={`${styles.itemOption}`} onClick={openForwardIpm}>
            <Icon text='ipm' />
            <p>{t('procedureBox.actions.ipm.forwardIPM.text')}</p>
          </div>
        </Show>
      </Menu>
      </ThemeProvider>
    </div>
  );
}
