import { HolidaysResponse } from '../@types/schedule';
import Axios from '../config/api';

export default class ScheduleService {
  public static async getAll(query = '', card = true) {
    return Axios.get(`api/schedules${query ? `?${query}` : query}`, { params: { card } })
      .then((res) => res.data);
  }

  public static async getAllReason() {
    return Axios.get('api/schedules/reasons/')
      .then((res) => res.data);
  }

  public static async getAllHolidays() {
    return Axios.get<HolidaysResponse>('api/schedules/holidays')
      .then((res) => res.data);
  }
}
