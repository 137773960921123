import {
  DragDropContext, DropResult,
} from 'react-beautiful-dnd';
import { t } from 'i18next';
import { useState, useEffect, useContext } from 'react';
import { Trans } from 'react-i18next';
import { SelectedProcedures } from '../SelectedProcedures';
import styles from './styles.module.scss';
import { Section } from './Section';
import { Action, MultipleAction, MultipleActionStep } from '../../@types/backgroundActions';
import { Button } from '../Button';
import { initialData } from './data';
import { isVisible } from '../../helpers/actionsMenu';
import { ProcedureActionsContext } from '../../context/procedureActionsContext';
import { Procedure } from '../../@types/model';

type MultipleActionsProps = {
  closeModal: () => void;
  procedure?: Procedure;
};

type CheckActionsList = {
  stepName: string;
  error: boolean;
};

export function MultipleActions(props: MultipleActionsProps) {
  const { proceduresSeleted, setAlert, handleMultipleActions } = useContext(ProcedureActionsContext);
  const [sourceActions, setSourceActions] = useState<MultipleAction[]>([]);
  const [selectedActions, setSelectedActions] = useState<MultipleAction[]>([]);
  const [disabled, setDisabled] = useState(true);

  useEffect(() => {
    const data = initialData.filter((obj) => {
      if (proceduresSeleted.length > 1 && (obj.id === 'spu' || obj.id === 'registrationSpu')) {
        return false;
      }
      return isVisible(
        props.procedure?.box || proceduresSeleted[0].box,
        obj.id,
      );
    });
    setSourceActions(data);
  }, []);

  const onDragEnd = (result: DropResult) => {
    const { destination, source } = result;

    if (!destination) {
      return;
    }

    if (
      destination.droppableId === source.droppableId
      && destination.index === source.index
    ) {
      return;
    }

    let add;
    const active = sourceActions;
    const complete = selectedActions;

    if (source.droppableId === 'sourceList') {
      add = active[source.index];
      active.splice(source.index, 1);
    } else {
      add = complete[source.index];
      complete.splice(source.index, 1);
    }

    if (destination.droppableId === 'sourceList') {
      active.splice(destination.index, 0, add);
    } else {
      complete.splice(destination.index, 0, add);
    }
    setDisabled(complete.length < 1);
    setSelectedActions(complete);
    setSourceActions(active);
  };

  const renderTitle = () => {
    if (proceduresSeleted.length > 1) {
      return (
        <Trans
          i18nKey='procedureBox.actions.multipleActions.modal.titleManyProcedures'
          values={{ number: proceduresSeleted.length }}
        />
      );
    }
    return (
      <Trans
        i18nKey='procedureBox.actions.multipleActions.modal.title'
        values={{ number: proceduresSeleted[0].process_number }}
      />
    );
  };

  const renderText = () => {
    const result = selectedActions.map((a) => t(a.label));
    return (
      <Trans
        i18nKey='procedureBox.actions.multipleActions.modal.text'
        values={{ actions: result.join(' > ') }}
      />
    );
  };

  const handleConfirm = () => {
    const data: Action[] = selectedActions.map((i) => {
      const nextAction = selectedActions[selectedActions.indexOf(i) + 1];
      return {
        name: i.id,
        nextStep: nextAction ? nextAction.id : '',
      };
    });
    const currentStep = selectedActions[0].id;
    const multiAction: MultipleActionStep = {
      data,
      currentStep,
    };
    setAlert(undefined);
    props.closeModal();
    handleMultipleActions(multiAction, currentStep);
  };

  const showAlert = () => {
    setAlert({
      visible: true,
      handleConfirm: () => handleConfirm(),
      title: renderTitle(),
      text: renderText(),
    });
  };

  const showAlertError = (action: string) => {
    setAlert({
      visible: true,
      type: 'error',
      title: t('procedureBox.actions.multipleActions.modal.error'),
      text: <Trans
        i18nKey={'procedureBox.actions.multipleActions.modal.errorMsg'}
        components={{ bold: <strong /> }}
        values={{ action }}
      />,
    });
  };

  const checkActionsPositon = (): CheckActionsList => {
    const selectedActionsSize = selectedActions.length;
    const checkTramit = selectedActions.find((i) => i.id === 'tramit');
    if (checkTramit) {
      if (selectedActions.indexOf(checkTramit) + 1 < selectedActionsSize) {
        return {
          stepName: t(checkTramit.label),
          error: true,
        };
      }
    }
    const checkArchive = selectedActions.find((i) => i.id === 'archive');
    if (checkArchive) {
      if (selectedActions.indexOf(checkArchive) + 1 < selectedActionsSize) {
        return {
          stepName: t(checkArchive.label),
          error: true,
        };
      }
    }
    const checkForceTramit = selectedActions.find((i) => i.id === 'forceTramit');
    if (checkForceTramit) {
      if (selectedActions.indexOf(checkForceTramit) + 1 < selectedActionsSize) {
        return {
          stepName: t(checkForceTramit.label),
          error: true,
        };
      }
    }
    const checkUnarchive = selectedActions.find((i) => i.id === 'unArchive');
    if (checkUnarchive) {
      if (selectedActions.indexOf(checkUnarchive) + 1 < selectedActionsSize) {
        return {
          stepName: t(checkUnarchive.label),
          error: true,
        };
      }
    }
    return {
      stepName: '',
      error: false,
    };
  };

  const handleSubmit = () => {
    const verify = checkActionsPositon();
    if (verify.error) {
      showAlertError(verify.stepName);
    } else {
      showAlert();
    }
  };

  return (
    <div className={ styles.container }>
      <SelectedProcedures />
      <DragDropContext onDragEnd={onDragEnd}>
        <Section
          sourceActions={sourceActions}
          setSourceActions={setSourceActions}
          selectedActions={selectedActions}
          setSelectedActions={setSelectedActions}
        />
      </DragDropContext>
      <div className={styles.footer}>
        <Button
          title={t('procedureBox.actions.multipleActions.submit')}
          textCenter
          round
          buttonType='primary'
          size='flat'
          disabled={disabled}
          onClick={() => handleSubmit()}
        />
      </div>
    </div>
  );
}
