import { useContext } from 'react';
import moment from 'moment';
import { t } from 'i18next';
import { ProcedureDeadline } from '../../../@types/schedule';
import { ProcedureActionsContext } from '../../../context/procedureActionsContext';
import styles from './styles.module.scss';
import Show from '../../../components/Show';
import { Button } from '../../../components/Button';
import { ShowAttribute } from './ShowAttribute';

type ShowDeadlineProps = {
  data: ProcedureDeadline[];
  dateSelected: string;
  deadline?: ProcedureDeadline;
};

export function ShowDeadline(props: ShowDeadlineProps) {
  const { openDeadline, removeDeadlineProcedures, setAlert } = useContext(ProcedureActionsContext);
  const i18nkey = 'schedule.deadline';

  const showAlert = (procedureParam: ProcedureDeadline) => {
    setAlert({
      visible: true,
      handleConfirm: () => removeDeadlineProcedures([procedureParam.procedure[0]], procedureParam.id),
      title: t(`${i18nkey}.destroy.warning`),
    });
  };

  return (
    <div className={styles.containerDeadline}>
      <div className={styles.deadline}>{t(`${i18nkey}.details`, { date: props.dateSelected })}</div>
      <div
        className={styles.containerView}
      >
        {
          props.data.map((deadline: ProcedureDeadline) => (
            <div className={styles.containerItem}>
              <div className={styles.item}>
                <div className={styles.reason}>{deadline?.reason}</div>
              </div>
              <ShowAttribute label={t(`${i18nkey}.title`)} value={deadline?.title} />
              <ShowAttribute label={t(`${i18nkey}.description`)} value={deadline?.description} />
              <ShowAttribute label={t(`${i18nkey}.procedure_number`)} value={deadline?.procedure_number} />
              <ShowAttribute label={t(`${i18nkey}.attorney_name`)} value={deadline?.attorney_name} />
              <ShowAttribute label={t(`${i18nkey}.court_name`)} value={deadline?.court_name} />
              <ShowAttribute
                label={t(`${i18nkey}.deadline`)}
                value={moment(deadline?.deadline).format('DD/MM/YYYY HH:mm')}
              />
              <ShowAttribute label={t(`${i18nkey}.individual_name`)} value={deadline?.individual_name} />
              <Show if={props.deadline !== undefined && deadline?.id === props.deadline.id}>
                <div className={styles.buttonsGroup}>
                  <Button
                    buttonType={'danger'}
                    round
                    size='flat'
                    title={t('general.destroy')}
                    onClick={() => showAlert(deadline)}
                  />

                  <Button
                    buttonType={'default'}
                    round
                    size='flat'
                    title={t('general.edit')}
                    onClick={() => openDeadline(deadline, deadline.procedure[0])}
                  />
                </div>
              </Show>
            </div>
          ))
        }
      </div>
    </div>
  );
}
