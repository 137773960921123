export default {
  translations: {
    reports: {
      general: {
        gteq: 'Maior que ou igual a',
        lteq: 'Menor que ou igual a',
        clear: 'Limpar filtros',
        filter: 'Filtrar',
        emptyList: 'Nenhum registro encontrado',
        print: 'Imprimir relatório',
        selectAllFromPage: 'Selecionar todos da página',
        selectAll: 'Selecionar todos',
        clearSelect: 'Desmarcar todos',
        totalSelected: 'Total de registros selecionados: {{ number }}',
      },
      calculation: {
        title: 'Cálculos contábeis',
        table: {
          header: {
            number: 'Número do processo principal',
            subprocessNumber: 'Número do subprocesso de cálculos contábeis',
            status: 'Status',
            processCategory: 'Objeto',
            individual: 'Procurador',
            sendDate: 'Data de envio',
            deadline: 'Prazo de entrega',
            responseDate: 'Data de resposta',
          },
          orderBy: 'Ordenar por',
        },
        filters: {
          attorney: 'Procurador',
          object: 'Objeto',
          status: 'Status',
          sendDate: {
            label: 'Data de envio',
          },
          deadline: {
            label: 'Prazo de entrega',
          },
          responseDate: {
            label: 'Data de resposta',
          },
        },
      },
      audience: {
        title: 'Relatório de Audiências',
        form: {
          process_number: 'Número do processo',
          attorney: 'Procurador',
          stick: 'Vara',
          interested_part: 'Nome da parte interessada',
          initial_period: 'Período Inicial',
          final_period: 'Período Final',
          not_result: 'Sem resultados',
        },
        table: {
          process_number: 'Número do processo',
          interested_parts: 'Partes Interessadas',
          court: 'Junta / Vara',
          schedule: 'Data e Hora',
          attorney: 'Procurador',
        },
        print_report: 'Imprimir relatório',
      },
      paymentOrder: {
        title: 'Ordens Judiciais de Pagamento',
        notFound: 'Nenhum resultado retornado.',
        form: {
          requisitionType: {
            title: 'Tipo de Requisição',
            placeholder: '',
          },
          beneficiary: {
            title: 'Beneficiário ',
            placeholder: '',
          },
          processNumber: {
            title: 'N° Processo',
            placeholder: '',
          },
          spuNumber: {
            title: 'N° SPU',
            placeholder: '',
          },
          object: {
            title: 'Objeto',
            placeholder: '',
          },
          vara: {
            title: 'Vara',
            placeholder: '',
          },
          situation: {
            title: 'Situação',
            placeholder: '',
          },
          period: {
            title: 'Período',
            placeholder: '',
          },
          sefinDate: {
            title: 'Data do Ofício para SEFIN',
            placeholder: '',
          },
          value: {
            title: 'Valor',
            placeholder: '',
          },
          responseDeadline: {
            title: 'Prazo de Resposta',
            placeholder: '',
          },
        },
        table: {
          part_information: 'Beneficiário',
          procedure_number: 'N° Processo',
          spu_number: 'N° SPU',
          order_type_i18n: 'Tipo de Requisição',
          parent_category: 'Objeto',
          court_name: 'Vara',
          payment_value: 'Valor',
          period: 'Período',
          judicial_order_number: 'Número Ordem Judicial',
          origin_document_date: 'Data do Ofício para SEFIN',
          judicial_order_deadline: 'Prazo de Resposta',
          status: 'Situação',
        },
      },
    },
  },
};
