import { Grid, Modal } from '@mui/material';
import { t } from 'i18next';
import { useEffect, useState, useContext } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { FaPlus } from 'react-icons/fa';
import { Button } from '../../../../components/Button';
import { Header } from '../../../../components/Header';
import { InputDate } from '../../../../components/InputDate';
import { InputText } from '../../../../components/InputText';
import FormHelper from '../../../../helpers/form';
import NewProcessService from '../../../../services/newProcessService';
import styles from './styles.module.scss';
import { ProcedureActionsContext } from '../../../../context/procedureActionsContext';
import { ErrorField } from '../../../../components/ErrorField';
import Select from '../../../../components/Select';
import { ProcedureType } from '../../../../@types/processRegistration';
import Format from '../../../../helpers/format';

type NewConsultancyProcessProps = {
  fluxId?: number;
  closeModal: () => void;
  procedure: ProcedureType | undefined;
};

export function NewConsultancyProcess(props: NewConsultancyProcessProps) {
  const { handleSubmit, control, setValue } = useForm();
  const { addToasts } = useContext(ProcedureActionsContext);
  const [open, setOpen] = useState('');
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState(null);
  const [origins, setOrigins] = useState([]);
  const [modalType, setModalType] = useState('');
  const [subjects, setSubjects] = useState([]);

  const getNewConsultancyProcessData = () => {
    NewProcessService.getNewConsultancyProcessData().then((res) => {
      setSubjects(res.data.consultancy_process_subject_id);
      setOrigins(res.data.consultancy_process_origin_id);
    });
  };

  useEffect(() => {
    getNewConsultancyProcessData();
    if (props.procedure) {
      const consultancyData = props.procedure.consultancy_process;
      setValue('process_number', consultancyData.process_number);
      setValue('consultancy_process_origin_id', consultancyData.process_origin.value);
      setValue('consultancy_process_subject_id', consultancyData.process_subject.value);
      setValue('interested_part', consultancyData.interested_part);
      setValue('cpf_cnpj', consultancyData.cpf_cnpj);
      setValue('phone_number', consultancyData.phone_number);
      setValue('open_date', consultancyData.open_date);
    }
  }, []);

  const submit = (form: any) => {
    setLoading(true);
    const data = { ...{ consultancy_process: form }, flux_id: props.fluxId };
    const editData = {
      ...{ consultancy_process: form },
      flux_id: props.procedure && props.procedure.flux_id,
      procedure_id: props.procedure?.consultancy_process.procedure_id,
    };
    if (props.procedure) {
      NewProcessService.editConsultancyProcess(editData)
        .then((res) => {
          if (res.errors) {
            setErrors(res.errors);
          } else {
            props.closeModal();
            addToasts({
              type: 'success',
              text: FormHelper.customToastMessageEdit(res.id),
            });
          }
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
          if (err.response.status === 400) {
            setErrors(err.response.data);
          }
        });
    } else {
      NewProcessService.createConsultancyProcess(data)
        .then((res) => {
          if (res.errors) {
            setErrors(res.errors);
          } else {
            props.closeModal();
            addToasts({
              type: 'success',
              text: FormHelper.customToastMessage(res.id),
            });
          }
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
          if (err.response.status === 400) {
            setErrors(err.response.data);
          }
        });
    }
  };

  const submitField = (field: string, data: string) => {
    if (field === 'consultancy_process_origin_id') {
      NewProcessService.createOrigin(data)
        .then((res) => {
          getNewConsultancyProcessData();
          setOpen('');
          addToasts({
            type: 'success',
            text: t(`procedureBox.newProcedure.newConsultancyProcess.form.${field}.success`),
          });
        })
        .catch((err) => {
          if (err.response.status === 422) {
            setMessage(err.response.data.name[0]);
          }
        });
    } else {
      NewProcessService.createSubject(data)
        .then((res) => {
          getNewConsultancyProcessData();
          setOpen('');
          addToasts({
            type: 'success',
            text: t(`procedureBox.newProcedure.newConsultancyProcess.form.${field}.success`),
          });
        })
        .catch((err) => {
          if (err.response.status === 422) {
            setMessage(err.response.data.name[0]);
          }
        });
    }
  };

  const renderModal = () => {
    return (
      <Modal
        open={open !== ''}
        onClose={() => {
          setOpen('');
          setMessage('');
        }}
        className={styles.modal}
      >
        <div className={styles.modalContent}>
          <Grid container columns={{ xs: 1, sm: 1, md: 1 }}>
            <Grid item xs={1} sm={1} md={1} className={styles.input}>
              <InputText
                label={t(`procedureBox.newProcedure.newConsultancyProcess.form.${open}.label`)}
                onChange={(e) => {
                  setModalType(e.currentTarget.value);
                }}
              />
              <ErrorField text={message} />
            </Grid>
          </Grid>
          <div className={styles.submit} style={{ marginTop: '18px' }}>
            <Button
              title={t(`procedureBox.newProcedure.newConsultancyProcess.form.${open}.new`)}
              textCenter
              round
              buttonType="primary"
              size="flat"
              disabled={loading}
              onClick={() => submitField(open, modalType)}
            />
          </div>
        </div>
      </Modal>
    );
  };

  return (
    <form className={styles.container} onSubmit={handleSubmit(submit)}>
      { renderModal() }
      <Header
        text={t('procedureBox.newProcedure.newConsultancyProcess.form.data')}
      />
      <Grid container columns={{ xs: 1, sm: 10, md: 12 }}>
        <Grid item xs={1} sm={5} md={4} className={styles.input}>
          <Controller
            control={control}
            name="process_number"
            render={({ field }) => (
              <InputText
                {...field}
                label={t('procedureBox.newProcedure.newConsultancyProcess.form.processNumber.label')}
                placeholder={t('procedureBox.newProcedure.newConsultancyProcess.form.processNumber.placeholder')}
                required
              />
            )}
          />
          {FormHelper.renderErrorField('process_number', errors)}
        </Grid>

        <Grid item xs={1} sm={5} md={4} className={styles.input}>
          <Controller
            control={control}
            name="consultancy_process_origin_id"
            render={({ field }) => (
              <div className={styles.inputWrapper}>
                <div className={styles.selectContainer}>
                  <Select
                    {...field}
                    className={styles.selectInput}
                    label={t('procedureBox.newProcedure.newConsultancyProcess.form.consultancy_process_origin_id.label')}
                    placeholder={t('procedureBox.newProcedure.newConsultancyProcess.form.consultancy_process_origin_id.placeholder')}
                    options={origins}
                    returnValue
                    required
                    defaultValue={props.procedure?.consultancy_process.process_origin}
                  />
                </div>
                <div
                  className={styles.plusIcon}
                  onClick={() => setOpen('consultancy_process_origin_id')}
                >
                  <FaPlus />
                </div>
              </div>
            )}
          />
          {FormHelper.renderErrorField('consultancy_process_origin_id', errors)}
        </Grid>

        <Grid item xs={1} sm={5} md={4} className={styles.input}>
          <Controller
            control={control}
            name="consultancy_process_subject_id"
            render={({ field }) => (
              <div className={styles.inputWrapper}>
                <div className={styles.selectContainer}>
                  <Select
                    {...field}
                    className={styles.selectInput}
                    label={t('procedureBox.newProcedure.newConsultancyProcess.form.consultancy_process_subject_id.label')}
                    placeholder={t('procedureBox.newProcedure.newConsultancyProcess.form.consultancy_process_subject_id.placeholder')}
                    options={subjects}
                    returnValue
                    required
                    defaultValue={props.procedure?.consultancy_process.process_subject}
                  />
                </div>
                <div
                  className={styles.plusIcon}
                  onClick={() => setOpen('consultancy_process_subject_id')}
                >
                  <FaPlus />
                </div>
              </div>
            )}
          />
          {FormHelper.renderErrorField('consultancy_process_subject_id', errors)}
        </Grid>

        <Grid item xs={1} sm={5} md={4} className={styles.input}>
          <Controller
            control={control}
            name="interested_part"
            render={({ field }) => (
              <InputText
                {...field}
                label={t('procedureBox.newProcedure.newConsultancyProcess.form.interested_part.label')}
                placeholder={t('procedureBox.newProcedure.newConsultancyProcess.form.interested_part.label')}
                required
              />
            )}
          />
          {FormHelper.renderErrorField('interested_part', errors)}
        </Grid>

        <Grid item xs={1} sm={5} md={4} className={styles.input}>
          <Controller
            control={control}
            name="cpf_cnpj"
            render={({ field }) => (
              <InputText
                {...field}
                label={t('procedureBox.newProcedure.newConsultancyProcess.form.cpf_cnpj.label')}
                placeholder={t('procedureBox.newProcedure.newConsultancyProcess.form.cpf_cnpj.placeholder')}
                required
              />
            )}
          />
          {FormHelper.renderErrorField('cpf_cnpj', errors)}
        </Grid>

        <Grid item xs={1} sm={5} md={4} className={styles.input}>
          <Controller
            control={control}
            name="phone_number"
            render={({ field }) => (
              <InputText
                {...field}
                label={t('procedureBox.newProcedure.newConsultancyProcess.form.phone_number.label')}
                placeholder={t('procedureBox.newProcedure.newConsultancyProcess.form.phone_number.placeholder')}
                mask={'(99) 99999-9999'}
                required
              />
            )}
          />
          {FormHelper.renderErrorField('phone_number', errors)}
        </Grid>

        <Grid item xs={1} sm={5} md={4} className={styles.input}>
          <Controller
            control={control}
            name="open_date"
            render={({ field }) => (
              <InputDate
                {...field}
                label={t('procedureBox.newProcedure.newConsultancyProcess.form.open_date.label')}
                required
                value={ props.procedure ? Format.formatDate(`${props.procedure?.consultancy_process.open_date}`) : '' }
              />
            )}
          />
          {FormHelper.renderErrorField('open_date', errors)}
        </Grid>
      </Grid>
      <div className={styles.submit}>
        <Button
          title={t('procedureBox.newProcedure.submit')}
          textCenter
          round
          buttonType="primary"
          size="flat"
          type="submit"
          disabled={loading}
          dataCy={'submit-consultancy-process'}
        />
      </div>
    </form>
  );
}
