import { Grid } from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { t } from 'i18next';
import { Trans } from 'react-i18next';
import { SelectedProcedures } from '../SelectedProcedures';
import styles from './styles.module.scss';
import { Attachments } from './Attachments';
import { DocumentData } from '../../pages/Spu/DocumentData';
import { AttachedDocument, OptionType } from '../../@types/config';
import { SpuDocumentData, spuInitialState, SpuJudicialDocument } from '../../@types/spu';
import ProcedureBoxService from '../../services/procedureBoxService';
import { ProcedureActionsContext } from '../../context/procedureActionsContext';
import { BackgroundActionsContext } from '../../context/backgroundActions';
import { Batch, MultipleActionStep } from '../../@types/backgroundActions';
import Show from '../Show';

type SpuProps = {
  closeModal: () => void;
  spuObject?: SpuJudicialDocument | undefined;
  multipleActions?: MultipleActionStep;
};

export function Spu(props: SpuProps) {
  const { setAlert, proceduresSeleted, removeProcedures } = useContext(ProcedureActionsContext);
  const { addBatch } = useContext(BackgroundActionsContext);
  const [loading, setLoading] = useState(true);
  const [prioritiesOptions, setPrioritiesOptions] = useState<OptionType[]>([]);
  const [institutionsOptions, setInstitutionsOptions] = useState<OptionType[]>([]);
  const [stateOptions, setStateOptions] = useState<OptionType[]>([]);
  const [protesterOptions, setProtesterOptions] = useState<OptionType[]>([]);
  const [spuObject, setSpuObject] = useState<SpuJudicialDocument>(props.spuObject || spuInitialState);

  const priorities: OptionType[] = [];
  const targetInstitutions: OptionType[] = [];
  const states: OptionType[] = [];
  const protestersTypes: OptionType[] = [];

  const parserNumber = (value: number | undefined) => {
    if (value !== undefined) {
      return value;
    }
    return null;
  };

  const handleSave = (spuItem: SpuJudicialDocument) => {
    const procedures = proceduresSeleted;
    const type = 'spu';
    const spu = {
      priority: spuItem.documentData?.priority.label || '',
      priority_code: spuItem.documentData?.priority.value,
      target_institution: spuItem.documentData?.targetInstitution.label || '',
      target_institution_code: spuItem.documentData?.targetInstitution.value,
      target_sector: spuItem.documentData?.targetSector.label || '',
      target_sector_code: spuItem.documentData?.targetSector.value,
      order_body: spuItem.documentData?.orderBody || '',
      protester_type: spuItem.documentData?.protester?.label || '',
      protester_type_code: spuItem.documentData?.protester?.value || '',
      name: spuItem.documentData?.name || '',
      responsible: spuItem.documentData?.responsible || '',
      organization: spuItem.documentData?.organization || '',
      cpf: spuItem.documentData?.cpf || '',
      cnpj: spuItem.documentData?.cnpj || '',
      gender: parserNumber(spuItem.documentData?.gender?.value),
      address: spuItem.documentData?.address || '',
      address_number: spuItem.documentData?.addressNumber || '',
      address_complement: spuItem.documentData?.addressComplement || '',
      cep: spuItem.documentData?.cep || '',
      state: spuItem.documentData?.state?.label || '',
      state_code: spuItem.documentData?.state?.value,
      city: spuItem.documentData?.city?.label || '',
      city_code: spuItem.documentData?.city?.value || '',
      neighborhood: spuItem.documentData?.neighborhood?.label || '',
      neighborhood_code: spuItem.documentData?.neighborhood?.value,
      home_phone: spuItem.documentData?.homePhone || '',
      cellphone: spuItem.documentData?.cellPhone || '',
      commercial_phone: spuItem.documentData?.commercialPhone || '',
      email: spuItem.documentData?.email || '',
      observation: spuItem.documentData?.observation || '',
      procedure_id: spuItem.documentData?.procedureId || null,
    };

    const body = {
      documentSelected: spuItem.documentSelected,
      spu,
      spuObject: spuItem,
    };
    const id = (Math.random() + 1).toString(36).substring(1);

    const batch: Batch = {
      id,
      procedures,
      type,
      count: proceduresSeleted.length,
      successList: [],
      failedList: [],
      body,
      multipleActions: props.multipleActions,
    };
    addBatch(batch);
    if (!props.multipleActions) {
      removeProcedures(procedures);
    }
    setAlert(undefined);
    props.closeModal();
  };

  const renderSubtitle = (spu: SpuJudicialDocument) => {
    return (
      <Trans
        i18nKey='procedureBox.actions.spu.send.message'
        components={{ bold: <strong /> }}
        values={{
          institution: spu.documentData?.targetInstitution.label,
          field: spu.documentData?.targetSector.label,
          deadline: spu.documentData?.deadline,
        }}
      />
    );
  };

  const showAlert = (spu: SpuJudicialDocument) => {
    setAlert({
      visible: true,
      handleConfirm: () => handleSave(spu),
      title: t('procedureBox.actions.spu.send.title'),
      text: renderSubtitle(spu),
      confirmText: t('general.yes'),
      confirmType: 'primary',
    });
  };

  const handleAttachments = (values: AttachedDocument[]) => {
    const spuItem = { ...spuObject, documentSelected: values };
    setSpuObject(spuItem);
  };

  const handleDocumentData = (values: SpuDocumentData) => {
    const spuItem = { ...spuObject, documentData: values };
    setSpuObject(spuItem);
    showAlert(spuItem);
  };

  useEffect(() => {
    setLoading(true);
    ProcedureBoxService.getSpuInformations()
      .then((res) => {
        res.priorities.map((doc: any) => priorities.push({
          label: doc[0],
          value: doc[1],
        }));
        res.target_institutions.map((doc: any) => targetInstitutions.push({
          label: doc[0],
          value: doc[1],
        }));
        res.protesters_types.map((doc: any) => protestersTypes.push({
          label: doc[0],
          value: doc[1],
        }));
        res.states.map((doc: any) => states.push({
          label: doc[0],
          value: doc[1],
        }));
        setPrioritiesOptions(priorities);
        setInstitutionsOptions(targetInstitutions);
        setProtesterOptions(protestersTypes);
        setStateOptions(states);
      });

    ProcedureBoxService.getSpu(proceduresSeleted[0].id)
      .then((res) => {
        const spuItem = { ...spuObject, documentData: res };
        setSpuObject(spuItem);
        setLoading(false);
      });
  }, []);

  return (
    <div className={styles.container}>
      <SelectedProcedures />
      <div className={styles.form}>
        <Grid
          container
          columns={{ xs: 1, sm: 8, md: 12 }}
          className={styles.content}
        >
          <Grid item xs={1} sm={8} md={12}>
            <Attachments
              content={spuObject.documentSelected}
              nextStep={(values) => handleAttachments(values)}
            />
          </Grid>
          <Grid item xs={1} sm={8} md={12}>
            <Show if={!loading}>
              <DocumentData
                content={spuObject.documentData}
                priorityOptions={prioritiesOptions}
                institutionOptions={institutionsOptions}
                protesterOptions={protesterOptions}
                stateOptions={stateOptions}
                nextStep={(values) => handleDocumentData(values)}
                documents={spuObject.documentSelected}
              />
            </Show>
          </Grid>
        </Grid>
      </div>
    </div>
  );
}
