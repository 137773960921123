import {
  createContext,
  FC,
  useEffect,
  useState,
} from 'react';
import { useNavigate } from 'react-router-dom';
import { t } from 'i18next';
import {
  OptionFilter,
  OptionType,
  OrderData, OrderFields, OrderSchedule, ResponseProcedure,
} from '../@types/config';
import { CitationIntimation, Marker, Procedure } from '../@types/model';
import { QueryType } from '../@types/query';
import {
  FluxTab, GenericMarkerTab, MarkerPendingTab, MarkerPriorityTab, MarkerTab,
} from '../@types/tab';
import MarkerService from '../services/markerService';
import ProcedureBoxService from '../services/procedureBoxService';
import { GenericMarker } from '../@types/markers';
import { getPendingKey, getPriorityKey, isMarker } from '../helpers/markers';
import { AdvancedFiltersData } from '../@types/advancedFilters';
import { isProcedureBox, isProcedureCollection } from '../helpers/routeTranslate';

const contextDefaultValues: QueryType = {
  filterMarkers: [],
  filterGenericMarkers: [],
  orderBy: '',
  query: '',
  page: '',
  perPage: '',
  box: '',
  quickSearch: '',
  subjectsSelects: [],
  currentPage: 1,
  totalProcedureValues: 0,
  totalProcedures: 0,
  totalPages: 0,
  hasAnyFilter: false,
  orderItem: '',
  updateQuery: () => {},
  setQuickSearch: () => {},
  setOrderBy: () => {},
  setPage: () => {},
  setBox: () => {},
  setPerPage: () => {},
  addMarker: () => {},
  removeMarker: () => {},
  removeAllMarkers: () => {},
  removeQuerys: () => {},
  setLoadingListMarkers: () => {},
  handleFluxSelected: () => {},
  handleMarkerSelected: () => {},
  clearState: () => {},
  setHasAnyFilter: () => {},
  setOrderSchedule: () => {},
  setOrderItem: () => {},
  removeMarkersProcedure: () => {},
  addCustomMarker: () => {},
  changePriorityMarker: () => {},
  changePendencyMarker: () => {},
  loadDataTabs: () => {},
  loadData: () => {},
  redirectToProcedureCollection: () => {},
  setOrderData: () => {},
  setSubjectsSelects: () => {},
  procedures: [],
  setStateProcedures: () => {},
  loadingDetails: false,
  setLoadingDetails: (value: boolean) => {},
  loadingListMarkers: false,
  fluxes: [],
  queryFluxes: [],
  markers: {} as MarkerTab,
  tabSwitch: 0,
  setTabSwitch: () => {},
  loadingTabs: false,
  loading: false,
  isFluxSelected: false,
  orderSchedule: [],
  orderData: [],
  markInstructionAsFinished: () => {},
  removeProcedureFromList: () => {},
  setAdvancedFiltersData: () => {},
  advancedFiltersData: {
    flux_id_in: [],
    step_id_in: [],
  },
  updateAdvancedFilter: () => {},
  citationIntimations: [],
  setCitationIntimations: () => {},
};

export const QueryContext = createContext<QueryType>(
  contextDefaultValues,
);

const QueryProvider: FC = ({ children }) => {
  const [orderSchedule, setOrderSchedule] = useState<OrderSchedule[]>([]);
  const [advancedFiltersData, setAdvancedFiltersData] = useState<AdvancedFiltersData>({ flux_id_in: [], step_id_in: [] });
  const [orderData, setOrderData] = useState<OrderData[]>([]);
  const [orderFields, setOrderFields] = useState<OrderFields>();
  const [filterMarkers, setFilterMarkers] = useState<Marker[]>([]);
  const [filterGenericMarkers, setGenericFilterMarkers] = useState<GenericMarker[]>([]);
  const [box, setBox] = useState('');
  const [fetchFlux, setFetchFlux] = useState(true);
  const [orderBy, setOrderBy] = useState<string | undefined>();
  const [orderItem, setOrderItem] = useState('mostUpdatedAt');
  const [page, setPage] = useState('');
  const [perPage, setPerPage] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [procedures, setProcedures] = useState<Procedure[]>([]);
  const [fluxes, setFluxes] = useState<FluxTab[]>([]);
  const [isFluxSelected, setIsFluxSelected] = useState(false);
  const [markers, setMarkers] = useState<MarkerTab>({} as MarkerTab);
  const [tabSwitch, setTabSwitch] = useState(3);
  const [loading, setLoading] = useState(false);
  const [loadingTabs, setLoadingTabs] = useState(false);
  const [loadingDetails, setLoadingDetails] = useState(false);
  const [query, setQuery] = useState('');
  const [queryFlux, setQueryFlux] = useState('');
  const [queryFluxes, setQueryFluxes] = useState<FluxTab[]>();
  const [queryMarker, setQueryMarker] = useState<string | undefined>();
  const [subjectsSelects, setSubjectsSelects] = useState<OptionType[]>([]);
  const [quickSearch, setQuickSearch] = useState('');
  const [totalProcedureValues, setTotalProcedureValues] = useState(0);
  const [totalProcedures, setTotalProcedures] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [hasAnyFilter, setHasAnyFilter] = useState(false);
  const [loadingListMarkers, setLoadingListMarkers] = useState(false);
  const [citationIntimations, setCitationIntimations] = useState<CitationIntimation[]>([]);

  const navigate = useNavigate();

  const loadDataTabs = async () => {
    const tab = await ProcedureBoxService.getTabSelected();
    setTabSwitch(tab);
    setLoadingTabs(true);
    const fluxTabList = await ProcedureBoxService.getFluxTabList(box, queryFlux);
    const allFluxesObj: FluxTab = {
      flux_id: 0,
      name: t('general.allFluxes'),
      total: fluxTabList.reduce((sum, item) => sum + item.total, 0),
      was_not_listed: fluxTabList.reduce((sum, item) => sum + item.was_not_listed, 0),
      was_not_seen: fluxTabList.reduce((sum, item) => sum + item.was_not_seen, 0),
      allFluxes: true,
    };
    fluxTabList.unshift(allFluxesObj);
    setFluxes(fluxTabList);

    const markerTabList = await ProcedureBoxService.getMarkerTabList(box, queryFlux);
    setMarkers(markerTabList);
    setLoadingTabs(false);
  };

  const loadFluxCollection = async (newQuery: string) => {
    setLoadingTabs(true);
    setFluxes([]);
    try {
      const fluxTabList = await ProcedureBoxService.getFluxCollection(newQuery);
      const allFluxesObj: FluxTab = {
        flux_id: 0,
        name: t('general.allFluxes'),
        total: fluxTabList.reduce((sum, item) => sum + item.total, 0),
        was_not_listed: fluxTabList.reduce((sum, item) => sum + item.was_not_listed, 0),
        was_not_seen: fluxTabList.reduce((sum, item) => sum + item.was_not_seen, 0),
        allFluxes: true,
      };
      fluxTabList.unshift(allFluxesObj);
      setLoadingTabs(false);
      const markerTabList = await ProcedureBoxService.getMarkerCollection(newQuery);
      setMarkers(markerTabList);
      setFluxes(fluxTabList);
    } catch (error) {
      setLoadingTabs(false);
    }
  };

  const handleBoxesContent = (data: ResponseProcedure) => {
    setTotalProcedures(data.total_procedures);
    setTotalPages(data.total_pages);
    setTotalProcedureValues(data.total_procedure_values);
    setProcedures(data.procedures);
    setOrderSchedule(data.order_fields.schedule);
    setOrderData(data.order_fields.data);
    setOrderFields(data.order_fields);
    setLoading(false);
  };

  const loadCollectionData = async (redirect: boolean) => {
    const params = sessionStorage.getItem('query') ? sessionStorage.getItem('query') : query.replace(query[0], '?');
    const newQuery = params ? (params.endsWith('=') ? '' : params) : '';
    const data = await ProcedureBoxService.getProcedureCollectionList(`${newQuery}&page=${currentPage}&per_page=${perPage}`);
    setTotalProcedures(data.total_procedures);
    setTotalPages(data.total_pages);
    setTotalProcedureValues(data.total_procedure_values);
    setProcedures(data.procedures);
    loadFluxCollection(newQuery);
    if (redirect && data.total_procedures > 0) {
      sessionStorage.setItem('query', newQuery);
      navigate('/procedure_collection');
    } else {
      setLoading(false);
    }
  };

  const redirectToProcedureCollection = () => {
    loadCollectionData(true);
    setLoading(false);
  };

  const loadData = async (loadTabs = true) => {
    setLoading(true);
    if (fetchFlux && loadTabs) {
      loadDataTabs();
    }
    setProcedures([]);
    if (box === 'citationIntimation') {
      const citationIntimationList = await ProcedureBoxService.getAllCitationIntimation();
      setCitationIntimations(citationIntimationList);
      setLoading(false);
    } else if (isProcedureBox(window.location.pathname)) {
      const procedureList = await ProcedureBoxService.getProcedureList(box, query);
      if (procedureList.total_procedures === 0 && quickSearch.length > 0) {
        redirectToProcedureCollection();
        setLoading(false);
      } else {
        handleBoxesContent(procedureList);
      }
    } else if (isProcedureCollection(window.location.pathname)) {
      loadCollectionData(false);
    }
  };

  const handleDefaultOrder = () => {
    if (box === 'archivedbox') {
      return 'q[s]=individual_update desc';
    }
    if (box === 'outbox') {
      return 'q[last_user_tramit]=true&last_tramit_order=desc';
    }
    return 'q[s]=updated_at desc';
  };

  const handlePage = (pageValue: string) => {
    if (pageValue === undefined || pageValue === '') {
      return '1';
    }
    return pageValue;
  };

  const handlePerPage = (perPageValue: string) => {
    if (perPageValue === undefined || perPageValue === '') {
      return '20';
    }
    return perPageValue;
  };

  const updateQuery = (changePage?: boolean, changeBox?: boolean) => {
    let newQuery = '';
    if (changeBox) {
      const order = handleDefaultOrder();
      newQuery = newQuery.concat(`&${order}&page=1&per_page=${handlePerPage(perPage)}`);
    } else {
      newQuery = newQuery.concat(queryMarker || '');
      filterMarkers.map((marker) => {
        const prefix = 'markers_id_in';
        newQuery = newQuery.concat(`&q[${prefix}][]=${marker.id}`);
      });
      filterGenericMarkers.map((marker) => {
        if (marker.marker_pending) {
          const prefix = 'marker_pending_in';
          newQuery = newQuery.concat(`&q[${prefix}][]=${getPendingKey(marker.marker_pending)}`);
        } else {
          const prefix = 'marker_priority_in';
          newQuery = newQuery.concat(`&q[${prefix}][]=${getPriorityKey(marker.marker_priority)}`);
        }
      });
      setQueryFlux(newQuery);
      queryFluxes?.map((flux) => {
        const prefix = 'flux_id_in';
        newQuery = newQuery.concat(`&q[${prefix}]=${flux.flux_id}`);
      });
      const queryPage = changePage ? handlePage(page) : '1';
      setCurrentPage(Number(queryPage));
      const order = orderBy || 'updated_at desc';
      newQuery = newQuery.concat(`&q[s]=${order}&page=${queryPage}&per_page=${handlePerPage(perPage)}`);

      const entries = Object.entries(advancedFiltersData).filter(([_, v]) => v.length >= 1);
      if (entries !== null && entries.length >= 1) {
        setHasAnyFilter(true);
        entries.map((entry) => {
          if (typeof entry[1] === 'object') {
            entry[1].map((e) => { newQuery = newQuery.concat(`&q[${entry[0]}][]=${e}`); });
          } else { newQuery = newQuery.concat(`&q[${entry[0]}]=${entry[1]}`); }
        });
      }
      if (subjectsSelects.length > 0) {
        subjectsSelects.map((option: OptionType) => {
          const prefix = 'subject_general_in';
          newQuery = newQuery.concat(`&q[${prefix}][]=${option.label}`);
        });
      }
    }
    setQuery(newQuery);
  };

  const updateQueryQuickSearch = () => {
    let newQuery = '';
    newQuery = newQuery.concat(`&q[procedure_numbers_num_cont]=${quickSearch}`).concat('&q[s]=updated_at desc');
    setQuery(newQuery);
  };

  const updateAdvancedFilter = (id: string, value?: any, multiple?: OptionFilter[]) => {
    if (multiple === undefined) {
      setAdvancedFiltersData({
        ...advancedFiltersData,
        [id]: value,
      });
    } else {
      Object.entries(multiple).forEach(([key, keyValue]: OptionFilter) => { advancedFiltersData[key as keyof AdvancedFiltersData] = keyValue; });
      setAdvancedFiltersData(advancedFiltersData);
    }
  };

  const updateOrderSchedule = () => {
    if (orderFields) {
      const newOrderFields = orderFields;
      const newOrderData = orderData.map((e) => {
        return { description: e.description, order: e.order };
      });
      const newOrderSchedule = orderSchedule.map((e) => {
        return { description: e.description, order: e.order };
      });
      newOrderFields.schedule = newOrderSchedule;
      newOrderFields.data = newOrderData;
      const body = {
        order_fields: [
          {
            field: 0,
            data: newOrderData,
          },
          {
            field: 1,
            data: newOrderSchedule,
          },
        ],
      };
      ProcedureBoxService.updateOrderFields(body);
    }
  };

  useEffect(() => {
    if (tabSwitch < 2) {
      ProcedureBoxService.updateTabSelected(tabSwitch);
    }
  }, [tabSwitch]);

  useEffect(() => {
    if (filterMarkers.length > 0 || filterGenericMarkers.length > 0 || subjectsSelects.length > 0) {
      setFetchFlux(true);
      updateQuery();
    }
  }, [filterMarkers, filterGenericMarkers, subjectsSelects]);

  useEffect(() => {
    if (perPage !== '') {
      setFetchFlux(false);
      updateQuery();
    }
  }, [perPage]);

  useEffect(() => {
    if ((queryFluxes !== undefined && queryFluxes.length > 0) || queryMarker !== undefined) {
      updateQuery();
    }
  }, [queryFluxes, queryMarker]);

  useEffect(() => {
    if (page !== '' || orderBy !== undefined) {
      updateQuery(true);
    }
  }, [page, orderBy]);

  useEffect(() => {
    if (query !== '') {
      loadData();
    }
  }, [query]);

  useEffect(() => {
    if (box !== '') {
      setFetchFlux(true);
      updateQuery(false, true);
    }
  }, [box]);

  useEffect(() => {
    updateQueryQuickSearch();
  }, [quickSearch]);

  useEffect(() => {
    updateOrderSchedule();
  }, [orderSchedule, orderData]);

  const addMarker = (marker: Marker | GenericMarker) => {
    if (isMarker(marker)) {
      if (!filterMarkers.find((item) => item.id === marker.id && item.private_marker === marker.private_marker)) {
        setFilterMarkers(() => [...filterMarkers, marker]);
      }
    } else if (!filterGenericMarkers.find((item) => item.marker_pending === marker.marker_pending && item.marker_priority === marker.marker_priority)) {
      setGenericFilterMarkers(() => [...filterGenericMarkers, marker]);
    }
  };

  const removeMarker = (marker: Marker | GenericMarker) => {
    if (isMarker(marker)) {
      setFilterMarkers(() => filterMarkers.filter((item) => item.id !== marker.id || item.private_marker !== marker.private_marker));
    } else {
      setGenericFilterMarkers(() => filterGenericMarkers.filter((item) => item.marker_pending !== marker.marker_pending || item.marker_priority !== marker.marker_priority));
    }
  };

  const removeAllMarkers = () => {
    setFilterMarkers([]);
    setGenericFilterMarkers([]);
  };

  const removeQuerys = () => {
    setQueryMarker('');
    setQueryFluxes([]);
  };

  const clearState = () => {
    setHasAnyFilter(false);
    setQuery('');
    setQuickSearch('');
    setAdvancedFiltersData({ flux_id_in: [], step_id_in: [] });
  };

  const removeMarkersProcedure = async (markerId: number, procedureIds: number[]) => {
    const response = await ProcedureBoxService.removeMarkersProcedure(markerId, procedureIds);

    if (response.status === 200) {
      window.location.reload();
    }
  };

  const changePriorityMarker = async (procedureId: number, priorityData: string, proceduresInBulk?: Procedure[] | undefined) => {
    setLoadingListMarkers(true);
    if (proceduresInBulk !== undefined && proceduresInBulk.length > 1) {
      for (let index = 0; index <= proceduresInBulk.length; index += 1) {
        MarkerService.addPriorityMarker([proceduresInBulk[index].id], priorityData)
          .then(((response) => {
            if (response.status === 200) {
              const newProceduresState = procedures.map((procedure) => {
                const processSelected = proceduresInBulk.filter((p) => p.id === procedure.id);
                if (processSelected.length === 1) {
                  return { ...procedure, marker_priority: priorityData };
                }

                return procedure;
              });

              setProcedures(newProceduresState);
            }
          }));
      }
    } else {
      const response = await MarkerService.addPriorityMarker([procedureId], priorityData);
      if (response.status === 200) {
        const newProceduresState = procedures.map((procedure) => {
          if (procedure.id === procedureId) {
            return { ...procedure, marker_priority: priorityData };
          }

          return procedure;
        });

        setProcedures(newProceduresState);
      }
    }
    setLoadingListMarkers(false);
  };

  const changePendencyMarker = async (procedureId: number, pendencyData: string, proceduresInBulk?: Procedure[] | undefined) => {
    setLoadingListMarkers(true);
    if (proceduresInBulk !== undefined && proceduresInBulk.length > 1) {
      for (let index = 0; index <= proceduresInBulk.length; index += 1) {
        MarkerService.addPendencyMarker([proceduresInBulk[index].id], pendencyData)
          .then(((response) => {
            if (response.status === 200) {
              const newProceduresState = procedures.map((procedure) => {
                const processSelected = proceduresInBulk.filter((p) => p.id === procedure.id);
                if (processSelected.length === 1) {
                  return { ...procedure, marker_pending: pendencyData };
                }

                return procedure;
              });

              setProcedures(newProceduresState);
            }
          }));
      }
    } else {
      const response = await MarkerService.addPendencyMarker([procedureId], pendencyData);
      if (response.status === 200) {
        const newProceduresState = procedures.map((procedure) => {
          if (procedure.id === procedureId) {
            return { ...procedure, marker_pending: pendencyData };
          }

          return procedure;
        });

        setProcedures(newProceduresState);
      }
    }
    setLoadingListMarkers(false);
  };

  const addCustomMarker = async (marker: Marker, procedureId: number, proceduresInBulk?: Procedure[] | undefined) => {
    setLoadingListMarkers(true);
    if (proceduresInBulk !== undefined && proceduresInBulk.length > 1) {
      for (let index = 0; index <= proceduresInBulk.length; index += 1) {
        MarkerService.addCustomMarker(marker.id, [proceduresInBulk[index].id])
          .then((response) => {
            if (response.status === 200) {
              const newProceduresState = procedures.map((procedure) => {
                const processSelected = proceduresInBulk.filter((p) => p.id === procedure.id);
                if (processSelected.length === 1) {
                  return { ...procedure, markers: [...procedure.markers, marker] };
                }

                return procedure;
              });

              setProcedures(newProceduresState);
            }
          });
      }
    } else {
      const response = await MarkerService.addCustomMarker(marker.id, [procedureId]);
      if (response.status === 200) {
        const newProceduresState = procedures.map((procedure) => {
          if (procedure.id === procedureId) {
            return { ...procedure, markers: [...procedure.markers, marker] };
          }

          return procedure;
        });

        setProcedures(newProceduresState);
      }
    }
    setLoadingListMarkers(false);
  };

  const markInstructionAsFinished = async (procedureId: number, tramitId: number, finished: boolean) => {
    const response = await ProcedureBoxService.markAsInstructionFinished(tramitId, finished);
    if (response.status === 200) {
      const newProceduresState = procedures.map((procedure) => {
        if (procedure.id === procedureId) {
          const instruction = { ...procedure.last_tramit, finished };
          return { ...procedure, last_tramit: instruction };
        }

        return procedure;
      });

      setProcedures(newProceduresState);
    }
  };

  const handleFluxSelected = async (item: FluxTab) => {
    const list = fluxes.map((fluxItem) => {
      if (item.name === fluxItem.name && !item.allFluxes) {
        if (item.selected) {
          return { ...fluxItem, selected: !item.selected, was_not_listed: 0 };
        }
        return { ...fluxItem, selected: !item.selected };
      }
      if (fluxItem.selected) {
        return { ...fluxItem, selected: false, was_not_listed: 0 };
      }
      return { ...fluxItem, selected: false };
    });

    const queryListedByFlux = `&q[flux_id_eq]=${item.flux_id}`;
    await ProcedureBoxService.wasListedByFlux('inbox', queryListedByFlux);
    setFetchFlux(false);
    const selectedFluxList = list.filter((flux) => flux.selected === true);
    setQueryFluxes(selectedFluxList);
    setIsFluxSelected(selectedFluxList.length > 0);
    setFluxes(list);
  };

  async function handleMarkerSelected(marker: MarkerPriorityTab | MarkerPendingTab | GenericMarkerTab) {
    const {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      priority_markers, pending_markers, private_markers, public_markers,
    } = markers;

    const updatedPriorityMarkers = priority_markers.map((pMarker) => ({
      ...pMarker,
      selected: pMarker === marker ? !marker.selected : false,
      was_not_listed: pMarker === marker ? 0 : pMarker.was_not_listed,
    }));

    const updatedPendingMarkers = pending_markers.map((pMarker) => ({
      ...pMarker,
      selected: pMarker === marker ? !marker.selected : false,
      was_not_listed: pMarker === marker ? 0 : pMarker.was_not_listed,
    }));

    const updatedPrivateMarkers = private_markers.map((pMarker) => ({
      ...pMarker,
      selected: pMarker === marker ? !marker.selected : false,
      was_not_listed: pMarker === marker ? 0 : pMarker.was_not_listed,
    }));

    const updatedPublicMarkers = public_markers.map((pMarker) => ({
      ...pMarker,
      selected: pMarker === marker ? !marker.selected : false,
      was_not_listed: pMarker === marker ? 0 : pMarker.was_not_listed,
    }));

    setMarkers({
      priority_markers: updatedPriorityMarkers,
      pending_markers: updatedPendingMarkers,
      private_markers: updatedPrivateMarkers,
      public_markers: updatedPublicMarkers,
    });

    const queryListedByMarker = (() => {
      if ('marker_priority' in marker) {
        const prefix = 'marker_priority_in';
        return `&q[${prefix}][]=${getPriorityKey(marker.marker_priority)}`;
      }
      if ('marker_pending' in marker) {
        const prefix = 'marker_pending_in';
        return `&q[${prefix}][]=${getPendingKey(marker.marker_pending)}`;
      }
      const prefix = 'markers_id_in';
      return `&q[${prefix}][]=${marker.id}`;
    });
    let newQuery = '';
    if (marker.selected === false) {
      await ProcedureBoxService.wasListedByFlux('inbox', queryListedByMarker());
      newQuery = newQuery.concat(queryListedByMarker());
    }
    setFetchFlux(false);
    setQueryMarker(newQuery);
  }

  const setStateProcedures = (proceduresParam: Procedure[]) => {
    setProcedures(proceduresParam);
  };

  const removeProcedureFromList = (procedure: Procedure) => {
    setProcedures(procedures.filter((el) => el.id !== procedure.id));
  };

  return (
    <QueryContext.Provider
      value={{
        filterMarkers,
        filterGenericMarkers,
        addMarker,
        removeMarker,
        removeAllMarkers,
        removeQuerys,
        handleFluxSelected,
        handleMarkerSelected,
        query,
        loading,
        subjectsSelects,
        setSubjectsSelects,
        procedures,
        setStateProcedures,
        fluxes,
        queryFluxes,
        markers,
        tabSwitch,
        setTabSwitch,
        loadingTabs,
        orderBy,
        setOrderBy,
        page,
        perPage,
        setPage,
        setPerPage,
        quickSearch,
        setQuickSearch,
        loadDataTabs,
        loadData,
        loadingDetails,
        setLoadingDetails,
        redirectToProcedureCollection,
        clearState,
        totalProcedureValues,
        totalProcedures,
        totalPages,
        currentPage,
        orderSchedule,
        orderData,
        setOrderSchedule,
        removeMarkersProcedure,
        addCustomMarker,
        setLoadingListMarkers,
        loadingListMarkers,
        changePriorityMarker,
        changePendencyMarker,
        setOrderData,
        orderItem,
        setOrderItem,
        setBox,
        box,
        updateQuery,
        markInstructionAsFinished,
        removeProcedureFromList,
        advancedFiltersData,
        setAdvancedFiltersData,
        updateAdvancedFilter,
        hasAnyFilter,
        setHasAnyFilter,
        isFluxSelected,
        citationIntimations,
        setCitationIntimations,
      }}>
        {children}
      </QueryContext.Provider>
  );
};

export default QueryProvider;
