import { Grid, Modal } from '@mui/material';
import styles from './styles.module.scss';
import { InputText } from '../InputText';
import FormHelper from '../../helpers/form';
import { Button } from '../Button';
import { Errors } from '../../@types/config';
import { Header } from '../Modal/Header';

type SubjectModalProps = {
  loading: boolean;
  errors?: Errors | null;
  errorField: string;
  open: boolean;
  setOpen: (value: boolean) => void;
  setValue: (value: string) => void;
  inputLabel?: string;
  buttonLabel: string;
  handleOnClick: () => void;
};

export function SubjectModal(props: SubjectModalProps) {
  return (
    <Modal
      open={props.open}
      onClose={() => props.setOpen(false) }
      className={styles.modal}
    >
      <div className={styles.modalContent}>
        <Header
          onClose={() => props.setOpen(false)}
          className={styles.headerContainer}
        />
        <Grid container columns={{ xs: 1, sm: 1, md: 1 }}>
          <Grid item xs={1} sm={1} md={1} className={styles.input}>
            <InputText
              label={props.inputLabel}
              onChange={(e) => props.setValue(e.target.value)}
            />
            {FormHelper.renderErrorField(props.errorField, props.errors)}
          </Grid>
        </Grid>
        <div className={styles.submit}>
          <Button
            title={props.buttonLabel}
            textCenter
            round
            buttonType='primary'
            size='flat'
            disabled={props.loading}
            onClick={() => props.handleOnClick()}
          />
        </div>
      </div>
    </Modal>
  );
}
