import { useEffect } from 'react';
import Routes from './routes';
import { AuthProvider } from './context/authContext';

import './config/i18n';

export function App() {
  useEffect(() => {
    window.addEventListener('message', (event) => {
      if (event.data === 'reloadMainPage') {
        window.location.reload();
      } if (event.data.fromEpgm) {
        const { data } = event;
        data.fromEpgm = false;
        window.postMessage(data, '*');
      }
    }, false);
  }, []);
  return (
    <AuthProvider>
      <Routes />
    </AuthProvider>
  );
}
