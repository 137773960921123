import { isCnpj, isCpf } from './validator';

export function checkDocumentData(
  origin: string,
  deadline: string,
  priority: number | undefined,
  institution: number | undefined,
  field: number | undefined,
  description: string,
): boolean {
  const checkOrigin = origin !== undefined && origin.length > 0;
  const checkDeadline = deadline !== undefined && deadline.length > 0;
  const checkPriority = priority !== undefined;
  const checkInstitution = institution !== undefined;
  const checkField = field !== undefined;
  const checkDescription = description !== undefined && description.length > 0;
  return (checkOrigin && checkDeadline && checkPriority && checkInstitution && checkField && checkDescription);
}

export function checkPartData(
  protesterType: string,
  name: string,
  cpf: string,
  gender: any,
  cnpj: string,
): boolean {
  const checkProtester = protesterType !== undefined && protesterType.length > 0;
  const checkName = name !== undefined && name.length > 0;
  const checkCpf = cpf !== undefined && cpf.length > 0 && isCpf(cpf);
  const checkCnpj = cnpj !== undefined && cnpj.length > 0 && isCnpj(cnpj);
  const checkGender = gender !== undefined;
  if (protesterType.toLowerCase().includes('cpf')) {
    return (checkProtester && checkName && checkCpf && checkGender);
  }
  if (protesterType.toLowerCase().includes('cnpj')) {
    return (checkProtester && checkName && checkCnpj);
  }
  return (checkProtester && checkName);
}

export function checkPartAddress(
  state: string,
  stateCode: number | undefined,
  city: string,
  cityCode: number | undefined,
  neighborhood: string,
  neighborhoodCode: number | undefined,
): boolean {
  const checkState = state !== undefined && state.length > 0;
  const checkStateCode = stateCode !== undefined;
  const checkCity = city !== undefined && state.length > 0;
  const checkCityCode = cityCode !== undefined;
  const checkNeighborhood = neighborhood !== undefined && state.length > 0;
  const checkNeighborhoodCode = neighborhoodCode !== undefined;
  return (checkState && checkStateCode && checkCity && checkCityCode && checkNeighborhood && checkNeighborhoodCode);
}

export function checkSendSpu(fluxCode: string): boolean {
  return ['FLU0107', 'FLU0108', 'FLU0109', 'FLU0013'].includes(fluxCode);
}
