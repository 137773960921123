import { Grid } from '@mui/material';
import { t } from 'i18next';
import { useContext, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { FaPlus } from 'react-icons/fa';
import { Button } from '../../../../components/Button';
import { InputDate } from '../../../../components/InputDate';
import { InputText } from '../../../../components/InputText';
import { TextArea } from '../../../../components/TextArea';
import FormHelper from '../../../../helpers/form';
import NewProcessService from '../../../../services/newProcessService';
import styles from './styles.module.scss';
import { ProcedureActionsContext } from '../../../../context/procedureActionsContext';
import { maskCpfOrCnpj } from '../../../../helpers/masks';
import Show from '../../../../components/Show';
import { ErrorField } from '../../../../components/ErrorField';
import { ProcedureType } from '../../../../@types/processRegistration';
import { QueryContext } from '../../../../context/queryContext';
import Select from '../../../../components/Select';
import { Errors, OptionType } from '../../../../@types/config';
import { CurrentUser } from '../../../../@types/currentUser';
import { SubjectModal } from '../../../../components/SubjectModal';
import { Flux } from '../../../../@types/digitalProcesses/fluxes';

type NewProcessRegistrationProps = {
  fluxId?: number;
  fluxCode?: string;
  closeModal: () => void;
  procedure: ProcedureType | undefined;
  fluxes?: Flux[];
  procedureId?: number;
};

export function NewProcessRegistration(props: NewProcessRegistrationProps) {
  const { setLoadingDetails } = useContext(QueryContext);
  const keyI18n = 'procedureBox.newProcedure.newProcedureRegistration.form';
  const [origins, setOrigins] = useState([]);
  const [types, setTypes] = useState([]);
  const [subject, setSubject] = useState<string>();
  const [subjects, setSubjects] = useState<OptionType[]>([]);
  const [errors, setErrors] = useState<Errors | null>(null);
  const [errorCpfAndCnpj, setErrorCpfAndCnpj] = useState(false);
  const [loading, setLoading] = useState(false);
  const [documentValueMask, setDocumentValueMask] = useState('');
  const [open, setOpen] = useState(false);
  const currentUser: CurrentUser = JSON.parse(localStorage.getItem('currentUser')!);

  const { addToasts } = useContext(ProcedureActionsContext);

  const { control, handleSubmit, setValue } = useForm();

  const handleCpfOrCnpj = (value: string) => {
    setDocumentValueMask(maskCpfOrCnpj(value).mask());
    setErrorCpfAndCnpj(false);
  };

  const loadData = () => {
    NewProcessService.getNewProcedureData()
      .then((res) => {
        setOrigins(res.data.origin_procedure_id);
        setTypes(res.data.process_type);
        setSubjects(res.data.subject_id);
      });
  };

  useEffect(() => {
    loadData();
    if (props.procedure) {
      const registration = props.procedure?.process_registration;
      setValue('process_number', registration?.process_number);
      setValue('spu_number', registration?.spu_number);
      setValue('interested_part_name', registration?.interested_parts);
      setValue('origin_procedure_id', registration?.origin_procedure.value);
      setValue('process_type', registration?.process_type.value);
      setValue('subject_id', registration?.subject?.value);
      setValue('date_receipt', registration?.date_receipt);
      setValue('deadline_procedure', registration?.deadline_procedure);
      setValue('resume', registration?.resume);
      setDocumentValueMask(maskCpfOrCnpj(registration?.interested_part_document).mask());
    }
  }, []);

  const isValid: any = () => {
    const cpfIsValid = documentValueMask.replace(/[^0-9]/g, '').length === 11;
    const cnpjIsValid = documentValueMask.replace(/[^0-9]/g, '').length === 14;

    if (!cpfIsValid && !cnpjIsValid && props.fluxCode !== 'FLU0105') {
      setErrorCpfAndCnpj(true);
      setLoading(false);

      return {};
    }

    setLoading(true);

    return { cpfIsValid, cnpjIsValid };
  };

  const createProcedure = (form: any) => {
    const data = {
      ...form,
      interested_part_cpf: isValid()?.cpfIsValid ? documentValueMask : null,
      interested_part_cnpj: isValid()?.cnpjIsValid ? documentValueMask : null,
      flux_id: props.fluxId,
      procedure_id: props.procedureId,
    };

    if (isValid()?.cpfIsValid || isValid()?.cnpjIsValid || props.fluxCode === 'FLU0105') {
      NewProcessService.createProcedureRegistration(data)
        .then((res) => {
          if (res.errors) {
            setErrors(res.errors);
          } else {
            props.closeModal();
            addToasts({
              type: 'success',
              text: t('procedureBox.newProcedure.toasts.success'),
            });
          }
        })
        .catch((err) => {
          setLoading(false);
          if (err.response.status === 400) {
            setErrors(err.response.data);
          }
        });
    }
  };

  const updateProcedure = (form: any) => {
    const procedureId = props.procedure?.process_registration?.procedure_id as number;
    const data = {
      ...form,
      interested_part_cpf: isValid()?.cpfIsValid ? documentValueMask : null,
      interested_part_cnpj: isValid()?.cnpjIsValid ? documentValueMask : null,
      flux_id: props.procedure?.flux_id,
      procedure_id: procedureId,
    };

    NewProcessService.updateProcedureRegistration(data)
      .then((res) => {
        if (res.errors) {
          setErrors(res.errors);
        } else {
          props.closeModal();
          addToasts({
            type: 'success',
            text: FormHelper.customToastMessageEdit(res.id),
          });
        }
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        if (err.status === 400) {
          setErrors(err.data);
        }
      });

    setLoadingDetails(true);
  };

  const submit = (form: any) => {
    setLoading(true);
    if (props.procedure) {
      updateProcedure(form);
    } else {
      createProcedure(form);
    }
  };

  const handleSubject = () => {
    NewProcessService.createSubjectProcedure(subject, '9999', 1)
      .then((resp) => {
        const newItem = { label: resp.name, value: resp.id };
        setSubjects([...subjects, newItem].sort((a, b) => a.label.localeCompare(b.label)));
        setOpen(false);
      })
      .catch((err) => {
        setErrors({ new_subject: [err.response.data.error] });
      });
  };

  return (
    <form className={ styles.container } onSubmit={handleSubmit(submit)}>
      <SubjectModal
        loading={loading}
        errors={errors}
        errorField={'new_subject'}
        open={open}
        setOpen={setOpen}
        setValue={setSubject}
        inputLabel={t('procedureBox.newProcedure.newProcedureRegistration.form.subject_id.label')}
        buttonLabel={t('procedureBox.newProcedure.newProcedureRegistration.form.subject_id.new')}
        handleOnClick={handleSubject}
      />
      <Grid
        container
        columns={{ xs: 1, sm: 10, md: 12 }}
      >
        <Grid
          item
          xs={ 1 }
          sm={ 5 }
          md={ 4 }
          className={styles.input}
        >
          <Controller
            control={control}
            name="process_number"
            render={({ field }) => (
              <InputText
                {...field}
                label={t(`${keyI18n}.processNumber.label`)}
                placeholder={t(`${keyI18n}.processNumber.placeholder`)}
                dataCy={'process-registration-number'}
              />
            )}
          />
          {FormHelper.renderErrorField('process_number', errors)}
        </Grid>

        <Grid
          item
          xs={ 1 }
          sm={ 5 }
          md={ 4 }
          className={styles.input}
        >
          <Controller
            control={control}
            name="spu_number"
            render={({ field }) => (
              <InputText
                {...field}
                label={t(`${keyI18n}.spu_number.label`)}
                placeholder={t(`${keyI18n}.spu_number.placeholder`)}
              />
            )}
          />
          {FormHelper.renderErrorField('spu_number', errors)}
        </Grid>

        <Grid
          item
          xs={ 1 }
          sm={ 5 }
          md={ 4 }
          className={styles.input}
        >
          <Controller
            control={control}
            name="interested_part_document"
            render={({ field }) => (
              <InputText
                {...field}
                value={documentValueMask}
                onChange={(event) => handleCpfOrCnpj(event.target.value)}
                label={t(`${keyI18n}.iterested_part_cpf_cnpj.label`)}
                required={props.fluxCode !== 'FLU0105'}
                dataCy={'process-registration-document'}
              />
            )}
          />
          <Show if={errorCpfAndCnpj}>
            <ErrorField text={t(`${keyI18n}.iterested_part_cpf_cnpj.error`)}/>
          </Show>
          {FormHelper.renderErrorField('interested_part_document', errors)}
        </Grid>

        <Grid
          item
          xs={ 1 }
          sm={ 5 }
          md={ 4 }
          className={styles.input}
        >
          <Controller
            control={control}
            name="interested_part_name"
            render={({ field }) => (
              <InputText
                {...field}
                label={t(`${keyI18n}.interested_part_name.label`)}
                required
                dataCy={'process-registration-interested-part'}
              />
            )}
          />
          {FormHelper.renderErrorField('interested_part_name', errors)}
        </Grid>

        <Grid
          item
          xs={ 1 }
          sm={ 5 }
          md={ 4 }
          className={styles.input}
        >
          <Controller
            control={control}
            name="origin_procedure_id"
            render={({ field }) => (
              <Select
                {...field}
                label={t(`${keyI18n}.origin_procedure_id.label`)}
                options={origins}
                defaultValue={props.procedure?.process_registration?.origin_procedure}
                returnValue
                required
                dataCy={'process-registration-origin'}
              />
            )}
          />
          {FormHelper.renderErrorField('origin_procedure_id', errors)}
        </Grid>

        <Grid
          item
          xs={ 1 }
          sm={ 5 }
          md={ 4 }
          className={styles.input}
        >
          <Controller
            control={control}
            name="process_type"
            render={({ field }) => (
              <Select
                {...field}
                label={t(`${keyI18n}.process_type.label`)}
                options={types}
                defaultValue={props.procedure?.process_registration?.process_type}
                returnValue
                required
                dataCy={'process-registation-process-type'}
              />
            )}
          />
          {FormHelper.renderErrorField('process_type', errors)}
        </Grid>

        <Grid
          item
          xs={ 1 }
          sm={ 5 }
          md={ 4 }
          className={styles.input}
        >
          <Controller
            control={control}
            name="subject_id"
            render={({ field }) => (
              <div className={styles.inputWrapper}>
                <div className={`${styles.selectContainer} ${currentUser.can_create_subject && styles.uniqSelect}`}>
                  <Select
                    {...field}
                    label={t(`${keyI18n}.subject_id.label`)}
                    defaultValue={props.procedure?.process_registration?.subject}
                    options={subjects}
                    returnValue
                    required
                    dataCy={'process-registration-subject-id'}
                  />
                </div>
                <Show if={!!currentUser.can_create_subject}>
                  <div
                    className={styles.plusIcon}
                    onClick={() => setOpen(true)}
                  >
                    <FaPlus />
                  </div>
                </Show>
              </div>
            )}
          />
          {FormHelper.renderErrorField('subject_id', errors)}
        </Grid>

        <Grid
          item
          xs={ 1 }
          sm={ 5 }
          md={ 4 }
          className={styles.input}
        >
          <Controller
            control={control}
            name="date_receipt"
            render={({ field }) => (
              <InputDate
                {...field}
                required
                label={t(`${keyI18n}.date_receipt.label`)}
                value={props.procedure?.process_registration?.date_receipt || ''}
                dataCy={'process-registration-receipt-data'}
              />
            )}
          />
          {FormHelper.renderErrorField('date_receipt', errors)}
        </Grid>

        <Grid
          item
          xs={ 1 }
          sm={ 5 }
          md={ 4 }
          className={styles.input}
        >
          <Controller
            control={control}
            name="deadline_procedure"
            render={({ field }) => (
              <InputDate
                {...field}
                label={t(`${keyI18n}.deadline_procedure.label`)}
                value={props.procedure?.process_registration?.deadline_procedure || ''}
              />
            )}
          />
          {FormHelper.renderErrorField('deadline_procedure', errors)}
        </Grid>

        <Grid
          item
          xs={ 1 }
          sm={ 10 }
          md={ 12 }
          className={styles.input}
        >
          <Controller
            control={control}
            name="resume"
            render={({ field }) => (
              <TextArea
                {...field}
                label={t(`${keyI18n}.resume.label`)}
              />
            )}
          />
          {FormHelper.renderErrorField('resume', errors)}
        </Grid>

      </Grid>
      <div className={styles.submit}>
        <Button
          title={t(`procedureBox.${props.procedure ? 'editProcedure' : 'newProcedure'}.submit`)}
          textCenter
          round
          buttonType='primary'
          size='flat'
          type='submit'
          disabled={loading}
          dataCy={'proces-registration-submit'}
        />
      </div>
    </form>
  );
}
