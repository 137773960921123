import { Progress as ProgressType } from '../../../../@types/digitalProcesses/timeline';
import { Avatar } from '../../../../components/ProcedureList/Avatar';
import { Moment } from '../../../../config/moment';
import IconSelections from '../../../../helpers/dynamicIcons';
import styles from './styles.module.scss';

type ProgressProps = {
  progress: ProgressType;
  first?: boolean;
  last?: boolean;
  selected?: number;
  onClick: (id: number) => void;
};

export function Progress(props: ProgressProps) {
  const verifyProgress = props.progress.status_step === 'archived'
    ? styles.containerArchived
    : styles.containerDefault;

  return (
    <a
      className={`
        ${styles.container}
        ${verifyProgress}
        ${props.first ? styles.first : ''} 
        ${props.last ? styles.last : ''} 
        ${props.selected === props.progress.id ? styles.selected : ''}
      `}
      onClick={() => props.onClick(props.progress.id)}
    >
      <div className={styles.content}>
        <div className={`${styles.iconContent} ${verifyProgress}`}>
          {IconSelections(props.progress.step.list_activity_id)}
        </div>
        <div>
          <p className={`${styles.time} ${verifyProgress}`}>
            {Moment(props.progress.created_at).format('HH:mm')}
          </p>
          <p className={`${styles.productGenerated} ${verifyProgress}`}>
            {props.progress.step.product_generated}
          </p>
          <div className={styles.individualContent}>
            <Avatar
              name={props.progress.individual_name}
              color={props.progress.individual_color}
              profile_picture={props.progress.individual_profile_picture}
            />
            <p className={`${styles.individual} ${verifyProgress}`}>
              {props.progress.individual_name}
            </p>
          </div>
        </div>
      </div>
    </a>
  );
}
