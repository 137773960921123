import { ReactNode } from 'react';
import styles from './styles.module.scss';

type SearchButtonProps = {
  icon?: ReactNode;
  active: boolean;
  onSearchClick?: () => void;
};

export function SearchButton(props: SearchButtonProps) {
  return (
      <button type="submit" onClick={props.onSearchClick} className={props.active ? styles.searchButtonContainerActive : styles.searchButtonContainer}>
        { props.icon }
      </button>
  );
}
