import { t } from 'i18next';
import { useContext, useEffect, useState } from 'react';
import { Grid } from '@mui/material';
import { Controls, Player } from '@lottiefiles/react-lottie-player';
import { Link } from 'react-router-dom';
import { Trans } from 'react-i18next';
import { ResponsiveContainer } from 'recharts';
import styles from './styles.module.scss';
import { Header } from '../Header';
import { ProcedureActionsContext } from '../../context/procedureActionsContext';
import { Button } from '../Button';
import { InputText } from '../InputText';
import ProcedureBoxService from '../../services/procedureBoxService';
import { CitationIntimation, Procedure } from '../../@types/model';
import ActionsService from '../../services/actionsService';
import { QueryContext } from '../../context/queryContext';

const LOADING_ANIMATION = require('../../assets/lotties/loading.json');

export function AttachCitationIntimation() {
  const {
    proceduresSeleted, setAlert, setCitationIntimation, addToasts, citationIntimationSelected, setModal,
  } = useContext(ProcedureActionsContext);
  const { loadData: loadDataProcedure } = useContext(QueryContext);
  const [query, setQuery] = useState('');
  const [loading, setLoading] = useState(false);
  const [queryProcedures, setQueryProcedures] = useState<Procedure[]>([]);
  const [selected, setSelected] = useState<Procedure | undefined>();

  useEffect(() => {
    if (citationIntimationSelected?.process_number) {
      setLoading(true);
      ProcedureBoxService.getProcedureCollectionList(`?q[procedure_numbers_num_cont]=${citationIntimationSelected?.process_number}&page=1&per_page=20`)
        .then((resp) => {
          setQueryProcedures(resp.procedures);
          setLoading(false);
        });
    }
  }, [citationIntimationSelected]);

  const tableHeaderKeys: string[] = [
    'procedure',
    'flux',
    'subject',
    'current_responsbile',
    'interested_part',
  ];

  const renderRadio = (procedure: Procedure) => {
    return (
      <div onClick={() => { setSelected(procedure); } } className={selected === procedure ? styles.radioSelected : styles.radio }></div>
    );
  };

  const handleText = () => {
    return (
      <Trans
        i18nKey='procedureBox.actions.citation_intimation.attach.text'
        components={{ br: <br /> }}
        values={{ number: selected?.process_number }}
      />
    );
  };

  const handleAlert = () => {
    setAlert({
      visible: true,
      type: 'warning',
      title: t('procedureBox.actions.citation_intimation.attach.title'),
      text: handleText(),
      confirmText: t('general.yes'),
      confirmType: 'primary',
      cancelText: t('general.no'),
      cancelType: 'default',
      handleConfirm: () => {
        setAlert({
          visible: true,
          title: t('procedureBox.actions.citation_intimation.attach.load.title'),
          text: t('procedureBox.actions.citation_intimation.attach.load.text'),
          type: 'loading',
        });
        ActionsService.attachCitationIntimation(citationIntimationSelected as CitationIntimation, selected as Procedure).then((r) => {
          loadDataProcedure();
          setAlert(undefined);
          setCitationIntimation(undefined);
          setModal(undefined);
          addToasts({
            type: 'success',
            text: t('procedureBox.actions.citation_intimation.attach.toast'),
          });
        }).catch((e) => {
          addToasts({
            type: 'error',
            text: e.data.message,
          });
          setAlert(undefined);
        });
      },
    });
  };

  const renderLoading = () => {
    return (
      <div className={styles.icon}>
        <Player
          loop={true}
          autoplay
          keepLastFrame={true}
          src={LOADING_ANIMATION}
          speed={2}
          className={styles.lottie}
        >
          <Controls visible={false} />
        </Player>
      </div>
    );
  };

  const renderTableHeader = (procedures?: Procedure[]) => {
    return (
      <tr>
        {procedures && <td></td>}
        {tableHeaderKeys.map((headerKey, index) => {
          const fullKey = `procedureBox.actions.attach_procedure.modal.dependent_procedure.table.${headerKey}`;
          return <td key={index}>{t(fullKey)}</td>;
        })}
      </tr>
    );
  };

  const renderTableBody = (procedures?: Procedure[]) => {
    const toMap = procedures || proceduresSeleted;
    return toMap.map((procedure) => {
      return (
        <tr>
          {procedures && <td>{renderRadio(procedure)}</td>}
          <td><Link target="_blank" className={styles.link} to={`/procedure_box/show_procedure/${procedure.id}`}>{procedure.process_number}</Link></td>
          <td>{procedure.flux.name}</td>
          <td>{procedure.subject}</td>
          <td>{procedure.individual_name}</td>
          <td>{procedure.interested_parts}</td>
        </tr>
      );
    });
  };

  const loadData = async () => {
    setSelected(undefined);
    const data = await ProcedureBoxService.getProcedureCollectionList(`?q[procedure_numbers_num_cont]=${query}&page=1&per_page=20`);
    setQueryProcedures(data.procedures);
    setLoading(false);
  };

  return (
    <div className={styles.container}>
      <Header
          text={t('procedureBox.actions.citation_intimation.modal.select.title')}
          tooltip={t('procedureBox.actions.citation_intimation.modal.select.tooltip')}
        />
      <div className={styles.referenceProcedure}>
        <span className={styles.description}>
          {t('procedureBox.actions.citation_intimation.modal.select.subtitle')}
        </span>

        <Grid className={styles.searchForm}
          container
          columns={{ xs: 1, sm: 8, md: 8 }}>
        <Grid
          item
          xs={1}
          sm={2}
          md={2} >
            <InputText
              label={t('procedureBox.actions.citation_intimation.modal.destination_procedure.label')}
              placeholder={t('procedureBox.actions.citation_intimation.modal.destination_procedure.placeholder')}
              value={query}
              onChange={(e) => { setQuery(e.currentTarget.value); }}
              required
            />
          </Grid>
          <Button
            title={t('procedureBox.actions.citation_intimation.modal.search')}
            disabled={(!query || loading)}
            buttonType='primary'
            size='flat'
            onClick={() => { setLoading(true); loadData(); }}
            round
          />
        </Grid>
        {loading ? renderLoading() : (
          <ResponsiveContainer width={'99%'} minWidth={900} minHeight={100}>
            <table>
              <thead>
                {renderTableHeader(queryProcedures)}
              </thead>
              <tbody>
                {renderTableBody(queryProcedures)}
              </tbody>
            </table>
        </ResponsiveContainer>)}
      </div>
      <div className={styles.button}>
        <Button
          buttonType={'primary'}
          round
          disabled={!selected}
          size='flat'
          title={t('procedureBox.actions.citation_intimation.modal.button')}
          onClick={handleAlert}
        />
      </div>
    </div>
  );
}
