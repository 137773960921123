import { Grid } from '@mui/material';
import { useContext, useState } from 'react';
import {
  FaChevronDown, FaChevronUp, FaFolderOpen,
} from 'react-icons/fa';
import { t } from 'i18next';
import moment from 'moment';
import { CitationIntimation } from '../../../@types/model';
import { CheckBox } from '../../CheckBox';
import styles from './styles.module.scss';
import { Tooltip } from '../../Tooltip';
import { truncate } from '../../../helpers/strings';
import Show from '../../Show';
import { Expanded } from './Expanded';
import { ProcedureActionsContext } from '../../../context/procedureActionsContext';
import { RoundButton } from '../../RoundButton';

type ItemProps = {
  citationIntimation: CitationIntimation;
  selected: boolean;
  origin: 'collection' | 'default';
};

export function ItemCitationIntimation(props: ItemProps) {
  const [open, setOpen] = useState(false);
  const display: boolean = props.origin !== 'collection';
  const { citationIntimationSelected, setCitationIntimation } = useContext(ProcedureActionsContext);

  const selected = () => {
    return citationIntimationSelected === props.citationIntimation;
  };

  const selectItem = (e: any) => {
    e.stopPropagation();
    if (props.citationIntimation === citationIntimationSelected) {
      setCitationIntimation(undefined);
    } else {
      setCitationIntimation(props.citationIntimation);
    }
  };

  const getGridProps = () => {
    if (display) {
      return { xs: 4, sm: 10, md: 16 };
    }
    return { xs: 4, sm: 10, md: 10 };
  };

  const handleInterestedParts = () => {
    return props.citationIntimation.content ? (
      <span>{t('procedureBox.procedureList.citationIntimation.content.with')}</span>
    ) : (
      <span>{t('procedureBox.procedureList.citationIntimation.content.without')}</span>
    );
  };

  const handleScheduleCollumn = () => {
    return (
      <span className={styles.textInfo}>
        {props.citationIntimation.observation
          ? <Tooltip title={props.citationIntimation.observation}><p className={styles.name}>{t('procedureBox.procedureList.citationIntimation.observation.with')}</p></Tooltip>
          : <span>
              {t('procedureBox.procedureList.citationIntimation.observation.without')}
            </span>
        }
      </span>
    );
  };

  const handleDataCollumn = () => {
    return (
      <span className={styles.textInfo}>
        {moment(new Date(props.citationIntimation.created_at)).format('DD/MM/YYYY')}
      </span>
    );
  };

  const DocVisualizerButton = () => {
    const handleRedirectPageFolder = (event: any) => {
      window.open(
        `/digital_folder/${props.citationIntimation.id}/cn`,
        '_blank',
      );
      event.stopPropagation();
    };

    return (
      <div className={styles.container}>
        <RoundButton
          className={ styles.button }
          onClick={(event: any) => handleRedirectPageFolder(event)}
          icon={<FaFolderOpen/>}
          tooltip={t('procedureBox.procedureList.digitalFolder')}
          size={'medium'}
        />
    </div>
    );
  };

  return (
    <Grid
      container
      columns={getGridProps()}
      className={ `${styles.row} ${selected() ? styles.selected : ''} ${open ? styles.open : ''} low` }
      onClick={() => setOpen(!open)}
    >
      <Grid
        item
        className={ styles.procedureData }
        xs={ 2 }
        sm={ 3 }
        md={ 2 }
      >
        <div
          className={ styles.selectIcon }
          onClick={ (e) => selectItem(e) }
        >
          <CheckBox
            value={selected()}
            uncheckedColor='var(--dark)'
            color='var(--primary)'
            isMarker={false}
          />
        </div>
        <span className={ styles.linkProcedure } >
          {props.citationIntimation.process_number || t('procedureBox.procedureDeadline.noProcedureNumber')}
        </span>
      </Grid>
      <Grid
        onClick={ open ? (e) => e.stopPropagation() : () => {} }
        item
        className={ styles.value }
        sm={ 2 }
        md={ display ? 3 : 2 }
        sx={{ display: { xs: 'none', sm: 'block', md: 'block' } }}
      >
        <Tooltip title={props.citationIntimation.subject}>
          <span className={ styles.linkProcedure } >
            {truncate(props.citationIntimation.subject, 30)}
          </span>
        </Tooltip>
      </Grid>
      <Grid
        onClick={ open ? (e) => e.stopPropagation() : () => {} }
        item
        className={ styles.value }
        sm={ 2 }
        md={ display ? 2 : 1 }
        sx={{ display: { xs: 'none', sm: 'block', md: 'block' } }}
      >
        { handleDataCollumn() }
      </Grid>
      <Grid
        onClick={ open ? (e) => e.stopPropagation() : () => {} }
        item
        className={ styles.value }
        sm={ display ? 3 : 2 }
        md={ display ? 3 : 2 }
        sx={{ display: { xs: 'none', sm: 'none', md: 'block' } }}
      >
        { handleScheduleCollumn() }
      </Grid>
      <Grid
        item
        className={`${styles.value}`}
        sm={ display ? 3 : 2 }
        md={ 2 }
        sx={{ display: { xs: 'none', sm: 'none', md: 'block' } }}
      >
        { handleInterestedParts() }
      </Grid>

      <Grid
        item
        xs={ 1 }
        sm={ 1 }
        md={ 1 }
        sx={{ display: { xs: 'flex', sm: 'flex', md: 'flex' } }}
        className={ styles.actions }
      >
        <Show if={true}>
          <DocVisualizerButton />
        </Show>
        <div className={styles.container}>
          {open ? <FaChevronUp className={styles.chevron} /> : <FaChevronDown className={styles.chevron} />}
        </div>
      </Grid>
      { open ? <Expanded citationIntimation={props.citationIntimation} display={display} /> : null }
    </Grid>
  );
}
