import { t } from 'i18next';
import { useEffect, useState } from 'react';
import { Skeleton } from '@mui/material';
import { CDA } from '../../../@types/digitalProcesses/cdas';
import { Table } from '../../../components/Table';
import { Moment } from '../../../config/moment';
import Format from '../../../helpers/format';
import ProcedureBoxService from '../../../services/procedureBoxService';
import styles from './styles.module.scss';
import { Button } from '../../../components/Button';
import Show from '../../../components/Show';
import { Procedure } from '../../../@types/model';

type CDAsProps = {
  procedure: Procedure;
};

export function CDAs(props: CDAsProps) {
  const [data, setData] = useState<CDA[]>([]);
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    ProcedureBoxService.getCDAs(props.procedure.id).then((res) => {
      setData(res);
      setLoaded(true);
    });
  }, []);

  const getTotalValue = () => {
    let total = 0;
    data.map((cda) => {
      total += parseFloat(cda.balance);
    });
    return Format.currency(total);
  };

  const verifyDate = (date: string) => {
    if (Moment(date).isValid()) {
      return Moment(date).format('DD/MM/YYYY');
    }
    return date;
  };

  const handleEmitExtract = () => {
    const inscriptionsIds = data.map((inscription, index) => {
      return index === 0 ? `inscriptions[]=${inscription.id}` : `&inscriptions[]=${inscription.id}`;
    }).join('');

    ProcedureBoxService.emitExtract(inscriptionsIds).then((resp) => {
      const blob = Format.base64ToBlob(resp, 'application/pdf');
      const fileURL = URL.createObjectURL(blob);
      window.open(fileURL, '_blank');
    });
  };

  return (
    <div className={ styles.container }>
      <div className={styles.header}>
        <div className={styles.extract}>
          <Show if={!!data && ['FLU0051', 'FLU0003'].includes(props.procedure.flux.code)}>
            <Button
              onClick={() => handleEmitExtract()}
              title={t('procedureBox.processExtract.cdas.extract')}
              buttonType='primary' round={true} size='small' />
          </Show>
        </div>
        <p className={styles.title}>{t('procedureBox.processExtract.cdas.total')} {getTotalValue()}</p>
      </div>
      {
        loaded ? (
          <Table>
            <thead>
              <tr>
                <th>{t('procedureBox.processExtract.cdas.table.contributor')}</th>
                <th>{t('procedureBox.processExtract.cdas.table.number')}</th>
                <th>{t('procedureBox.processExtract.cdas.table.step')}</th>
                <th>{t('procedureBox.processExtract.cdas.table.lastChange')}</th>
                <th>{t('procedureBox.processExtract.cdas.table.prescriptionDate')}</th>
                <th>{t('procedureBox.processExtract.cdas.table.value')}</th>
              </tr>
            </thead>
            {
              data.map((cda) => (
                <tbody>
                  <td>{`${cda.contributor.cpf_cnpj} - ${cda.contributor.name}`}</td>
                  <td>{cda.cda_number}</td>
                  <td>{cda.state}</td>
                  <td>{Moment(cda.last_history_state.created_at).format('DD/MM/YYYY')}</td>
                  <td>{verifyDate(cda.prescription_date)}</td>
                  <td>{Format.currency(parseFloat(cda.balance))}</td>
                </tbody>
              ))
            }
          </Table>
        ) : <Skeleton variant={'rectangular'} className={ styles.row }/>
      }
    </div>
  );
}
