import { t } from 'i18next';
import {
  FaPaperclip,
  FaTrashAlt,
  FaPaperPlane,
} from 'react-icons/fa';
import LinkIcon from '@mui/icons-material/Link';
import { useContext } from 'react';
import { RoundButton } from '../../../RoundButton';
import styles from './styles.module.scss';
import { ProcedureActionsContext } from '../../../../context/procedureActionsContext';
import { CitationIntimation } from '../../../../@types/model';

type ItemProps = {
  citationIntimation?: CitationIntimation;
};

export function MenuCitationIntimation(props: ItemProps) {
  const disabled = false;
  const {
    openAttachFile,
    openAttachCitationIntimation,
    openDeleteCitationIntimation,
    openTramitCitationIntimation,
  } = useContext(ProcedureActionsContext);

  return (
  <>
    <div className={ styles.rightBorder }>
      <RoundButton
        className={ styles.button }
        icon={<FaPaperclip/>}
        tooltip={t('procedureBox.actions.attachFiles.title')}
        disabled={disabled}
        disabledText={t('procedureBox.actions.attachFiles.disabled')}
        size='big'
        onClick={() => openAttachFile()}
      />

      <RoundButton
        className={ styles.button }
        icon={<LinkIcon />}
        tooltip={t('procedureBox.actions.citation_intimation.modal.title')}
        disabled={disabled}
        size='big'
        onClick={() => openAttachCitationIntimation()}
      />

      <RoundButton
        className={ styles.button }
        icon={<FaPaperPlane/>}
        tooltip={t('procedureBox.actions.citation_intimation.tramit.modal.title')}
        disabled={disabled}
        size='big'
        onClick={() => openTramitCitationIntimation()}
      />

      <RoundButton
        className={ styles.button }
        icon={<FaTrashAlt/>}
        tooltip={t('procedureBox.actions.citation_intimation.delete.item')}
        disabled={disabled}
        size='big'
        onClick={() => openDeleteCitationIntimation()}
      />
    </div>
  </>
  );
}
