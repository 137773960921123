import { useEffect } from 'react';
import { Grid } from '@mui/material';
import { t } from 'i18next';
import { Controller } from 'react-hook-form';
import { FaTrashAlt } from 'react-icons/fa';
import { InputDate } from '../../../../../components/InputDate';
import { InputText } from '../../../../../components/InputText';
import { RoundButton } from '../../../../../components/RoundButton';
import Select from '../../../../../components/Select';
import { Currency, findCurrency } from '../../../../../helpers/enums';
import styles from './styles.module.scss';

type FormProps = {
  passive?: boolean;
  id: number;
  control: any;
  setValue: (name: string, value: string) => void;
  unregister: (name: string) => void;
  delete: () => void;
  data?: any;
};

export function Form(props: FormProps) {
  const handleDelete = () => {
    props.unregister(`petitioning_cdas_attributes[${props.id}]`);
    props.delete();
  };

  const loadValues = () => {
    if (props.data) {
      props.setValue(`petitioning_cdas_attributes[${props.id}].id`, props.data.id);
      props.setValue(`petitioning_cdas_attributes[${props.id}].number`, props.data.number);
      props.setValue(`petitioning_cdas_attributes[${props.id}].date`, props.data.date);
      props.setValue(`petitioning_cdas_attributes[${props.id}].currency`, props.data.currency);
      props.setValue(`petitioning_cdas_attributes[${props.id}].value`, props.data.value);
    }
  };

  useEffect(() => {
    loadValues();
  }, []);

  return (
    <div className={ styles.container }>
      <Grid
        container
        columns={{ xs: 1, sm: 10, md: 12 }}
      >

        <Grid
          item
          xs={ 1 }
          sm={ 10 }
          md={ 12 }
          className={styles.deleteButton}
        >
          <RoundButton
            icon={<FaTrashAlt />}
            size='small'
            onClick={handleDelete}
          />
        </Grid>
      </Grid>

      <Grid
        container
        columns={{ xs: 1, sm: 10, md: 12 }}
      >
        <Grid
          item
          xs={ 1 }
          sm={ 5 }
          md={ 4 }
          className={styles.input}
        >
          <Controller
            control={props.control}
            name={`petitioning_cdas_attributes[${props.id}].number`}
            render={({ field }) => (
              <InputText
                {...field}
                label={t('procedureBox.newProcedure.newPetitioningInformations.form.cdas.number.label')}
                placeholder={t('procedureBox.newProcedure.newPetitioningInformations.form.cdas.number.placeholder')}
                required
              />
            )}
          />
        </Grid>

        <Grid
          item
          xs={ 1 }
          sm={ 5 }
          md={ 4 }
          className={styles.input}
        >
          <Controller
            control={props.control}
            name={`petitioning_cdas_attributes[${props.id}].date`}
            render={({ field }) => (
              <InputDate
                {...field}
                label={t('procedureBox.newProcedure.newPetitioningInformations.form.cdas.date.label')}
                value={props.data?.date}
                required
              />
            )}
          />
        </Grid>

        <Grid
          item
          xs={ 1 }
          sm={ 5 }
          md={ 4 }
          className={styles.input}
        >
          <Controller
            control={props.control}
            name={`petitioning_cdas_attributes[${props.id}].currency`}
            render={({ field }) => (
              <Select
                {...field}
                label={t('procedureBox.newProcedure.newPetitioningInformations.form.cdas.currency.label')}
                placeholder={t('procedureBox.newProcedure.newPetitioningInformations.form.cdas.currency.placeholder')}
                options={Currency()}
                defaultValue={findCurrency(props.data?.currency)}
                returnValue
                required
              />
            )}
          />
        </Grid>

        <Grid
          item
          xs={1}
          sm={5}
          md={4}
          className={styles.input}
        >
          <Controller
            control={props.control}
            name={`petitioning_cdas_attributes[${props.id}].value`}
            render={({ field }) => (
              <InputText
                {...field}
                label={t('procedureBox.newProcedure.newPetitioningInformations.form.cdas.value.label')}
                placeholder={t('procedureBox.newProcedure.newPetitioningInformations.form.cdas.value.placeholder')}
                currency
                required />
            )} />
        </Grid>
      </Grid>
    </div>
  );
}
