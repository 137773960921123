import { Grid } from '@mui/material';
import { t } from 'i18next';
import { useContext, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Button } from '../../../../components/Button';
import { Header } from '../../../../components/Header';
import { InputText } from '../../../../components/InputText';
import { TextArea } from '../../../../components/TextArea';
import { InputDate } from '../../../../components/InputDate';
import ProcedureBoxService from '../../../../services/procedureBoxService';
import styles from './styles.module.scss';
import FormHelper from '../../../../helpers/form';
import { ProcedureActionsContext } from '../../../../context/procedureActionsContext';
import { maskCpfOrCnpj } from '../../../../helpers/masks';
import Select from '../../../../components/Select';
import Format from '../../../../helpers/format';
import { OptionType } from '../../../../@types/config';
import NewProcessService from '../../../../services/newProcessService';
import { QueryContext } from '../../../../context/queryContext';

type ProurmaDeedEdit = {
  flux_id: string,
  procedure_id: string,

  prourma_deed: {
    registry: OptionType,
    book: string,
    page: string,
    acquisition_type: OptionType,
    ordinance: string,
    goal: string,
    interested_part: string,
    interested_part_document: string,
    deed_signed_at: string,
    street_type: OptionType,
    street: string
  }
};

type NewProurmaDeedProps = {
  fluxId?: number;
  closeModal: () => void;
  procedure?: ProurmaDeedEdit;
};

export function NewProurmaDeed(props: NewProurmaDeedProps) {
  const [registry, setRegistry] = useState([]);
  const [acquisitionType, setAcquisitionType] = useState([]);
  const [streetType, setStreetType] = useState([]);
  const [errors, setErrors] = useState(null);
  const [loading, setLoading] = useState(false);
  const [document, setDocument] = useState('');
  const { setLoadingDetails } = useContext(QueryContext);

  const { addToasts } = useContext(ProcedureActionsContext);
  const {
    handleSubmit,
    control,
    setValue,
  } = useForm();

  const loadProurmaDeedData = async () => {
    await ProcedureBoxService.getNewProurmaDeedData().then((res) => {
      setRegistry(res.data.registry);
      setAcquisitionType(res.data.acquisition_type);
      setStreetType(res.data.street_type);
    });
  };

  const loadFormValues = () => {
    if (props.procedure && props.procedure?.prourma_deed) {
      setValue('registry', props.procedure.prourma_deed.registry.value);
      setValue('book', props.procedure.prourma_deed.book);
      setValue('page', props.procedure.prourma_deed.page);
      setValue('acquisition_type', props.procedure.prourma_deed.acquisition_type.value);
      setValue('ordinance', props.procedure.prourma_deed.ordinance);
      setValue('goal', props.procedure.prourma_deed.goal);
      setValue('interested_part', props.procedure.prourma_deed.interested_part);
      setValue('interested_part_document', props.procedure.prourma_deed.interested_part_document);
      setValue('deed_signed_at', Format.formatDate(props.procedure.prourma_deed.deed_signed_at));
      setValue('street_type', props.procedure.prourma_deed.street_type.value);
      setValue('street', props.procedure.prourma_deed.street);
    }
  };

  useEffect(() => {
    loadProurmaDeedData();
    loadFormValues();
  }, []);

  const createProcedure = (form: any) => {
    const data = { prourma_deed: { ...form }, flux_id: props.fluxId };
    ProcedureBoxService.createProurmaDeed(data)
      .then((res) => {
        if (res.errors) {
          setErrors(res.errors);
        } else {
          props.closeModal();

          addToasts({
            type: 'success',
            text: FormHelper.customToastMessage(res.id),
          });
        }
        setLoading(false);
      })
      .catch((error) => {
        setErrors(error.response.data);
        setLoading(false);
      });
  };

  const updateProcedure = (form: any) => {
    const data = {
      prourma_deed: {
        ...form,
      },
      flux_id: props.procedure?.flux_id,
      procedure_id: props.procedure?.procedure_id,
    };

    NewProcessService.editProurmaDeed(data)
      .then((res) => {
        if (res.errors) {
          setErrors(res.errors);
        } else {
          props.closeModal();
          addToasts({
            type: 'success',
            text: FormHelper.customToastMessageEdit(res.id),
          });
        }
        setLoading(false);
        setLoadingDetails(true);
      })
      .catch((err) => {
        setLoading(false);
        if (err.status === 400) {
          setErrors(err.data);
        }
      });
  };

  const submit = (form: any) => {
    setLoading(true);
    if (props.procedure) {
      updateProcedure(form);
    } else {
      createProcedure(form);
    }
  };

  return (
    <form className={styles.container} onSubmit={handleSubmit(submit)}>
      <Header text={t('procedureBox.newProcedure.newProurmaDeed.form.data')} />
      <Grid container columns={{ xs: 1, sm: 10, md: 12 }}>
        <Grid item xs={1} sm={5} md={4} className={styles.input}>
          <Controller
            control={control}
            name="registry"
            render={({ field }) => (
              <Select
                {...field}
                label={t(
                  'procedureBox.newProcedure.newProurmaDeed.form.registry.label',
                )}
                defaultValue={props.procedure ? props.procedure?.prourma_deed.registry : undefined}
                options={registry}
                returnValue
                required
              />
            )}
          />
          {FormHelper.renderErrorField('registry', errors)}
        </Grid>

        <Grid item xs={1} sm={5} md={4} className={styles.input}>
          <Controller
            control={control}
            name="book"
            render={({ field }) => (
              <InputText
                {...field}
                label={t(
                  'procedureBox.newProcedure.newProurmaDeed.form.livro.label',
                )}
                required
              />
            )}
          />
          {FormHelper.renderErrorField('book', errors)}
        </Grid>

        <Grid item xs={1} sm={5} md={4} className={styles.input}>
          <Controller
            control={control}
            name="page"
            render={({ field }) => (
              <InputText
                {...field}
                label={t(
                  'procedureBox.newProcedure.newProurmaDeed.form.folha.label',
                )}
                required
              />
            )}
          />
          {FormHelper.renderErrorField('page', errors)}
        </Grid>

        <Grid item xs={1} sm={5} md={4} className={styles.input}>
          <Controller
            control={control}
            name="interested_part"
            render={({ field }) => (
              <InputText
                {...field}
                label={t(
                  'procedureBox.newProcedure.newProurmaDeed.form.interested_part.label',
                )}
                required
              />
            )}
          />
          {FormHelper.renderErrorField('interested_part', errors)}
        </Grid>

        <Grid item xs={1} sm={5} md={4} className={styles.input}>
          <Controller
            control={control}
            name="interested_part_document"
            render={({ field }) => (
              <InputText
                {...field}
                label={t(
                  'procedureBox.newProcedure.newProurmaDeed.form.interested_part_document.label',
                )}
                value={document}
                onChange={(e) => {
                  setDocument(maskCpfOrCnpj(e.target.value).mask());
                  field.onChange(e);
                }}
                required
              />
            )}
          />
          {FormHelper.renderErrorField('interested_part_document', errors)}
        </Grid>

        <Grid item xs={1} sm={5} md={4} className={styles.input}>
          <Controller
            control={control}
            name="acquisition_type"
            render={({ field }) => (
              <Select
                {...field}
                label={t(
                  'procedureBox.newProcedure.newProurmaDeed.form.acquisition_type.label',
                )}
                defaultValue={props.procedure ? props.procedure?.prourma_deed.acquisition_type : undefined}
                options={acquisitionType}
                returnValue
                required
              />
            )}
          />
          {FormHelper.renderErrorField('acquisition_type', errors)}
        </Grid>

        <Grid item xs={1} sm={5} md={4} className={styles.input}>
          <Controller
            control={control}
            name="ordinance"
            render={({ field }) => (
              <InputText
                {...field}
                label={t(
                  'procedureBox.newProcedure.newProurmaDeed.form.ordinance.label',
                )}
              />
            )}
          />
          {FormHelper.renderErrorField('ordinance', errors)}
        </Grid>

        <Grid item xs={1} sm={10} md={12} className={styles.input}>
          <Controller
            control={control}
            name="goal"
            render={({ field }) => (
              <TextArea
                {...field}
                label={t(
                  'procedureBox.newProcedure.newProurmaDeed.form.goal.label',
                )}
              />
            )}
          />
          {FormHelper.renderErrorField('goal', errors)}
        </Grid>

        <Grid item xs={1} sm={5} md={4} className={styles.input}>
          <Controller
            control={control}
            name="street_type"
            render={({ field }) => (
              <Select
                {...field}
                label={t(
                  'procedureBox.newProcedure.newProurmaDeed.form.street_type.label',
                )}
                options={streetType}
                defaultValue={props.procedure ? props.procedure?.prourma_deed.street_type : undefined}
                returnValue
              />
            )}
          />
          {FormHelper.renderErrorField('street_type', errors)}
        </Grid>

        <Grid item xs={1} sm={5} md={4} className={styles.input}>
          <Controller
            control={control}
            name="street"
            render={({ field }) => (
              <InputText
                {...field}
                label={t(
                  'procedureBox.newProcedure.newProurmaDeed.form.street.label',
                )}
              />
            )}
          />
          {FormHelper.renderErrorField('street', errors)}
        </Grid>

        <Grid item xs={1} sm={5} md={4} className={styles.input}>
          <Controller
            control={control}
            name="deed_signed_at"
            render={({ field }) => (
              <InputDate
                {...field}
                label={t(
                  'procedureBox.newProcedure.newProurmaDeed.form.deed_signed_at.label',
                )}
                value={props.procedure ? Format.formatDate(props.procedure?.prourma_deed?.deed_signed_at) : ''}
              />
            )}
          />
          {FormHelper.renderErrorField('deed_signed_at', errors)}
        </Grid>
      </Grid>
      <div className={styles.submit}>
        <Button
          title={t(`procedureBox.newProcedure.${props.procedure ? 'edit' : 'submit'}`)}
          textCenter
          round
          buttonType='primary'
          size='flat'
          type='submit'
          disabled={loading}
        />
      </div>
    </form>
  );
}
