import { useEffect, useRef, useState } from 'react';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { Grid } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import ptBR from 'date-fns/locale/pt-BR';
import { t } from 'i18next';
import { FaTimes, FaRegQuestionCircle } from 'react-icons/fa';
import { format, parse, isWeekend } from 'date-fns';
import { PickersDay } from '@mui/x-date-pickers/PickersDay/PickersDay';
import { PickersDayProps } from '@mui/lab';
import { Tooltip } from '../Tooltip';
import styles from './styles.module.scss';
import { Label } from '../Label';
import { maskDate, maskDateTime } from '../../helpers/masks';
import ScheduleService from '../../services/scheduleService';

type InputDateProps = {
  dataCy?: string;
  label?: string;
  placeholder?: string;
  className?: string;
  tooltip?: string;
  value?: string;
  required?: boolean;
  datetime?: boolean;
  readOnly?: boolean;
  fixedValue?: boolean;
  onChange: (value: string) => void;
};

export function InputDate(props: InputDateProps) {
  const [open, setOpen] = useState(false);
  const [value, setValue] = useState<string>(props.value || '');
  const [holidays, setHolidays] = useState<string[]>([]);
  const componentRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (props.fixedValue) {
      setValue(props.value || '');
    }
  }, [props.value]);

  const getHolidays = async () => {
    await ScheduleService.getAllHolidays().then((r) => {
      setHolidays(r.holidays.map((holiday) => holiday.date));
    });
  };

  useEffect(() => {
    getHolidays();
  }, []);

  const handleOutsideClick = (event: MouseEvent) => {
    const isOutsideComponent = !event.composedPath().some((el: any) => el === componentRef.current);
    const isOutsidePicker = !event.composedPath().some((el: any) => el.classList && el.classList.contains('MuiCalendarPicker-root'));
    if (isOutsidePicker && isOutsideComponent) {
      setOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleOutsideClick);

    return () => {
      document.removeEventListener('click', handleOutsideClick);
    };
  }, []);

  const handleOnChange = (newValue: string | null) => {
    if (props.fixedValue && props.value && newValue) {
      props.onChange(newValue);
      setValue(props.value);
    } else if (newValue) {
      setValue(newValue);
      props.onChange(newValue);
    } else {
      setValue('');
      props.onChange('');
    }
  };

  const formatDate = (date: Date): string => {
    return `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')}`;
  };

  const returnDay = (day: Date, selectedDays: Date[], pickersDayProps: PickersDayProps<Date>): JSX.Element => {
    const validation = isWeekend(day) || holidays.includes(formatDate(day));
    return <PickersDay {...pickersDayProps} className={validation ? styles.disabled : ''} day={day} />;
  };

  const renderInput = (inputRef: any, inputProps: any) => {
    return (
      <div className={styles.wrapper}>
        <input
          data-cy={props.dataCy}
          readOnly={props.readOnly}
          className={styles.input}
          placeholder={props.placeholder}
          ref={inputRef}
          {...inputProps}
          onClick={() => {
            setOpen(true);
          }}
          required={props.required}
          value={value}
          onChange={(event) => {
            handleOnChange(props.datetime ? maskDateTime(event.target.value) : maskDate(event.target.value));
          }}
        />
        <span hidden={Boolean(!value)} className={styles.icon} onClick={() => handleOnChange(null)}>
          <FaTimes />
        </span>
      </div>
    );
  };

  const renderPicker = () => {
    if (props.datetime) {
      return (
        <DateTimePicker
          value={value ? parse(value, 'yyyy-MM-dd HH:mm', new Date()) : null}
          open={open}
          toolbarTitle={false}
          showToolbar={false}
          cancelText={t('procedureBox.procedureDeadline.cancel')}
          onAccept={(newValue) => {
            handleOnChange(newValue ? format(newValue, 'dd/MM/yyyy HH:mm') : null);
          }}
          renderInput={({ inputRef, inputProps }) => (
            renderInput(inputRef, inputProps)
          )}
          onChange={(newValue) => {
            handleOnChange(newValue ? format(newValue, 'dd/MM/yyyy HH:mm') : null);
          }}
        />
      );
    }
    return (
      <DatePicker
        value={value ? parse(value, 'yyyy-MM-dd', new Date()) : null}
        open={open}
        toolbarTitle={false}
        showToolbar={false}
        renderDay={returnDay}
        cancelText={t('procedureBox.procedureDeadline.cancel')}
        onChange={(newValue) => {
          handleOnChange(newValue ? format(newValue, 'dd/MM/yyyy') : null);
        }}
        renderInput={({ inputRef, inputProps }) => (
          renderInput(inputRef, inputProps)
        )}
      />
    );
  };

  return (
    <LocalizationProvider locale={ptBR} dateAdapter={AdapterDateFns}>
      <Grid className={props.className} ref={componentRef}>
        <div className={styles.label}>
          {props.label && <Label text={props.label} required={props.required} />}
          {props.tooltip
            ? (
              <Tooltip title={ props.tooltip }>
                <div className={styles.containerTag}>
                  <FaRegQuestionCircle />
                </div>
              </ Tooltip>
            ) : null}
        </div>
        {renderPicker()}
      </Grid>
    </LocalizationProvider>
  );
}
