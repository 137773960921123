import { useEffect, useState } from 'react';
import { Grid } from '@mui/material';
import { t } from 'i18next';
import { Controller, useForm } from 'react-hook-form';
import { useLocation } from 'react-router-dom';
import { loadTitle } from '../../../helpers/changeTitle';
import styles from './styles.module.scss';
import { Breadcumb } from '../../../components/Breadcumb';
import { InputText } from '../../../components/InputText';
import { AutoCompleteRemote } from '../../../components/SearchBar/FilterAdvanced/AutoComplete';
import ProcedureBoxService from '../../../services/procedureBoxService';
import Select from '../../../components/Select';
import { PaymentOrderReport } from '../../../@types/digitalProcesses/reports';
import ReportsService from '../../../services/reportsService';
import { OptionType } from '../../../@types/config';
import { InputDate } from '../../../components/InputDate';
import { Button } from '../../../components/Button';
import { OrderSelect } from '../Calculation/OrderSelect';
import { orderPaymentReport } from '../../../helpers/enums';
import { Table } from '../../../components/Table';
import { EmptyList } from '../EmptyList';
import { Loading } from '../Loading';

type PaymentOrdersProps = {
  title?: string;
};

interface QueryObject {
  [key: string]: any;
}
export function PaymentOrders(props: PaymentOrdersProps) {
  const { handleSubmit, control, setValue } = useForm();

  const { pathname } = useLocation();
  const [data, setData] = useState<PaymentOrderReport[]>([]);
  const [query, setQuery] = useState('');
  const [orderBy, setOrderBy] = useState<string>('order_type asc');
  const [courts, setCourts] = useState<OptionType[]>([]);
  const [orderTypes, setOrderTypes] = useState<OptionType[]>([]);
  const [processCategories, setProcessCategories] = useState<OptionType[]>([]);
  const [status, setStatus] = useState<OptionType[]>([]);
  const [loading, setLoading] = useState(false);

  const formatValue = (value: any): string => {
    if (typeof value === 'object' && value !== null && 'value' in value) {
      return value.value.toString();
    }
    if (typeof value === 'string' && value.includes('R$')) {
      return value.replace('R$ ', '');
    }
    return value.toString();
  };

  const clearQuery = (obj: QueryObject): string => {
    let queryString = '';
    for (const key in obj) {
      if (Object.prototype.hasOwnProperty.call(obj, key)) {
        const value = obj[key];
        if (value !== undefined && value !== '') {
          const formattedValue = formatValue(value);
          queryString += `q[${key}]=${formattedValue}&`;
        }
      }
    }
    return queryString.slice(0, -1);
  };

  const columns = [
    t('reports.paymentOrder.table.part_information'),
    t('reports.paymentOrder.table.procedure_number'),
    t('reports.paymentOrder.table.spu_number'),
    t('reports.paymentOrder.table.order_type_i18n'),
    t('reports.paymentOrder.table.parent_category'),
    t('reports.paymentOrder.table.court_name'),
    t('reports.paymentOrder.table.payment_value'),
    t('reports.paymentOrder.table.period'),
    t('reports.paymentOrder.table.judicial_order_number'),
    t('reports.paymentOrder.table.origin_document_date'),
    t('reports.paymentOrder.table.judicial_order_deadline'),
    t('reports.paymentOrder.table.status'),
  ];

  useEffect(() => {
    loadTitle(props.title);
    ReportsService.getDependenciesPaymentOrder().then((response) => {
      setCourts(response.courts);
      setOrderTypes(response.order_types);
      setProcessCategories(response.process_categories);
      setStatus(response.status);
    });
  }, []);

  const executeQuery = (orderString: string, queryString: string) => {
    setData([]);
    setLoading(true);
    if (typeof queryString === 'string' && queryString !== '') {
      ReportsService.getPaymentOrderList(orderString, queryString).then(
        (res) => {
          setData(res);
          setLoading(false);
        },
      );
    } else {
      setLoading(false);
    }
  };

  const handleOrder = (order: string) => {
    setOrderBy(order);
    executeQuery(order, query);
  };

  const submit = (form: any) => {
    const formattedForm = clearQuery(form);
    setQuery(formattedForm);
    executeQuery(orderBy, formattedForm);
  };

  const clearFilter = () => {
    window.location.reload();
  };

  const renderLoading = () => {
    if (loading) {
      return <Loading />;
    } if (query.length > 0) {
      return <EmptyList />;
    }
    return <></>;
  };

  const renderTable = () => {
    return (
      <>
        <Grid item className={styles.order} xs={12}>
          <OrderSelect
            label={t('reports.calculation.table.orderBy')}
            onChange={handleOrder}
            options={orderPaymentReport}
          />
        </Grid>
        <Table>
          <thead>
            <tr>
              {columns.map((column) => (
                <th>{column}</th>
              ))}
            </tr>
          </thead>
          {data.map((value) => (
            <tbody>
              <td>{value.part_information}</td>
              <td>{value.procedure_number}</td>
              <td>{value.spu_number}</td>
              <td>{value.order_type_i18n}</td>
              <td>{value.parent_category}</td>
              <td>{value.court_name}</td>
              <td>{value.payment_value}</td>
              <td>{value.period}</td>
              <td>{value.judicial_order_number}</td>
              <td>{value.origin_document_date}</td>
              <td>{value.judicial_order_deadline}</td>
              <td>{value.state}</td>
            </tbody>
          ))}
        </Table>
      </>
    );
  };

  return (
    <div className={styles.container}>
      <Grid
        container
        columns={{ xs: 2, sm: 12, md: 12 }}
        spacing={2}
      >
        <Grid item xs={12}>
          <Grid item xs={3} sm={6}>
            <h1>{t('reports.paymentOrder.title')}</h1>
            <Breadcumb pathnames={pathname.split('/').filter((x) => x)} />
          </Grid>
        </Grid>
        <Grid item xs={12} className={styles.content}>
          <form onSubmit={handleSubmit(submit)}>
            <Grid container columns={{ xs: 7, sm: 7, md: 7 }} spacing={3}>
              <Grid item xs={7} sm={7} md={1}>
                <Controller
                  control={control}
                  name="order_type_eq"
                  render={({ field }) => (
                    <Select
                      {...field}
                      label={t(
                        'reports.paymentOrder.form.requisitionType.title',
                      )}
                      options={orderTypes}
                      placeholder={t(
                        'reports.paymentOrder.form.requisitionType.placeholder',
                      )}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={7} sm={7} md={2}>
                <AutoCompleteRemote
                  id="contributor"
                  title={t('reports.paymentOrder.form.beneficiary.title')}
                  placeholder={t(
                    'reports.paymentOrder.form.beneficiary.placeholder',
                  )}
                  getUrl={ProcedureBoxService.getContributors}
                  setData={(e) => setValue('interested_part_eq', e.value)}
                />
              </Grid>
              <Grid item xs={7} sm={7} md={1}>
                <Controller
                  control={control}
                  name="process_number_eq"
                  render={({ field }) => (
                    <InputText
                      {...field}
                      label={t(
                        'reports.paymentOrder.form.processNumber.title',
                      )}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={7} sm={7} md={1}>
                <Controller
                  control={control}
                  name="spu_number_eq"
                  render={({ field }) => (
                    <InputText
                      {...field}
                      label={t('reports.paymentOrder.form.spuNumber.title')}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={7} sm={7} md={2}>
                <Controller
                  control={control}
                  name="process_category_eq"
                  render={({ field }) => (
                    <Select
                      {...field}
                      label={t('reports.paymentOrder.form.object.title')}
                      options={processCategories}
                      placeholder={t(
                        'reports.paymentOrder.form.object.placeholder',
                      )}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={7} sm={7} md={1}>
                <Controller
                  control={control}
                  name="payment_order_court_id_eq"
                  render={({ field }) => (
                    <Select
                      {...field}
                      label={t('reports.paymentOrder.form.vara.title')}
                      options={courts}
                      placeholder={t(
                        'reports.paymentOrder.form.vara.placeholder',
                      )}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={7} sm={7} md={2}>
                <Controller
                  control={control}
                  name="state_eq"
                  render={({ field }) => (
                    <Select
                      {...field}
                      label={t('reports.paymentOrder.form.situation.title')}
                      options={status}
                      placeholder={t(
                        'reports.paymentOrder.form.situation.placeholder',
                      )}
                    />
                  )}
                />
              </Grid>
            </Grid>
            <Grid
              className={styles.selectable}
              container
              columns={{ xs: 8, sm: 8, md: 8 }}
              spacing={3}
            >
              <Grid
                item
                xs={8}
                sm={8}
                md={2}
                className={styles.itemSelectable}
              >
                <Controller
                  control={control}
                  name="period_initial_gteq"
                  render={({ field }) => (
                    <InputDate
                      {...field}
                      label={t('reports.paymentOrder.form.period.title')}
                      fixedValue
                    />
                  )}
                />
                <Controller
                  control={control}
                  name="period_final_lteq"
                  render={({ field }) => (
                    <InputDate {...field} label={''} fixedValue />
                  )}
                />
              </Grid>
              <Grid
                item
                xs={8}
                sm={8}
                md={2}
                className={styles.itemSelectable}
              >
                <Controller
                  control={control}
                  name="origin_document_date_gteq"
                  render={({ field }) => (
                    <InputDate
                      {...field}
                      label={t('reports.paymentOrder.form.sefinDate.title')}
                      fixedValue
                    />
                  )}
                />
                <Controller
                  control={control}
                  name="origin_document_date_lteq"
                  render={({ field }) => (
                    <InputDate {...field} label={''} fixedValue />
                  )}
                />
              </Grid>
              <Grid
                item
                xs={8}
                sm={8}
                md={2}
                className={styles.itemSelectable}
              >
                <Controller
                  control={control}
                  name="payment_value_gteq"
                  render={({ field }) => (
                    <InputText
                      {...field}
                      label={t('reports.paymentOrder.form.value.title')}
                      placeholder={t('reports.general.gteq')}
                      currency
                    />
                  )}
                />
                <Controller
                  control={control}
                  name="payment_value_lteq"
                  render={({ field }) => (
                    <InputText
                      {...field}
                      label={''}
                      placeholder={t('reports.general.lteq')}
                      currency
                    />
                  )}
                />
              </Grid>
              <Grid
                item
                xs={8}
                sm={8}
                md={2}
                className={styles.itemSelectable}
              >
                <Controller
                  control={control}
                  name="official_letter_deadline_gteq"
                  render={({ field }) => (
                    <InputDate
                      {...field}
                      label={t(
                        'reports.paymentOrder.form.responseDeadline.title',
                      )}
                      fixedValue
                    />
                  )}
                />
                <Controller
                  control={control}
                  name="official_letter_deadline_lteq"
                  render={({ field }) => (
                    <InputDate {...field} label={''} fixedValue />
                  )}
                />
              </Grid>
            </Grid>
            <Grid
              className={styles.selectable}
              container
              columns={{ xs: 8, sm: 8, md: 8 }}
              flexDirection={'row'}
            >
              <Grid className={styles.submit} item xs={8} sm={8} md={2}>
                <Button
                  title={t('reports.general.clear')}
                  buttonType="default"
                  size="flat"
                  type="button"
                  round
                  onClick={clearFilter}
                />
                <Button
                  title={t('reports.general.filter')}
                  buttonType="primary"
                  size="flat"
                  disabled={loading}
                  round
                />
              </Grid>
            </Grid>
          </form>
          {data.length > 0 ? renderTable() : renderLoading()}
        </Grid>
      </Grid>
    </div>
  );
}
