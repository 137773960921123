import { useEffect, useState } from 'react';
import { Tooltip } from '@mui/material';
import { t } from 'i18next';
import { FaCheckCircle, FaFileAlt, FaTrashAlt } from 'react-icons/fa';
import { AttachDocument, OptionType } from '../../../@types/config';
import { CheckBox } from '../../CheckBox';
import { ResponsiveList } from '../ResponsiveList';
import styles from './styles.module.scss';
import SelectionBoxMenu from '../../SelectionBoxMenu';
import Select from '../../Select';
import { orderOptionType } from '../../../helpers/strings';
import ProcedureBoxService from '../../../services/procedureBoxService';

type ListFilesProps = {
  documents: any[];
  removeFile: (files: AttachDocument[]) => void;
  handleSign: (document: AttachDocument) => void;
  handleType: (documents: AttachDocument[], type: string) => void;
  isMobile?: boolean;
  joinDocuments?: boolean;
};

type DocumentOptions = {
  id: string;
  label: string;
  value: any;
};

export function ListFiles(props: ListFilesProps) {
  const [filteredData, setFilteredData] = useState<OptionType[]>([]);

  useEffect(() => {
    ProcedureBoxService.getDocumentTypes().then((res) => {
      setFilteredData(orderOptionType(res));
    });
  }, []);

  const [files, setFiles] = useState<AttachDocument[]>([]);
  const [documents, setDocuments] = useState<DocumentOptions[]>(
    props.documents.map((document: AttachDocument) => ({ id: document.id, label: '', value: '' })),
  );

  const getValue = (documentId: string) => {
    const optionDocument = documents.find((document: DocumentOptions) => document.id === documentId);

    if (optionDocument) {
      return { label: optionDocument.label, value: optionDocument.value };
    }
    return { label: '', value: '' };
  };

  const changeDocumentTypes = (documentId: string, event: OptionType) => {
    const selectedsIds = files.map((file) => file.id);
    const list = props.documents.map((document: AttachDocument) => {
      if ((selectedsIds.includes(document.id) && selectedsIds.includes(documentId)) || document.id === documentId) {
        return { id: document.id, label: event.label, value: event.value };
      }
      const documentOption = getValue(document.id);
      return { id: document.id, label: documentOption.label, value: documentOption.value };
    });
    setDocuments(list);
  };

  const selectFile = (file :any) => {
    const archive = files.find((f) => f.id === file.id);
    if (!archive) {
      setFiles([...files, file]);
    } else {
      const filesNewState = files.filter((f) => f.id !== file.id);
      setFiles(filesNewState);
    }
  };

  const selectAll = () => {
    setFiles(props.documents);
  };

  const unselectAll = () => {
    setFiles([]);
  };

  const getDocuments = (document: AttachDocument) => {
    const findDocument = files.find((obj) => obj.id === document.id);
    if (!findDocument) {
      return [document];
    }

    return files;
  };

  const handleDocuments = (event: any, document: AttachDocument) => {
    if (event) {
      props.handleType(getDocuments(document), event.value);
      changeDocumentTypes(document.id, event);
    }
  };

  return (
    <div className={ styles.container }>
      {
        !props.isMobile ? (
          <table className={styles.table}>
            <thead>
              <tr>
                <th>
                  <SelectionBoxMenu
                    onClickAll={() => selectAll()}
                    onClickNone={() => unselectAll()}
                    selectedCount={files.length}
                    countTotal={props.documents.length}
                  />
                </th>
                <th>{t('procedureBox.actions.prepareDocument.modal.form.list.fileName')}</th>
                <th>{t('procedureBox.actions.prepareDocument.modal.form.list.type')}</th>
                { !props.joinDocuments && <th>{t('procedureBox.actions.prepareDocument.modal.form.list.signed')}</th> }
                <th>{t('procedureBox.actions.prepareDocument.modal.form.list.delete')}</th>
              </tr>
            </thead>
          {
            props.documents.map((document) => (
              <tbody>
                <td>
                  <CheckBox
                    value={!!files.find((f) => f.id === document.id)}
                    onClick={() => selectFile(document)}
                    color='var(--primary)'
                  />
                </td>
                <td>
                  <FaFileAlt />
                  <a target='_blank'>
                    {document.file.name}
                  </a>
                </td>
                <td>
                  <Select
                    label={''}
                    value={getValue(document.id)}
                    options={filteredData}
                    placeholder={t('procedureBox.actions.prepareDocument.modal.form.list.typePlaceholder')}
                    required
                    onChange={(e) => handleDocuments(e, document)}
                  />
                </td>
                { !props.joinDocuments && (
                  <td>
                    { document.sign && (<FaCheckCircle className={styles.check} color='var(--success)'/>) }
                  </td>
                )}
                <td>
                  <>
                  <Tooltip title={t('procedureBox.actions.prepareDocument.modal.form.list.deleteTooltip') || ''}>
                    <a className={styles.buttonDelete} onClick={() => props.removeFile(getDocuments(document))}>
                      <FaTrashAlt />
                    </a>
                  </Tooltip>
                  </>
                </td>
              </tbody>
            ))
          }
          </table>
        ) : <ResponsiveList documents={props.documents} /> }
    </div>
  );
}
