import { t } from 'i18next';
import { useContext, useEffect, useState } from 'react';
import {
  FaCalendarAlt,
  FaFileAlt,
  FaFlag,
  FaUserAlt,
  FaCheckCircle,
  FaChevronLeft,
  FaChevronRight,
} from 'react-icons/fa';
import { AttachedDocument, ResponseAttachedDocument } from '../../@types/config';
import { ProcedureActionsContext } from '../../context/procedureActionsContext';
import { getPaginationTitle } from '../../helpers/strings';
import ProcedureBoxService from '../../services/procedureBoxService';
import { CheckBox } from '../CheckBox';
import { RoundButton } from '../RoundButton';
import SelectionBoxMenu from '../SelectionBoxMenu';
import styles from './styles.module.scss';
import Format from '../../helpers/format';

type DocumentListProps = {
  onChange: (value: AttachedDocument[]) => void;
  documents: AttachedDocument[];
  documentsList?: [] | any[];
  removePagination?: boolean;
  isMobile?: boolean;
  removeSelected?: boolean;
  bulk?: boolean;
  sizeField?: boolean;
  selectColor?: string;
};

export function DocumentList(props: DocumentListProps) {
  const { proceduresSeleted } = useContext(ProcedureActionsContext);
  const [selectedDocuments, setSelectedDocuments] = useState<AttachedDocument[]>(props.documents || []);
  const [data, setData] = useState<AttachedDocument[]>([]);
  const [currentData, setCurrentData] = useState<AttachedDocument[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const totalPerPage = 10;

  useEffect(() => {
    if (props.documentsList) {
      setCurrentData(props.documentsList);
    }
  }, [props.documentsList]);

  useEffect(() => {
    if (!props.documentsList) {
      if (proceduresSeleted.length < 2) {
        ProcedureBoxService.getAttachedDocuments(proceduresSeleted[0].id)
          .then((res) => {
            const response: ResponseAttachedDocument = res;
            setData(response.archives);
            const lastIndex = currentPage * totalPerPage;
            const firstIndex = lastIndex - totalPerPage;
            setCurrentData(response.archives.slice(firstIndex, lastIndex));
          });
      } else {
        const ids = proceduresSeleted.map((procedure) => procedure.id);
        ProcedureBoxService.getAttachedDocumentsInBulk(ids)
          .then((res) => {
            const response: ResponseAttachedDocument = res;
            setData(response.archives);
            const lastIndex = currentPage * totalPerPage;
            const firstIndex = lastIndex - totalPerPage;
            setCurrentData(response.archives.slice(firstIndex, lastIndex));
          });
      }
    }
  }, []);

  useEffect(() => {
    props.onChange(selectedDocuments);
  }, [selectedDocuments]);

  const handleClick = (doc: AttachedDocument) => {
    if (selectedDocuments.includes(doc)) {
      setSelectedDocuments(selectedDocuments.filter((el) => el.file_id !== doc.file_id));
    } else {
      setSelectedDocuments([...selectedDocuments, doc]);
    }
  };

  const selectAll = () => {
    setSelectedDocuments(currentData);
  };

  const unselectAll = () => {
    setSelectedDocuments([]);
  };

  const handlePage = (event: string) => {
    const totalPages = Math.floor(data.length / totalPerPage) + 1;
    let page = 1;
    if (event === 'prev' && currentPage > 1 && totalPages > 1) {
      page = currentPage - 1;
    } else if (event === 'next' && currentPage < totalPages) {
      page = currentPage + 1;
    }
    const lastIndex = page * totalPerPage;
    const firstIndex = lastIndex - totalPerPage;
    setCurrentData(data.slice(firstIndex, lastIndex));
    setCurrentPage(page);
  };

  const checkDocumentSelected = (document: AttachedDocument): boolean => {
    return !!selectedDocuments.find((item) => item.file_id === document.file_id && item.file_class === document.file_class);
  };

  return (
    <div className={ styles.container }>
      {
        !props.removePagination && (
          <div className={`${styles.pagination}`}>
            <span className={`${styles.paginationItem}`}>{getPaginationTitle(currentPage, totalPerPage, data.length)}</span>
            <RoundButton
              className={ styles.button }
              icon={<FaChevronLeft/>}
              size='small'
              onClick={() => handlePage('prev')}
            />
            <RoundButton
              className={ styles.button }
              icon={<FaChevronRight/>}
              size='small'
              onClick={() => handlePage('next')}
            />
      </div>
        )
      }
      {
        !props.isMobile ? (
          <table
            className={styles.table}
            data-cy={'table_documents'}
          >
            <thead>
              <tr>
                {!props.removeSelected && (
                  <th>
                    <SelectionBoxMenu
                      onClickAll={() => selectAll()}
                      onClickNone={() => unselectAll()}
                      selectedCount={selectedDocuments.length}
                      countTotal={data.length}
                      color={!props.selectColor ? 'var(--danger)' : props.selectColor}
                    />
                  </th>)
                }
                {props.bulk && <th>{t('procedureBox.actions.removeDocument.modal.documentList.processNumber')}</th>}
                <th>{t('procedureBox.actions.removeDocument.modal.documentList.fileName')}</th>
                {props.sizeField && <th>{t('procedureBox.actions.removeDocument.modal.documentList.size')}</th>}
                <th>{t('procedureBox.actions.removeDocument.modal.documentList.date')}</th>
                <th>{t('procedureBox.actions.removeDocument.modal.documentList.owner')}</th>
                <th>{t('procedureBox.actions.removeDocument.modal.documentList.type')}</th>
                <th>{t('procedureBox.actions.removeDocument.modal.documentList.signed')}</th>
                <th>{t('procedureBox.actions.removeDocument.modal.documentList.cosigned')}</th>
              </tr>
            </thead>
            {
             currentData.map((document: any) => (
                <tbody>
                  {
                    !props.removeSelected && (
                      <td>
                        <CheckBox
                          value={checkDocumentSelected(document)}
                          disabled={document.disabled}
                          uncheckedColor='var(--dark)'
                          color={!props.selectColor ? 'var(--danger)' : props.selectColor}
                          isMarker={false}
                          onClick={() => handleClick(document)}
                        />
                      </td>
                    )
                  }
                  {props.bulk && <th>{document.process_number}</th>}
                <td style={checkDocumentSelected(document) ? { color: !props.selectColor ? 'var(--danger)' : props.selectColor } : {}}>
                    <FaFileAlt />
                    <a
                      style={checkDocumentSelected(document) ? { color: !props.selectColor ? 'var(--danger)' : props.selectColor } : {}}
                      href={document.url}
                      target='_blank'
                      data-cy={'document_name'}
                    >
                      {document.file_name}
                    </a>
                  </td>
                  {
                    props.sizeField && (
                      <td style={checkDocumentSelected(document) ? { color: !props.selectColor ? 'var(--danger)' : props.selectColor } : {}}>
                        {document.size}
                      </td>
                    )
                  }
                  <td
                    style={checkDocumentSelected(document) ? { color: !props.selectColor ? 'var(--danger)' : props.selectColor } : {}}
                    data-cy={'document_created_at'}
                    id={document.created_at}
                  >
                    <FaCalendarAlt />
                    {Format.formatDate(document.created_at)}
                  </td>
                  <td
                    style={checkDocumentSelected(document) ? { color: !props.selectColor ? 'var(--danger)' : props.selectColor } : {}}
                    data-cy={'document_individual'}
                  >
                    <FaUserAlt />
                    {document.individual_name}
                  </td>
                  <td
                    style={checkDocumentSelected(document) ? { color: !props.selectColor ? 'var(--danger)' : props.selectColor } : {}}
                    data-cy="document_type"
                  >
                    <FaFlag />
                    {document.type}
                  </td>
                  <td style={checkDocumentSelected(document) ? { color: !props.selectColor ? 'var(--danger)' : props.selectColor } : {}}>
                    {document.signed
                      ? (
                        <>
                          {document.signed}
                          <FaCheckCircle color='var(--success)'/>
                        </>
                      ) : null
                    }
                  </td>
                  <td></td>
                </tbody>
             ))}
          </table>
        ) : (
          <table className={styles.table}>
            <thead>
              <tr>
                <th>
                  <SelectionBoxMenu
                    onClickAll={() => selectAll()}
                    onClickNone={() => unselectAll()}
                    selectedCount={selectedDocuments.length}
                    countTotal={data.length}
                    color={'var(--danger)'}
                  />
                </th>
                <th>{t('procedureBox.actions.removeDocument.modal.documentList.fileName')}</th>
              </tr>
            </thead>
            {
              currentData.map((document) => (
                <tbody>
                  <td>
                    <CheckBox
                      value={checkDocumentSelected(document)}
                      uncheckedColor='var(--dark)'
                      color='var(--danger)'
                      isMarker={false}
                      onClick={() => handleClick(document)}
                    />
                  </td>
                  <td style={checkDocumentSelected(document) ? { color: !props.selectColor ? 'var(--danger)' : props.selectColor } : {}} className={props.isMobile ? styles.mobile : ''}>
                    <div className={styles.itemMobile}>
                      <FaFileAlt />
                      <a
                        style={checkDocumentSelected(document) ? { color: !props.selectColor ? 'var(--danger)' : props.selectColor } : {}}
                        href={document.url}
                        target='_blank'
                        data-cy={'document_name'}
                      >
                        {document.file_name}
                      </a>
                    </div>
                    <div
                      className={styles.itemMobile}
                      data-cy={'document_created_at'}
                      id={document.created_at}
                    >
                      <FaCalendarAlt />
                      {Format.formatDate(document.created_at)}
                    </div>
                    <div
                      className={styles.itemMobile}
                      data-cy={'document_individual'}
                    >
                      <FaUserAlt />
                      {document.individual_name}
                    </div>
                    <div
                      className={styles.itemMobile}
                      data-cy={'document_type'}
                    >
                      <FaFlag />
                      {document.type}
                    </div>
                    <div className={styles.itemMobile}>
                      {document.signed
                        ? (
                          <>
                            {document.signed}
                            <FaCheckCircle color='var(--success)'/>
                          </>
                        ) : null
                      }
                    </div>
                    <div className={styles.itemMobile}>
                      {document.cosigned
                        ? (
                          <>
                            {document.cosigned}
                            <FaCheckCircle color='var(--success)'/>
                          </>
                        ) : null
                      }
                    </div>
                  </td>
                </tbody>
              ))
            }
          </table>
        )
      }
    </div>
  );
}
