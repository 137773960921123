import {
  useEffect,
  useState,
  useRef,
} from 'react';
import { useForm } from 'react-hook-form';
import { Grid } from '@mui/material';
import { t } from 'i18next';
import { useLocation } from 'react-router-dom';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import listPlugin from '@fullcalendar/list';
import ptLocale from '@fullcalendar/core/locales/pt';
import moment from 'moment';
import { Controls, Player } from '@lottiefiles/react-lottie-player';
import { loadTitle } from '../../../helpers/changeTitle';
import styles from './styles.module.scss';
import { Breadcumb } from '../../../components/Breadcumb';
import { Item } from '../Item';
import ScheduleService from '../../../services/scheduleService';
import './fullcalendar.scss';
import Show from '../../../components/Show';
import { ProcedureDeadline } from '../../../@types/schedule';
import { ShowDeadline } from '../ShowDeadline';
import { EitherFilter } from '../EitherFilter';
import { SelectFilter } from '../../../components/SelectFilter';
import { OptionType } from '../../../@types/config';
import { Button } from '../../../components/Button';

const LOADING_ANIMATION = require('../../../assets/lotties/loading.json');

type ScheduleProps = {
  title?: string;
};

export function Calendar(props: ScheduleProps) {
  const [schedules, setSchedules] = useState<ProcedureDeadline[]>([]);
  const [reasons, setReasons] = useState<OptionType[]>([]);
  const [data, setData] = useState<any[]>([]);
  const [deadlines, setDeadlines] = useState<ProcedureDeadline[]>([]);
  const [deadline, setDeadline] = useState<ProcedureDeadline>();
  const [query, setQuery] = useState('');
  const calendarRef = useRef<any>(null);
  const [dateSelected, setDateSelected] = useState('');
  const [loading, setLoading] = useState(false);
  const { pathname } = useLocation();
  const { handleSubmit } = useForm();
  const [reasonsSelected, setReasonsSelected] = useState<number[]>([]);
  const [filters, setFilters] = useState('');
  const [box, setBox] = useState();
  const i18nkey = 'schedule.filters.box';
  const itens = [
    {
      label: `${i18nkey}.all`,
      value: 'all',
    },
    {
      label: `${i18nkey}.mySchedule`,
      value: 'my_schedule',
    },
    {
      label: `${i18nkey}.external`,
      value: 'external',
    },
  ];

  const getKeyQuery = (key: any, value: any) => {
    return `q[${key}]=${value}`;
  };

  const loadSchedules = async (queryParam: string) => {
    const res = await ScheduleService.getAll(queryParam);
    setSchedules(res.schedules);
    return res;
  };

  const setNewQuery = () => {
    if (calendarRef.current) {
      const range = calendarRef.current.calendar.currentData.dateProfile.renderRange;
      const initialDay = moment(range.start).format('DD/MM/YYYY');
      const endDay = moment(range.end).format('DD/MM/YYYY');
      const start = `${getKeyQuery('deadline_gteq', initialDay)}`;
      const end = `&${getKeyQuery('deadline_lteq', endDay)}`;
      let q = `${start}${end}`;

      if (filters) {
        q += filters;
      }

      loadSchedules(q);
      setQuery(q);
    }
  };

  useEffect(() => {
    loadTitle(props.title);
    setFilters('');
    if (query) {
      loadSchedules(query);
    } else {
      setNewQuery();
    }
  }, []);

  const getBackgroundColor = (dateString: string | null, checked: boolean | undefined) => {
    const date = moment(dateString);
    const today = moment().endOf('day');
    const deadlineParsed = moment(date.format('YYYY-MM-DD')).endOf('day');

    const difference = deadlineParsed.diff(today, 'days', false);
    if (difference < 0) {
      return 'completed';
    }
    if (difference <= 1) {
      return 'delayed';
    }
    if (difference <= 3) {
      return 'next';
    }
    return 'external';
  };

  const loadEvents = () => {
    const res = schedules.map((schedule) => {
      return {
        id: schedule.id,
        title: schedule.reason,
        start: schedule.deadline,
        backgroundColor: getBackgroundColor(schedule.deadline, schedule.was_seen),
      };
    });
    setData(res);
  };

  const loadFluxes = async () => {
    const res = await ScheduleService.getAllReason();
    setReasons(res.reasons);
  };

  const submit = () => {
    const queryBox = `&${getKeyQuery('box', box)}`;
    const reasonsFilter = `&q[reason_in][]=${reasonsSelected}`;
    const queryFilter = `${query}${queryBox}${reasonsFilter}`;

    setFilters(`${queryBox}${reasonsFilter}`);
    loadSchedules(queryFilter);
  };

  useEffect(() => {
    loadEvents();
    loadFluxes();
  }, [schedules]);

  const renderEventContent = (eventInfo: any) => {
    return (<Item item={eventInfo} />);
  };

  const getScheduleFromId = async (e: any) => {
    setLoading(true);
    const id = e.el.fcSeg.eventRange.def.publicId;
    const findFromId = schedules.find((s: ProcedureDeadline) => String(s.id) === id);
    if (findFromId) {
      const dateFormated = moment(findFromId.deadline).format('DD/MM/YYYY');
      const filterDate = schedules.filter((s) => moment(s.deadline).format('DD/MM/YYYY') === dateFormated);
      const ids = filterDate.map((i) => i.id);
      const res = await ScheduleService.getAll(`q[id_in][]=${ids}`, false);

      setDateSelected(dateFormated);
      setDeadlines(res.schedules);
      setDeadline(findFromId);
      setLoading(false);
    }
  };

  const renderLoading = () => {
    return (
      <Grid item xs={6} sm={6} md={3}>
        <div className={styles.icon}>
          <Player
            loop={true}
            autoplay
            keepLastFrame={true}
            src={LOADING_ANIMATION}
            speed={2}
            className={styles.lottie}
          >
            <Controls visible={false} />
          </Player>
        </div>
      </Grid>
    );
  };

  return (
    <Grid
      container
      columns={{ xs: 1, sm: 12, md: 12 }}
      spacing={2}
      className={ styles.container }
    >
      <Grid item xs={12} className={ styles.title }>
        <Grid item xs={3} sm={6}>
          <h1>{ t('schedule.title') }</h1>
          <Breadcumb pathnames={pathname.split('/').filter((x) => x)}/>
        </Grid>
      </Grid>
      <Grid
        container
        columns={{ xs: 2, sm: 12, md: 12 }}
        spacing={2}
        className={styles.bodyCalendar}
      >
        <Grid item xs={9} sm={9} md={12}>
          <form onSubmit={handleSubmit(submit)}>
            <Grid item xs={3} sm={3} md={3}>
              <EitherFilter
                id={'box'}
                title={t(`${i18nkey}.title`)}
                itens={itens}
                onClick={(value) => setBox(value)}
                defaultValue={'all'}
              />
            </Grid>
            <Grid item xs={3} sm={3} md={3}>
              <SelectFilter
                label={t(`${i18nkey}.reason.title`)}
                options={reasons}
                handleSelectedItems={(i) => setReasonsSelected(i)}
                placeholder={t(`${i18nkey}.reason.placeholder`)}
              />
            </Grid>
            <Grid item xs={3} sm={3} md={3} className={styles.button}>
              <Button
                type='submit'
                title={t(`${i18nkey}.search`)}
                buttonType='primary'
                round
              />
            </Grid>
          </form>
        </Grid>
        <Grid item xs={6} sm={6} md={9}>
          <FullCalendar
            ref={calendarRef}
            themeSystem='bootstrap5'
            headerToolbar={{
              left: 'prev next today',
              center: 'title',
              right: 'dayGridMonth listYear',
            }}
            eventColor='red'
            plugins={[dayGridPlugin, listPlugin]}
            locale={ptLocale}
            buttonText={{
              dayGridMonth: 'Mensal',
              listYear: 'Anual',
            }}
            listDayFormat={{ year: undefined, day: 'numeric', month: 'long' }}
            listDaySideFormat={false}
            initialView='dayGridMonth'
            eventClick={(e) => getScheduleFromId(e)}
            events={data}
            eventContent={renderEventContent}
            datesSet={() => setNewQuery()}
            dayMaxEvents={3}
          />
        </Grid>
        { loading
          ? renderLoading()
          : <Show if={deadlines.length > 0}>
              <Grid item xs={6} sm={6} md={3}>
                <ShowDeadline
                  data={deadlines}
                  dateSelected={dateSelected}
                  deadline={deadline}
                />
              </Grid>
            </Show>
        }
      </Grid>
    </Grid>
  );
}
