import {
  createContext,
  FC,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { t } from 'i18next';
import { BackgroundActionsContextType, Batch, BatchStatus } from '../@types/backgroundActions';
import { AttachedDocument, AttachedDocumentResquet } from '../@types/config';
import { Procedure } from '../@types/model';
import ProcedureBoxService from '../services/procedureBoxService';
import { ProcedureActionsContext } from './procedureActionsContext';
import { QueryContext } from './queryContext';
import { buildMultipleActionsList } from '../helpers/multipleActions';
import { DistributeObject, ProgressStatus } from '../@types/digitalProcesses/fluxes';
import { ProcedureDetailsContext } from './procedureDetailsContextProvider';
import FormHelper from '../helpers/form';
import { inactivatePjeUser } from '../helpers/pje';

const contextDefaultValues: BackgroundActionsContextType = {
  batches: [],
  addBatch: () => {},
  removeBatch: () => {},
  procedureOnProgress: () => false,
};

export const BackgroundActionsContext = createContext<BackgroundActionsContextType>(
  contextDefaultValues,
);

const BackgroundActionsProvider: FC = ({ children }) => {
  const [batches, setBatches] = useState<Batch[]>([]);
  const { removeProcedureFromList, loadData, setLoadingDetails } = useContext(QueryContext);
  const {
    removeAllProcedures, openCreateSubProcess, setSelectedSubProcess,
    setShowIconSubProcess, handleMultipleActions, addProcedures,
    openCreateDocument, openAttachFile, addToasts, openTramit,
  } = useContext(ProcedureActionsContext);
  const { loadDataTabs } = useContext(QueryContext);
  const batchesRef = useRef(batches);
  const { pathname } = useLocation();
  const isShowProcedure = pathname.includes('/procedure_box/show_procedure');
  const navigate = useNavigate();
  const { handleLoadTimeline } = useContext(ProcedureDetailsContext);
  batchesRef.current = batches;

  const removeBatch = (batch: Batch) => {
    if (batch.status === 'finished' || batch.status === 'waitingClose') {
      setBatches(batchesRef.current.filter((el) => el.id !== batch.id));
    }
  };

  const addProcedure = (id: string, procedure: any, error?: boolean, redirect?: boolean) => {
    const newState = batchesRef.current.map((obj) => {
      if (obj.id === id && (!obj.successList.find((e) => e === procedure) && !obj.failedList.find((e) => e === procedure))) {
        const successList = error ? obj.successList : [...obj.successList, procedure];
        const failedList = error ? [...obj.failedList, procedure] : obj.failedList;
        const redirectToProcedureBox = redirect;
        let status: BatchStatus;
        if (successList.length + failedList.length === obj.count) {
          status = 'finished';
        }
        if (!error && (obj.type === 'tramit' || obj.type === 'archive' || obj.type === 'forceTramit' || obj.type === 'unarchive')) {
          removeProcedureFromList(procedure);
        }

        return {
          ...obj,
          successList,
          failedList,
          status,
          redirectToProcedureBox,
        };
      }
      return obj;
    });
    setBatches(newState);
  };

  const setBatchStatus = (batch: Batch, status: BatchStatus) => {
    const newState = batchesRef.current.map((obj) => {
      if (obj.id === batch.id) {
        return {
          ...obj, status,
        };
      }
      return obj;
    });
    setBatches(newState);
  };

  const alertReloadPage = (value: boolean | null) => {
    window.onbeforeunload = () => value;
  };

  const checkLink = async (url: string, batch: Batch, doc: any) => {
    ProcedureBoxService.checkDocumentUrl(url)
      .then((res) => {
        if (res.exist === true) {
          addProcedure(batch.id, doc);
        } else {
          setTimeout(() => {
            checkLink(url, batch, doc);
          }, 4000);
        }
      });
  };

  const finishProgress = (batch: Batch) => {
    batch.body.documentsToRequest.procedures.map((procedure: any) => {
      ProcedureBoxService.finishSignerProgress(procedure);
    });
  };

  const finishProgressCosigner = (batch: Batch) => {
    batch.body.documentsToRequest.procedures.map((procedure: any) => {
      ProcedureBoxService.finishSignerProgress(procedure);
    });
  };

  const checkSignedFiles = (batch: Batch) => {
    for (let index = 0; index < batch.body.documents.length; index += 1) {
      const document = (batch.body.documents[index]);
      if (!batch.successList.find((el) => el.id === document.id) && !batch.failedList.find((el) => el.id === document.id)) {
        if (!batch.successList.includes(batch.body.documents[index])) {
          const url = batch.body.documents[index].options.data.url_signed_file;
          checkLink(url, batch, batch.body.documents[index]);
        }
      }
    }
  };

  const handleWaitingRedirectModal = (batch: Batch) => {
    if (batch.type === 'prepareDocument' || batch.type === 'attachFiles') {
      setShowIconSubProcess(true);
      setSelectedSubProcess('documents');
      openCreateSubProcess();
    }
  };

  const handleArchiveProcesses = async (batch: Batch) => {
    for (let index = 0; index < batch.procedures.length; index += 1) {
      const procedure = (batch.procedures[index]);
      if (!batch.successList.find((el) => el.id === procedure.id) && !batch.failedList.find((el) => el.id === procedure.id)) {
        ProcedureBoxService.archiveProcedures(
          procedure.id,
          batch.body.reasonsValue,
          batch.body.description,
        ).then(() => {
          addProcedure(batch.id, procedure);
        })
          .catch(() => {
            addProcedure(batch.id, procedure, true);
          });
        index = batch.procedures.length;
      }
    }
    if (isShowProcedure) {
      navigate('/procedure_box');
    }
  };

  const handleCreateSubProcess = async (batch: Batch) => {
    for (let index = 0; index < batch.procedures.length; index += 1) {
      const procedure = (batch.procedures[index]);
      if (!batch.successList.find((el) => el.id === procedure.id) && !batch.failedList.find((el) => el.id === procedure.id)) {
        ProcedureBoxService.createSubProcedure(
          batch.body.procedureId,
          batch.body.copy_interested_parts,
          batch.body.deadline,
        ).then((res) => {
          addProcedure(batch.id, procedure);
          if (batch.body.nextStep === 'prepareDocument') {
            addProcedures([res]);
            openCreateDocument(undefined, undefined, undefined, true);
          }
          if (batch.body.nextStep === 'attachFiles') {
            addProcedures([res]);
            openAttachFile(undefined, undefined, undefined, undefined, true);
          }
          addToasts({
            type: 'success',
            text: FormHelper.customToastMessage(res.id),
          });
        })
          .catch(() => {
            addProcedure(batch.id, procedure, true);
          });
        index = batch.procedures.length;
      }
    }
  };

  const handleTramit = (batch: Batch) => {
    for (let index = 0; index < batch.procedures.length; index += 1) {
      const procedure = (batch.procedures[index]);
      if (!batch.successList.find((el) => el.id === procedure.id) && !batch.failedList.find((el) => el.id === procedure.id)) {
        ProcedureBoxService.tramitProcedure(procedure.id, batch.body)
          .then(() => {
            removeProcedureFromList(procedure);
            addProcedure(batch.id, procedure);
          })
          .catch(() => {
            addProcedure(batch.id, procedure, true);
          });
        index = batch.procedures.length;
      }
    }
  };

  const handleShare = (batch: Batch) => {
    for (let index = 0; index < batch.procedures.length; index += 1) {
      const procedure = (batch.procedures[index]);
      if (!batch.successList.find((el) => el.id === procedure.id) && !batch.failedList.find((el) => el.id === procedure.id)) {
        ProcedureBoxService.shareProcedure(procedure.id, batch.body.individuals, batch.body.observation)
          .then(() => {
            addProcedure(batch.id, procedure);
          })
          .catch(() => {
            addProcedure(batch.id, procedure, true);
          });
        index = batch.procedures.length;
      }
    }
  };

  const handleSigner = (batch: Batch) => {
    if (batch.successList.length === 0 && batch.failedList.length === 0) {
      window.postMessage(batch.body.data, '*');
      checkSignedFiles(batch);
    }
    handleLoadTimeline();
  };

  const handleCosigner = (batch: Batch) => {
    if (batch.successList.length === 0 && batch.failedList.length === 0) {
      window.postMessage(batch.body.data, '*');
      checkSignedFiles(batch);
    }
    handleLoadTimeline();
  };

  const handleDelete = (batch: Batch) => {
    if (batch.body.documents) {
      for (let index = 0; index < batch.body.documents.length; index += 1) {
        const document = (batch.body.documents[index]);
        if (!batch.successList.find((el) => el.file_id === document.file_id) && !batch.failedList.find((el) => el.file_id === document.file_id)) {
          if (batch.procedures.length > 1) {
            let proceduresIds = '';
            batch.procedures.map((procedure) => {
              const prefix = 'procedure_ids';
              proceduresIds = proceduresIds.concat(`&${prefix}[]=${procedure.id}`);
            });
            ProcedureBoxService.deleteDocumentInBulk(proceduresIds, document)
              .then(() => {
                addProcedure(batch.id, document);
              })
              .catch(() => {
                addProcedure(batch.id, document, true);
              });
          } else {
            ProcedureBoxService.deleteDocument(batch.procedures[0].id, document)
              .then(() => {
                addProcedure(batch.id, document);
              })
              .catch(() => {
                addProcedure(batch.id, document, true);
              });
          }
          index = batch.body.documents.length;
        }
      }
      handleLoadTimeline();
    }
  };

  const handleAttachFiles = (batch: Batch) => {
    for (let index = 0; index < batch.procedures.length; index += 1) {
      const procedure = (batch.procedures[index]);
      if (!batch.successList.find((el) => el.id === procedure.id) && !batch.failedList.find((el) => el.id === procedure.id)) {
        const params: AttachedDocumentResquet = {
          id: procedure.id,
          in_bulk: batch.body.in_bulk,
          attach_documents: batch.body.files,
        };
        ProcedureBoxService.attachFiles(params)
          .then((res) => {
            addProcedure(batch.id, procedure);
            if (batch.body.tramit) {
              addProcedures([procedure]);
              openTramit(undefined);
            }
          })
          .catch(() => addProcedure(batch.id, procedure, true));
        index = batch.procedures.length;
      }
    }
    handleLoadTimeline();
  };

  const handleAttachExtract = (batch: Batch) => {
    for (let index = 0; index < batch.procedures.length; index += 1) {
      const procedure = (batch.procedures[index]);
      if (!batch.successList.find((el) => el.id === procedure.id) && !batch.failedList.find((el) => el.id === procedure.id)) {
        ProcedureBoxService.postAttachExtract(procedure.id)
          .then((res) => {
            addProcedure(batch.id, procedure);
          })
          .catch(() => addProcedure(batch.id, procedure, true));
        index = batch.procedures.length;
      }
    }
  };

  const handleChangeFlux = (batch: Batch) => {
    for (let index = 0; index < batch.procedures.length; index += 1) {
      const procedure = (batch.procedures[index]);
      if (!batch.successList.find((el) => el.id === procedure.id) && !batch.failedList.find((el) => el.id === procedure.id)) {
        ProcedureBoxService.requestChangeFlux(procedure.id, batch.body.flux.value, batch.body.observation)
          .then((res) => {
            addProcedure(batch.id, procedure);
          })
          .catch(() => addProcedure(batch.id, procedure, true));
        index = batch.procedures.length;
      }
    }
  };

  const handleJoinDocuments = (batch: Batch) => {
    for (let index = 0; index < batch.procedures.length; index += 1) {
      const procedure = (batch.procedures[index]);
      if (!batch.successList.find((el) => el.id === procedure.id) && !batch.failedList.find((el) => el.id === procedure.id)) {
        const params: AttachedDocumentResquet = {
          id: procedure.id,
          in_bulk: batch.body.in_bulk,
          attach_documents: batch.body.files,
        };
        ProcedureBoxService.joinDocuments(params)
          .then((res) => {
            addProcedure(batch.id, procedure);
          })
          .catch(() => addProcedure(batch.id, procedure, true));
        index = batch.procedures.length;
      }
    }
  };

  const handleForceTramit = async (batch: Batch) => {
    for (let index = 0; index < batch.procedures.length; index += 1) {
      const procedure = (batch.procedures[index]);
      if (!batch.successList.find((el) => el.id === procedure.id) && !batch.failedList.find((el) => el.id === procedure.id)) {
        ProcedureBoxService.forceTramit(
          procedure.id,
          batch.body.reasonValue,
        ).then((res) => {
          addProcedure(batch.id, procedure);
          ProcedureBoxService.wasSeenUpdate(String(procedure.id), false);
        })
          .catch(() => {
            addProcedure(batch.id, procedure, true);
          });
        index = batch.procedures.length;
      }
    }
  };

  const handleAttachProcedure = async (batch: Batch) => {
    for (let index = 0; index < batch.procedures.length; index += 1) {
      const procedure = (batch.procedures[index]);
      if (!batch.successList.find((el) => el.id === procedure.id) && !batch.failedList.find((el) => el.id === procedure.id)) {
        ProcedureBoxService.postAttachProcedure(batch.body.main_procedure, procedure.id)
          .then((res) => {
            addProcedure(batch.id, procedure);
          })
          .catch(() => addProcedure(batch.id, procedure, true));
        index = batch.procedures.length;
      }
    }
  };

  const handleUnattachProcedure = async (batch: Batch) => {
    for (let index = 0; index < batch.procedures.length; index += 1) {
      const procedure = (batch.procedures[index]);
      if (!batch.successList.find((el) => el.id === procedure.id) && !batch.failedList.find((el) => el.id === procedure.id)) {
        ProcedureBoxService.deleteAttachProcedure(procedure.main_subprocedures_numbers.id)
          .then((res) => {
            addProcedure(batch.id, procedure);
          })
          .catch(() => addProcedure(batch.id, procedure, true));
        index = batch.procedures.length;
      }
    }
  };

  const handleUnarchive = async (batch: Batch) => {
    for (let index = 0; index < batch.procedures.length; index += 1) {
      const procedure = (batch.procedures[index]);
      if (!batch.successList.find((el) => el.id === procedure.id) && !batch.failedList.find((el) => el.id === procedure.id)) {
        ProcedureBoxService.postUnarchive(
          procedure.id,
          batch.body.observation,
        ).then((res) => {
          addProcedure(batch.id, procedure);
        })
          .catch(() => {
            addProcedure(batch.id, procedure, true);
          });
        index = batch.procedures.length;
      }
    }
  };

  const handlePrepareDocument = async (batch: Batch) => {
    for (let index = 0; index < batch.procedures.length; index += 1) {
      const procedure = (batch.procedures[index]);
      if (!batch.successList.find((el) => el.id === procedure.id) && !batch.failedList.find((el) => el.id === procedure.id)) {
        const content = batch.body.in_bulk ? batch.body.contentInBulk.find((x: any) => x.id === procedure.id).value : batch.body.content;
        ProcedureBoxService.prepareDocument(
          batch.body.title,
          content,
          batch.body.documentType,
          [procedure.id],
          batch.body.in_bulk,
        ).then((res) => {
          addProcedure(batch.id, procedure);
        })
          .catch(() => {
            addProcedure(batch.id, procedure, true);
          });
        index = batch.procedures.length;
      }
    }
    handleLoadTimeline();
  };

  const handleFowardToIPM = async (batch: Batch) => {
    for (let index = 0; index < batch.procedures.length; index += 1) {
      const procedure = (batch.procedures[index]);

      if (!batch.successList.find((el) => el.id === procedure.id) && !batch.failedList.find((el) => el.id === procedure.id)) {
        const obj = batch.body.data.find((elem: any) => elem.procedure_id === procedure.id);

        ProcedureBoxService.fowardToIpm(
          obj.procedure_id,
          obj.description,
          obj.archives,
        ).then((res) => {
          if (res.data.success) {
            addProcedure(batch.id, procedure);
          } else {
            addProcedure(batch.id, procedure, true);
          }
        })
          .catch(() => {
            addProcedure(batch.id, procedure, true);
          });
        index = batch.procedures.length;
      }
    }
    handleLoadTimeline();
  };

  const handleSendInitialPetition = async (batch: Batch) => {
    for (let index = 0; index < batch.procedures.length; index += 1) {
      const procedure = (batch.procedures[index]);
      if (!batch.successList.find((el) => el.id === procedure.id) && !batch.failedList.find((el) => el.id === procedure.id)) {
        const procedureToPetition = batch.body.proceduresToPetition.find((proc: any) => proc.procedure_id === procedure.id);
        ProcedureBoxService.sendInitialPetition(
          batch.body.password,
          procedureToPetition.procedure_id,
          procedureToPetition.attach_document_ids,
        ).then((res) => {
          if (res.status === 200) {
            addProcedure(batch.id, procedure);
          } else {
            addProcedure(batch.id, procedure, true);
            if (res.status === 401) {
              inactivatePjeUser();
            }

            addToasts({ type: 'error', text: res.message });
          }
        })
          .catch(() => {
            addProcedure(batch.id, procedure, true);
          });
        index = batch.procedures.length;
      }
    }
  };

  const handleSendIntermediatePetition = async (batch: Batch) => {
    for (let index = 0; index < batch.procedures.length; index += 1) {
      const procedure = (batch.procedures[index]);
      if (!batch.successList.find((el) => el.id === procedure.id) && !batch.failedList.find((el) => el.id === procedure.id)) {
        const procedureToPetition = batch.body.proceduresToPetition.find((proc: any) => proc.procedure_id === procedure.id);
        ProcedureBoxService.sendIntermediatePetitionData(
          batch.body.password,
          procedureToPetition.procedure_id,
          procedureToPetition.attach_document_ids,
        ).then((res) => {
          if (res.status === 200) {
            addProcedure(batch.id, procedure);
          } else {
            addProcedure(batch.id, procedure, true);
            if (res.status === 401) {
              inactivatePjeUser();
            }

            addToasts({ type: 'error', text: res.message });
          }
        })
          .catch(() => {
            addProcedure(batch.id, procedure, true);
          });
        index = batch.procedures.length;
      }
    }
  };

  const handleSetSendSpuDocument = async (documents: AttachedDocument[]): Promise<number> => {
    const docSuccess: number[] = [];
    const docError: number[] = [];
    for (let index = 0; index < documents.length; index += 1) {
      const doc = documents[index];
      // eslint-disable-next-line no-await-in-loop
      const response : Boolean = await ProcedureBoxService.setSendToSpu(doc.file_id, doc.file_class);
      if (response) {
        docSuccess.push(doc.file_id);
      } else {
        docError.push(doc.file_id);
      }
    }
    return docSuccess.length;
  };

  const handleSendSpu = async (batch: Batch) => {
    let index = 0;
    const procedure = (batch.procedures[index]);
    if (!batch.successList.find((el) => el.id === procedure.id) && !batch.failedList.find((el) => el.id === procedure.id)) {
      const documents = batch.body.documentSelected;
      const spuObject = batch.body.spu;

      let response;

      if (batch.body.registrationSpu) {
        response = await ProcedureBoxService.postRegistrationSpu(spuObject);
        if (response.status === 201 || response.status === 200) {
          addProcedure(batch.id, procedure);
        } else {
          addProcedure(batch.id, procedure, true);
        }
      } else {
        const setResult = await handleSetSendSpuDocument(documents);
        if (setResult >= documents.length) {
          try {
            response = await ProcedureBoxService.sendToSpu(spuObject);
            if (response.status === 201 || response.status === 200) {
              addProcedure(batch.id, procedure);
            } else {
              addToasts({
                type: 'error',
                isCloseToast: false,
                text: t('procedureBox.actions.spu.error'),
              });
              addProcedure(batch.id, procedure, true);
            }
          } catch (error: any) {
            const message = error?.data?.messageError;
            addToasts({
              type: 'error',
              isCloseToast: false,
              text: message.length > 3 ? message : t('procedureBox.actions.spu.error'),
            });
            addProcedure(batch.id, procedure, true);
          }
        } else {
          addProcedure(batch.id, procedure, true);
        }
        index = batch.procedures.length;
      }
    }
  };

  const handleSendOrderPayment = async (batch: Batch) => {
    for (let index = 0; index < batch.procedures.length; index += 1) {
      const procedure = (batch.procedures[index]);
      if (!batch.successList.find((el) => el.id === procedure.id) && !batch.failedList.find((el) => el.id === procedure.id)) {
        ProcedureBoxService.sendPaymentOrders(
          batch.body.paymentOrder.procedureId,
          batch.body.paymentOrder.deadline,
          batch.body.paymentOrder.paymentOrderData,
        ).then((res: any) => {
          ProcedureBoxService.getProcedure(res.data.procedure_id).then((r) => {
            if (batch.body.nextStep === 'prepareDocument') {
              addProcedures([r]);
              openCreateDocument(undefined, undefined, undefined, true);
            }
            if (batch.body.nextStep === 'attachFiles') {
              addProcedures([r]);
              openAttachFile(undefined, undefined, undefined, undefined, true);
            }
            addToasts({
              type: 'success',
              text: FormHelper.customToastMessage(res.data.procedure_id),
            });
          });
          addProcedure(batch.id, procedure);
          loadDataTabs();
        })
          .catch(() => {
            addProcedure(batch.id, procedure, true);
          });

        index = batch.procedures.length;
      }
    }
  };

  const handleRequestCalculate = (batch: Batch) => {
    for (let index = 0; index < batch.procedures.length; index += 1) {
      const procedure = (batch.procedures[index]);
      if (!batch.successList.find((el) => el.id === procedure.id) && !batch.failedList.find((el) => el.id === procedure.id)) {
        ProcedureBoxService.sendPaymentRequests(
          batch.body.procedure_id,
          batch.body.requestCalculations,
        ).then((res) => {
          addProcedure(batch.id, procedure);
          loadDataTabs();
          addToasts({
            type: 'success',
            text: FormHelper.customToastMessage(res.data.procedure_id),
          });
          const { attachAndTramit, subProcedure } = batch.body;

          if (attachAndTramit) {
            addProcedures([subProcedure]);
            openAttachFile(undefined, undefined, undefined, true);
          }
        })
          .catch(() => {
            addProcedure(batch.id, procedure, true);
          });

        index = batch.procedures.length;
      }
    }
  };

  const handleManualDistribution = async (batch: Batch) => {
    for (let index = 0; index < batch.count; index += 1) {
      const procedure = batch.procedures.filter((proc) => proc.id === batch.body[index].procedure_id)[0];
      if (!batch.successList.find((el) => el.id === procedure.id) && !batch.failedList.find((el) => el.id === procedure.id)) {
        ProcedureBoxService.createManualDistribution(procedure.id, batch.body[index].process_distribution)
          .then(() => {
            addProcedure(batch.id, procedure, false, true);
          })
          .catch(() => {
            addProcedure(batch.id, procedure, true);
          });

        index = batch.procedures.length;
      }
    }
  };

  const handleAutomaticDistribuition = async (batch: Batch) => {
    const distributieQuantity = batch.body;
    distributieQuantity.map((distribute: DistributeObject) => {
      const procedure = batch.procedures.filter((proc) => proc.id === distribute.procedure_ids)[0];
      if (!batch.successList.find((el) => el.id === procedure.id) && !batch.failedList.find((el) => el.id === procedure.id)) {
        ProcedureBoxService.createAutomaticDistribution(distribute)
          .then((response) => {
            const checkStatusInterval = setInterval(() => {
              ProcedureBoxService.automaticDistribuitionRedisStatus(response.key)
                .then((status: ProgressStatus) => {
                  if (status && status.progress.finished_process && status.progress.current === status.progress.total_succes) {
                    clearInterval(checkStatusInterval);
                    batch.procedures.map((procedureId) => {
                      return addProcedure(batch.id, procedureId, false, true);
                    });
                  } else if (status.progress.current !== status.progress.total_succes) {
                    clearInterval(checkStatusInterval);
                    const procedureFailed = status.progress.procedures_errors;
                    procedureFailed.map((procedureId) => {
                      const processList = batch.procedures.filter((process) => process.id === procedureId);
                      return addProcedure(batch.id, processList, true, false);
                    });
                  }
                });
            }, 1000);
            setTimeout(() => {
              clearInterval(checkStatusInterval);
            }, 5000);
          })
          .catch(() => {
            addProcedure(batch.id, procedure, true);
          });
      }
    });
  };

  useEffect(() => {
    batches.map(async (batch) => {
      if (batch.status === undefined) {
        alertReloadPage(true);
        if (batch.type === 'tramit') {
          handleTramit(batch);
        } else if (batch.type === 'archive') {
          handleArchiveProcesses(batch);
        } else if (batch.type === 'signer') {
          handleSigner(batch);
        } else if (batch.type === 'cosigner') {
          handleCosigner(batch);
        } else if (batch.type === 'removeDocument') {
          handleDelete(batch);
        } else if (batch.type === 'attachFiles') {
          handleAttachFiles(batch);
        } else if (batch.type === 'attachExtract') {
          handleAttachExtract(batch);
        } else if (batch.type === 'changeFlux') {
          handleChangeFlux(batch);
        } else if (batch.type === 'joinDocuments') {
          handleJoinDocuments(batch);
        } else if (batch.type === 'forceTramit') {
          handleForceTramit(batch);
        } else if (batch.type === 'unarchive') {
          handleUnarchive(batch);
        } else if (batch.type === 'attachProcedure') {
          handleAttachProcedure(batch);
        } else if (batch.type === 'unattachProcedure') {
          handleUnattachProcedure(batch);
        } else if (batch.type === 'prepareDocument') {
          handlePrepareDocument(batch);
        } else if (batch.type === 'forwardIPM') {
          handleFowardToIPM(batch);
        } else if (batch.type === 'spu') {
          handleSendSpu(batch);
        } else if (batch.type === 'registrationSpu') {
          handleSendSpu(batch);
        } else if (batch.type === 'subProcedure') {
          handleCreateSubProcess(batch);
        } else if (batch.type === 'initialPetition') {
          handleSendInitialPetition(batch);
        } else if (batch.type === 'intermediatePetition') {
          handleSendIntermediatePetition(batch);
        } else if (batch.type === 'eletronicCalculation') {
          handleRequestCalculate(batch);
        } else if (batch.type === 'paymentOrder') {
          handleSendOrderPayment(batch);
        } else if (batch.type === 'distributionManual') {
          handleManualDistribution(batch);
        } else if (batch.type === 'distributionAutomatic') {
          handleAutomaticDistribuition(batch);
        } else if (batch.type === 'shareProcedure') {
          handleShare(batch);
        } else if (batch.status === 'finished') {
          setBatchStatus(batch, 'waitingClose');
          alertReloadPage(null);
        }
      }

      if (batches[0].failedList.length === 0 && batches[0].status === 'finished') {
        const seconds = ['spu', 'registrationSpu'].includes(batches[0].type) ? 1000 : (batches[0].body.waiting ? 5000 : 10000);

        if (batch.multipleActions) {
          const nextStep = batch.multipleActions.data.find((i) => i.name === batch.multipleActions?.currentStep)?.nextStep;
          if (nextStep) {
            const data = buildMultipleActionsList(batch.multipleActions, batch.multipleActions.currentStep);
            handleMultipleActions(data, nextStep);
            removeBatch(batch);
          }
        }

        if (batch.type === 'signer') {
          for (let index = 0; index <= batches.length; index += 1) {
            finishProgress(batches[index]);
          }
        }

        if (batch.type === 'cosigner') {
          for (let index = 0; index <= batches.length; index += 1) {
            finishProgressCosigner(batches[index]);
          }
        }

        if (batch.type === 'tramit' && isShowProcedure) {
          navigate('/procedure_box');
        }

        if (batch.redirectToProcedureBox) {
          navigate('/procedure_box');
        }

        setTimeout(() => {
          if (batches[0].body.waiting) {
            handleWaitingRedirectModal(batches[0]);
          }

          setBatchStatus(batches[0], 'closed');
          if (batches[0].type === 'subProcedure') {
            loadDataTabs();
          }
        }, seconds);

        if (isShowProcedure && batch.type === 'forceTramit') {
          setLoadingDetails(true);
        }

        setTimeout(() => {
          const nextStep = batch.multipleActions?.data.find((i) => i.name === batch.multipleActions?.currentStep)?.nextStep;
          if (!nextStep && !isShowProcedure) {
            loadDataTabs();
            loadData();
          } else if (!nextStep && isShowProcedure) {
            if (!batches[0].body.attachAfter) {
              setLoadingDetails(true);
            }
          }
        }, 2500);
      }
    });
  }, [batches]);

  const addBatch = async (batch: Batch) => {
    if (batch.multipleActions) {
      const nextStep = batch.multipleActions.data.find((i) => i.name === batch.multipleActions?.currentStep)?.nextStep;
      if (!nextStep) {
        removeAllProcedures();
      }
    } else {
      removeAllProcedures();
    }
    await batchesRef.current.map((b) => {
      if (batch.procedures.some((el) => b.failedList.includes(el))) {
        if (b.status === 'finished' || b.status === 'waitingClose') {
          setBatchStatus(b, 'closed');
        }
      }
    });
    setBatches([batch, ...batchesRef.current]);
  };

  const procedureOnProgress = (procedure: Procedure) => {
    for (let index = 0; index < batches.length; index += 1) {
      if (batches[index].procedures.includes(procedure) && !batches[index].successList.includes(procedure) && !batches[index].failedList.includes(procedure)) {
        return true;
      }
      index += 1;
    }
    return false;
  };

  return (
    <BackgroundActionsContext.Provider
      value={{
        batches,
        addBatch,
        removeBatch,
        procedureOnProgress,
      }}>
        {children}
    </BackgroundActionsContext.Provider>
  );
};

export default BackgroundActionsProvider;
