import { Skeleton } from '@mui/material';
import { t } from 'i18next';
import { useContext, useEffect, useState } from 'react';
import {
  CartesianGrid, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis, Text,
} from 'recharts';
import { FaDownload } from 'react-icons/fa';
import Format from '../../helpers/format';
import { Menu } from '../Charts/Menu';
import { DashboardDigitalProcessesContext } from '../../context/dashboardDigitalProcessesContext';
import styles from './styles.module.scss';
import { getMenuItems, getYearsOptions } from '../../helpers/dashboardMenu';
import { Tabs } from '../Charts/Tabs';
import DashboardService from '../../services/dashboardService';
import Select from '../Select';
import Show from '../Show';
import AuthContext from '../../context/authContext';
import { CurrentUser } from '../../@types/currentUser';
import { PerformanceResponse } from '../../@types/digitalProcessesDashboard';
import { Modal } from '../Modal';
import Toast from './Toast';
import { DateTimePicker } from '../DateTimePicker';
import { Button } from '../Button';
import { Alert } from '../Alert';

export function PeformanceChart() {
  const [tabSelected, setTabSelected] = useState('peformance');
  const [showDateRangePicker, setShowDateRangePicker] = useState<boolean>(false);
  const [stepsChart, setStepsChart] = useState<PerformanceResponse>();
  const [steps, setSteps] = useState([]);
  const [stepSelected, setStepSelected] = useState();
  const [yearToStepSelected, setYearToStepSelected] = useState();
  const [loadingStepChart, setLoadingStepChart] = useState(false);
  const {
    loading, setYear, performance, query,
  } = useContext(DashboardDigitalProcessesContext);
  const { user } = useContext(AuthContext);
  const currentUser: CurrentUser = JSON.parse(localStorage.getItem('currentUser')!);
  const baseYear = new Date().getFullYear();
  const [open, setOpen] = useState<boolean>(false);
  const [beginDate, setBeginDate] = useState<string>('');
  const [endDate, setEndDate] = useState<string>('');
  const [loadingXls, setLoadingXls] = useState(false);

  const TABS = [
    {
      key: 'peformance',
      label: t('dashboard.digitalProcesses.performance.title'),
      onClick: () => setTabSelected('peformance'),
      tooltip: t('dashboard.digitalProcesses.performance.tooltip'),
    },
    {
      key: 'steps',
      label: t('dashboard.digitalProcesses.steps.title'),
      onClick: () => setTabSelected('steps'),
      tooltip: t('dashboard.digitalProcesses.steps.tooltip'),
    },
  ];

  const querySql = () => {
    let newQuery = '';
    if (query.length > 0 && query !== 'allFields') {
      newQuery += query;
    } else if (query.length > 0 && query === 'allFields') {
      newQuery += '';
    } else if (user?.id === undefined) {
      newQuery += `?q[individual_id_eq]=${currentUser?.id}`;
    } else {
      newQuery += `?q[individual_id_eq]=${user?.id}`;
    }

    return newQuery;
  };

  useEffect(() => {
    if (stepSelected) {
      setLoadingStepChart(true);
      const queryYear = `year=${yearToStepSelected || getYearsOptions(7, baseYear)[0].value}`;
      let newQuery = querySql();
      newQuery += newQuery.length > 0 ? `&q[step_id_in]=${stepSelected}` : `?q[step_id_in]=${stepSelected}`;
      newQuery += `&${queryYear}`;

      DashboardService.getStepChart(newQuery).then((res) => {
        setLoadingStepChart(false);
        setStepsChart(res);
      });
    }
  }, [stepSelected, yearToStepSelected, query]);

  useEffect(() => {
    if (tabSelected === 'steps') {
      DashboardService.getStepChartDependencies().then((res) => {
        const stepsToShow = res.map((step: any) => { return { label: step.product_generated, value: step.id }; });
        if (stepsToShow[0]) {
          setStepSelected(stepsToShow[0].value);
        }
        setSteps(stepsToShow);
      });
    }
  }, [tabSelected]);

  const getColor = (name: string) => {
    if (name === 'total_outbox') {
      return styles.totalOutbox;
    }
    if (name === 'total_inputbox') {
      return styles.totalInputbox;
    }
    if (name === 'total_snapshot') {
      return styles.totalSnapshot;
    }
    return styles.totalInbox;
  };

  const CustomTooltip = ({ active, payload }: any) => {
    if (active) {
      return (
        <div className={ styles.tooltip }>
          { payload.map((item: { name: any; value: any; }) => (
            <p
              className={ `${styles.period} ${getColor(item.name)}`}
            >
              {`${t(`dashboard.digitalProcesses.${tabSelected === 'steps' ? 'steps' : 'performance'}.${item.name}`)} : ${item.value}`}
            </p>
          ))}
        </div>
      );
    }
    return null;
  };

  const handleChangeStep = (val: any) => {
    setStepSelected(val);
  };

  const handleChangeYear = (val: any) => {
    setYearToStepSelected(val);
  };

  const renderStepsChart = () => {
    return (
      <>
        <div className={ styles.filters }>
          <Show if={steps.length > 0}>
            <Select
              options={steps}
              onlySelect
              label=''
              returnValue
              defaultValue={steps[0]}
              onChange={(val) => handleChangeStep(val)}
            />
          </Show>
          <Select
            options={getYearsOptions(7, baseYear)}
            onlySelect
            label=''
            defaultValue={getYearsOptions(7, baseYear)[0]}
            returnValue
            onChange={(val) => handleChangeYear(val)}
          />
          <div id='btnExportActiveDebt' className={ styles.button } onClick={() => setShowDateRangePicker(true)}>
            <FaDownload />
            <span>{t('home.dashboard.digitalProcesses.exportSpreadsheet.button')}</span>
          </div>
        </div>
        {
          !loadingStepChart && stepsChart !== undefined && stepsChart?.months.length > 0 ? (
          <>
            <div className={ styles.chart }>
              <ResponsiveContainer width={'99%'} minWidth={700} height={250} minHeight={200}>
                <LineChart
                  data={stepsChart?.months}
                  margin={{
                    top: 10,
                    right: 5,
                    left: -15,
                    bottom: 10,
                  }}>
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis
                    dataKey="month_number"
                    interval="preserveStartEnd"
                    tick={(e) => {
                      const { payload: { value } } = e;
                      e.fontSize = 10;
                      e.fontWeight = 'bold';
                      e.fill = 'var(--dark)';
                      return <Text {...e}>{t(`dashboard.prescriptions.months.${value}`).toUpperCase()}</Text>;
                    }}
                  />
                  <YAxis
                    tick={(e) => {
                      const { payload: { value } } = e;
                      e.fontSize = 10;
                      e.fill = 'var(--gray2)';
                      return <Text {...e}>{Format.formatValueName(value)}</Text>;
                    }}
                  />
                  <Tooltip content={<CustomTooltip />} />
                  <Line
                    activeDot={{ r: 6 }}
                    strokeWidth={2}
                    dataKey="total"
                    stroke="var(--primary)"
                    height={35} />
                </LineChart>
              </ResponsiveContainer>
            </div>
          </>
          )
            : <Skeleton variant={'rectangular'} className={ styles.row }/>
        }
      </>
    );
  };

  const renderPeformanceChart = () => {
    return (
      <>
        <div className={ styles.header }>
          <Menu itens={getMenuItems(7, baseYear)} onChange={(item: string) => setYear(item)} chevron/>
        </div>
        {
          !loading && performance !== undefined && performance.months.length > 0 ? (
          <>
            <div className={ styles.chart }>
              <ResponsiveContainer width={'99%'} minWidth={700} height={250} minHeight={200}>
                <LineChart
                  data={performance.months}
                  margin={{
                    top: 10,
                    right: 5,
                    left: -15,
                    bottom: 10,
                  }}>
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis
                    dataKey="month_number"
                    interval="preserveStartEnd"
                    tick={(e) => {
                      const { payload: { value } } = e;
                      e.fontSize = 10;
                      e.fontWeight = 'bold';
                      e.fill = 'var(--dark)';
                      return <Text {...e}>{t(`dashboard.prescriptions.months.${value}`).toUpperCase()}</Text>;
                    }}
                  />
                  <YAxis
                    tick={(e) => {
                      const { payload: { value } } = e;
                      e.fontSize = 10;
                      e.fill = 'var(--gray2)';
                      return <Text {...e}>{Format.formatValueName(value)}</Text>;
                    }}
                  />
                  <Tooltip content={<CustomTooltip />} />
                  <Line
                    activeDot={{ r: 6 }}
                    strokeWidth={2}
                    dataKey="total_snapshot"
                    stroke="var(--orange)"
                    height={35} />
                  <Line
                    activeDot={{ r: 6 }}
                    strokeWidth={2}
                    dataKey="total_inputbox"
                    stroke="var(--primary)"
                    height={15} />
                  <Line
                    activeDot={{ r: 6 }}
                    strokeWidth={2}
                    dataKey="total_outbox"
                    stroke="var(--success)"
                    height={25} />
                  <Line
                    activeDot={{ r: 6 }}
                    strokeWidth={2}
                    dataKey="total_inbox"
                    stroke="var(--gray6)"
                    fontSize={16} />
                </LineChart>
              </ResponsiveContainer>
            </div>
          </>
          )
            : <Skeleton variant={'rectangular'} className={ styles.row }/>
        }
      </>
    );
  };

  const handleSubmit = () => {
    setShowDateRangePicker(false);
    setLoadingXls(true);
    let newQuery = querySql();
    newQuery += newQuery.length > 0 ? '&' : '?';
    newQuery += `q[step_id_in]=${stepSelected}&start_date=${beginDate}&end_date=${endDate}`;

    DashboardService.downloadXls(newQuery)
      .then((resp) => {
        setOpen(true);
        window.open(resp.file);
        setLoadingXls(false);

        setTimeout(() => {
          setOpen(false);
        }, 3000);
      });
  };

  return (
    <div id='performanceChart' className={ styles.container }>
      <Tabs
        tabs={TABS}
        selected={tabSelected}
      />

      {tabSelected === 'peformance' ? renderPeformanceChart() : renderStepsChart()}
      <Toast
        open={open}
        stepSelected={stepSelected}
        className={styles.toast}
      />
      <Alert
        visible={loadingXls}
        title={t('home.dashboard.digitalProcesses.exportSpreadsheet.alert.title')}
        text={t('home.dashboard.digitalProcesses.exportSpreadsheet.alert.message')}
        type={'loading'}
        disabledButtons
      />
      <Modal
        title={t('home.dashboard.digitalProcesses.exportSpreadsheet.title')}
        open={showDateRangePicker}
        onClose={() => setShowDateRangePicker(false)}
        size={'small'}
      >
        <div className={styles.modalContainer}>
          <div className={styles.dateTimePicker}>
            <span>{t('home.dashboard.digitalProcesses.exportSpreadsheet.message')}</span>
            <DateTimePicker
              label={''}
              placeholder={t('procedureBox.filterAdvanced.procedureCreatedAt.placeholder')}
              tooltip={t('home.dashboard.digitalProcesses.exportSpreadsheet.tooltip')}
              disabledPopover
              onChange={(begin, end) => {
                setBeginDate(begin);
                setEndDate(end);
              }}
            />
          </div>
          <div className={styles.button}>
            <Button
              title='Exportar'
              buttonType='primary'
              onClick={() => handleSubmit()}
              size='flat'
              round
            />
          </div>
        </div>
      </Modal>
    </div>
  );
}
