const inboxMenus: { [key: string]: boolean } = {
  deadline: true,
  addOrRemoveMarker: true,
  tramit: true,
  changeFlux: true,
  attachExtract: true,
  forceTramit: false,
  prepareDocument: true,
  attachFiles: true,
  removeDocument: true,
  archive: true,
  signDocument: true,
  createSubprocess: true,
  joinDocuments: false,
  editProcedure: true,
  court: true,
  initialPetition: true,
  intermediatePetition: true,
  spu: true,
  registrationSpu: true,
  ipm: true,
  unArchive: false,
  notes: true,
  multipleActions: true,
  distributeProcess: true,
  delete: true,
  shareProcedure: true,
  attachProcedure: true,
  attachCitationIntimation: true,
};

const outboxMenus: { [key: string]: boolean } = {
  deadline: true,
  addOrRemoveMarker: true,
  tramit: false,
  changeFlux: false,
  attachExtract: false,
  forceTramit: true,
  prepareDocument: false,
  attachFiles: false,
  removeDocument: false,
  archive: false,
  signDocument: false,
  createSubprocess: true,
  joinDocuments: true,
  editProcedure: false,
  court: false,
  initialPetition: false,
  intermediatePetition: false,
  spu: false,
  registrationSpu: false,
  ipm: false,
  unArchive: false,
  notes: true,
  multipleActions: true,
  delete: false,
  shareProcedure: false,
  attachProcedure: false,
  distributeProcess: true,
  attachCitationIntimation: false,
};

const archiveboxMenus: { [key: string]: boolean } = {
  deadline: true,
  addOrRemoveMarker: true,
  tramit: true,
  changeFlux: true,
  attachExtract: true,
  forceTramit: false,
  prepareDocument: true,
  attachFiles: true,
  removeDocument: true,
  archive: false,
  signDocument: true,
  createSubprocess: true,
  joinDocuments: false,
  editProcedure: true,
  court: true,
  initialPetition: true,
  intermediatePetition: true,
  spu: true,
  registrationSpu: true,
  ipm: true,
  unArchive: true,
  notes: true,
  multipleActions: true,
  delete: false,
  shareProcedure: false,
  attachProcedure: false,
  distributeProcess: true,
  attachCitationIntimation: false,
};

const sharedBoxMenus: { [key: string]: boolean } = {
  deadline: true,
  addOrRemoveMarker: true,
  tramit: false,
  changeFlux: true,
  attachExtract: true,
  forceTramit: false,
  prepareDocument: true,
  attachFiles: true,
  removeDocument: true,
  archive: false,
  signDocument: true,
  createSubprocess: true,
  joinDocuments: false,
  editProcedure: true,
  court: true,
  initialPetition: true,
  intermediatePetition: true,
  spu: true,
  registrationSpu: true,
  ipm: true,
  unArchive: false,
  notes: true,
  multipleActions: true,
  distributeProcess: false,
  delete: true,
  shareProcedure: false,
  attachProcedure: false,
  attachCitationIntimation: false,
};

export type MenuItems = 'deadline' | 'addOrRemoveMarker' | 'tramit' | 'forceTramit'
| 'prepareDocument' | 'attachFiles' | 'removeDocument' | 'archive' | 'changeFlux'
| 'signDocument' | 'createSubprocess' | 'joinDocuments' | 'editProcedure' | 'attachExtract'
| 'court' | 'spu' | 'ipm' | 'unArchive' | 'notes' | 'multipleActions' | 'distributeProcess'
| 'delete' | 'shareProcedure' | 'registrationSpu';

export function isVisible(box: string | undefined, menu: MenuItems | string): boolean {
  const currentBox = box || 'inbox';
  if (currentBox === 'inbox') {
    return inboxMenus[menu];
  }
  if (currentBox === 'outbox' || currentBox === 'fieldbox') {
    return outboxMenus[menu];
  }
  if (currentBox === 'sharedbox') {
    return sharedBoxMenus[menu];
  }
  return archiveboxMenus[menu];
}
