import { useContext, useEffect, useState } from 'react';
import { saveAs } from 'file-saver';
import { t } from 'i18next';
import { Skeleton } from '@mui/material';
import moment from 'moment';
import { DocumentList } from '../../../components/DocumentList';
import ProcedureBoxService from '../../../services/procedureBoxService';
import styles from './styles.module.scss';
import { Procedure } from '../../../@types/model';
import Screen from '../../../helpers/screen';
import { AttachedDocument } from '../../../@types/config';
import { Button } from '../../../components/Button';
import { BackgroundActionsContext } from '../../../context/backgroundActions';
import { ProcedureActionsContext } from '../../../context/procedureActionsContext';
import { Batch } from '../../../@types/backgroundActions';
import Show from '../../../components/Show';
import { Timeline } from '../Timeline';
import Select from '../../../components/Select';
import { documentsOrder } from '../../../helpers/enums';

type DocumentsProps = {
  procedure: Procedure;
};

export function Documents(props: DocumentsProps) {
  const [loading, setLoading] = useState(false);
  const [documents, setDocuments] = useState<AttachedDocument[] | null>(null);
  const [documentsSeleted, setDocumentsSeleted] = useState<AttachedDocument[]>([]);
  const [orderBy, setOrderBy] = useState('date desc');
  const [batchId, setBatchId] = useState('');

  const { setAlert, proceduresSeleted, removeProcedures } = useContext(ProcedureActionsContext);
  const { batches, addBatch } = useContext(BackgroundActionsContext);
  const isMobile = Screen.isMobile();

  const filterRows = () => {
    const rows = documents || [];
    switch (orderBy) {
      case 'date asc':
        return rows.sort((a, b) => {
          const dateA = moment(a.created_at).valueOf();
          const dateB = moment(b.created_at).valueOf();
          return dateB - dateA;
        });
      case 'file_name asc':
        return rows.sort((a, b) => a.file_name.localeCompare(b.file_name));
      case 'file_name desc':
        return rows.sort((a, b) => b.file_name.localeCompare(a.file_name));
      case 'owner asc':
        return rows.sort((a, b) => a.individual_name.localeCompare(b.individual_name));
      case 'owner desc':
        return rows.sort((a, b) => b.individual_name.localeCompare(a.individual_name));
      case 'type asc':
        return rows.sort((a, b) => a.type.localeCompare(b.type));
      case 'type desc':
        return rows.sort((a, b) => b.type.localeCompare(a.type));
      default:
        return rows.sort((a, b) => {
          const dateA = moment(a.created_at).valueOf();
          const dateB = moment(b.created_at).valueOf();
          return dateA - dateB;
        });
    }
  };

  const renderTitle = () => {
    if (proceduresSeleted.length > 1) {
      return t(
        'procedureBox.actions.removeDocument.modal.alert.pluralTitle',
        {
          qnt_doc: documentsSeleted.length,
          plural: documentsSeleted.length > 1 ? 's' : '',
          procedure_qnt: proceduresSeleted.length,
        },
      );
    }
    return t(
      'procedureBox.actions.removeDocument.modal.alert.title',
      {
        qnt_doc: documentsSeleted.length,
        plural: documentsSeleted.length > 1 ? 's' : '',
        procedure_number: proceduresSeleted[0].process_number,
      },
    );
  };

  const renderTitleDownload = () => {
    if (proceduresSeleted.length > 1) {
      return t(
        'procedureBox.actions.downloadDocument.modal.alert.pluralTitle',
        {
          qnt_doc: documentsSeleted.length,
          plural: documentsSeleted.length > 1 ? 's' : '',
          procedure_qnt: proceduresSeleted.length,
        },
      );
    }
    return t(
      'procedureBox.actions.downloadDocument.modal.alert.title',
      {
        qnt_doc: documentsSeleted.length,
        plural: documentsSeleted.length > 1 ? 's' : '',
        procedure_number: proceduresSeleted[0].process_number,
      },
    );
  };

  const getDocuments = async () => {
    setLoading(true);
    const response = await ProcedureBoxService.getDocumentsInPreparation(props.procedure.id);
    if (response.documents) {
      setDocuments(response.documents);
    }
    setLoading(false);
  };

  const handleDelete = () => {
    const body = {
      documents: documentsSeleted,
    };
    setDocumentsSeleted([]);

    const procedures = proceduresSeleted;
    const type = 'removeDocument';
    const id = (Math.random() + 1).toString(36).substring(1);

    const batch: Batch = {
      id,
      procedures,
      type,
      count: documentsSeleted.length,
      successList: [],
      failedList: [],
      body,
    };
    addBatch(batch);
    removeProcedures(procedures);
    setBatchId(id);
    setAlert(undefined);
  };

  const handleDownload = () => {
    if (documentsSeleted.length > 1) {
      let textSend = '';
      for (let index = 0; index < documentsSeleted.length; index += 1) {
        const document = (documentsSeleted[index]);
        if ((index + 1) === documentsSeleted.length) {
          textSend = textSend.concat(`${document.file_id}|${document.file_class}`);
        } else {
          textSend = textSend.concat(`${document.file_id}|${document.file_class}, `);
        }
      }
      ProcedureBoxService.downloadDocuments(textSend).then((response) => {
        const blob = new Blob([response.data], { type: 'application/zip' });
        saveAs(blob, `processo_${proceduresSeleted[0].id}`);
      });
    } else {
      ProcedureBoxService.downloadDocument(documentsSeleted[0]).then((response) => {
        const blob = new Blob([response.data], { type: 'application/pdf' });
        saveAs(blob, `processo_${proceduresSeleted[0].id}`);
      });
    }

    setAlert(undefined);
  };

  const showAlert = () => {
    setAlert({
      visible: true,
      handleConfirm: () => handleDelete(),
      title: renderTitle(),
      text: t('procedureBox.actions.removeDocument.modal.alert.text'),
      confirmText: t('procedureBox.actions.removeDocument.modal.alert.button'),
      confirmType: 'danger',
    });
  };

  const showAlertToDownload = () => {
    setAlert({
      visible: true,
      handleConfirm: () => handleDownload(),
      title: renderTitleDownload(),
      text: '',
      confirmText: t('procedureBox.actions.downloadDocument.modal.alert.button'),
      confirmType: 'primary',
    });
  };

  const handleRedirectPageFolder = () => {
    window.open(
      `/digital_folder/${props.procedure.id}/default`,
      '_blank',
    );
  };

  const loadingTable = () => {
    return (
      <>
        <Skeleton variant={'rectangular'} className={ styles.row } />
        <Skeleton variant={'rectangular'} className={ styles.row } />
        <Skeleton variant={'rectangular'} className={ styles.row } />
      </>
    );
  };

  useEffect(() => {
    getDocuments();
  }, []);

  useEffect(() => {
    getDocuments();
    setBatchId('');
  }, [batches?.find((i) => i.id === batchId)?.status === 'finished']);

  return (
    <div className={styles.container}>
      {
        documents === null ? (
          <Skeleton variant={'rectangular'} className={ styles.skeleton }/>
        ) : (
          documents.length === 0 ? (
            <p className={styles.notFoundDocuments}>{t('procedureBox.processExtract.documentList.notFoundDocumentList')}</p>
          ) : (
            <>
              <div className={styles.digitalFolder}>
                <Button
                  buttonType='primary'
                  round
                  size='flat'
                  title={t('procedureBox.processExtract.documentList.digitalFolder')}
                  onClick={() => handleRedirectPageFolder()} />
              </div>
              <div className={styles.orderSelect}>
                <label>{t('procedureBox.processExtract.samePart.order.label')}</label>
                <Select
                  label={''}
                  dataCy={'order_select'}
                  placeholder={t('procedureBox.processExtract.samePart.order.placeholder')}
                  options={documentsOrder()}
                  onChange={(e) => setOrderBy(e as any) }
                  className={styles.inputSelect}
                  returnValue
                  onlySelect
                />
              </div>
              <div className={styles.listDocs}>
                {loading === false
                  ? <DocumentList
                    documents={[]}
                    removePagination
                    documentsList={filterRows()}
                    onChange={(value) => setDocumentsSeleted(value)}
                    isMobile={isMobile} />
                  : loadingTable()}
              </div>
              <div className={styles.footer}>
                <Show if={documentsSeleted.length > 0}>
                  <Button
                    title={t('procedureBox.processExtract.documentList.button.downloadText')}
                    buttonType='primary'
                    onClick={() => showAlertToDownload()}
                    round
                    size='large'
                    className={styles.space_button} />
                  <Button
                    title={t('procedureBox.processExtract.documentList.button.text')}
                    buttonType='danger'
                    onClick={() => showAlert()}
                    round
                    size='large' />
                </Show>
              </div>
            </>
          )
        )
      }
      <Timeline
        procedureId={props.procedure.id}
        procedureStatus={props.procedure.status}
      />
    </div>
  );
}
