import { useState } from 'react';
import { FaChevronDown, FaChevronUp } from 'react-icons/fa';
import Menu from '@mui/material/Menu';
import styles from './styles.module.scss';
import Show from '../Show';

export type ItemType = {
  id: string;
  query: string;
  translated: string;
  selected: boolean;
};

type MenuSelectProps = {
  itens: ItemType[];
  onChange: (item: ItemType) => void;
  chevron: boolean;
  title?: string;
  style?: any;
};

export function MenuSelect(props: MenuSelectProps) {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [items, setItems] = useState<ItemType[]>(props.itens);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleChange = (item: ItemType) => {
    const list = items.map((itemSelected) => {
      if (item.id === itemSelected.id) {
        return { ...itemSelected, selected: true };
      }
      return { ...itemSelected, selected: false };
    });

    setItems(list);
    handleClose();
    props.onChange(item);
  };

  return (
    <div
      className={`${props.style ? props.style : styles.sortItemContainer} ${open ? styles.sortItemContainerOpen : ''}`}
      onClick={open ? handleClose : handleClick}
    >
      <span className={`${styles.selectedItem}`}>{props.title ? props.title : items.find((i) => i.selected)?.translated}</span>
      <Show if={props.chevron}>
        { open ? <FaChevronUp className={ styles.chevron } /> : <FaChevronDown className={ styles.chevron } /> }
      </Show>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        {items.map((i) => (
          <div
            className={`${i.selected ? styles.searchItemOptionSelected : styles.searchItemOption}`}
            onClick={i.selected ? () => setAnchorEl(null) : () => handleChange(i)}
          >
            <p>{i.translated}</p>
          </div>
        ))}
      </Menu>
    </div>
  );
}
