import { Modal as ModalMui } from '@mui/material';
import { t } from 'i18next';
import { useContext, useState } from 'react';
import { Player, Controls } from '@lottiefiles/react-lottie-player';
import { FaTimes } from 'react-icons/fa';
import { ProcedureActionsContext } from '../../context/procedureActionsContext';
import { Button } from '../Button';
import styles from './styles.module.scss';
import Show from '../Show';
import { CheckBox } from '../CheckBox';

const ERROR_ANIMATION = require('../../assets/lotties/error.json');
const WARNING_ANIMATION = require('../../assets/lotties/warning.json');
const LOADING_ANIMATION = require('../../assets/lotties/loading.json');

type AlertProps = {
  visible?: boolean,
  title?: string,
  text?: string,
  type?: string,
  disabledButtons?: boolean;
};

export function Alert(props: AlertProps) {
  const { alert, setAlert } = useContext(ProcedureActionsContext);
  const [agreement, setAgreement] = useState(false);

  const getAnimation = (type: string) => {
    if (type === 'error' || props.type === 'error') {
      return ERROR_ANIMATION;
    }
    if (type === 'loading' || props.type === 'loading') {
      return LOADING_ANIMATION;
    }
    return WARNING_ANIMATION;
  };

  const isVisible = () => {
    return alert?.visible || props.visible || false;
  };

  if (alert) {
    return (
      <ModalMui
        open={isVisible()}
        onClose={alert.type !== 'loading' ? () => {} : () => setAlert()}
        disableAutoFocus={true}
        onBackdropClick={alert.type !== 'loading' ? () => {} : () => setAlert()}
        >
        <div className={styles.box}>
          <div className={styles.body}>
            <Show if={alert.type !== 'loading'}>
              <a className={styles.closeButton} onClick={() => setAlert()}>
                <FaTimes />
              </a>
            </Show>
            <div className={styles.icon}>
              <Player
                loop={alert.type === 'loading' || props.type === 'loading'}
                autoplay
                keepLastFrame={true}
                src={getAnimation(alert.type || '')}
                speed={2}
                className={styles.lottie}
              >
                <Controls visible={false} />
              </Player>
            </div>
            <span className={styles.title}>{alert.title || props.title}</span>
            <span className={styles.text}>{alert.text || props.text}</span>

            { alert.agreementText
              && <CheckBox
                  fontLarger
                  value={agreement}
                  onClick={() => setAgreement(!agreement)}
                  removeTooltip
                  text={alert.agreementText} />
            }

            { alert.handleConfirm && !props.disabledButtons
              ? <div className={styles.actions}>
                <Button
                  buttonType={alert.cancelType || 'default'}
                  round
                  size='flat'
                  title={alert.cancelText || t('general.no')}
                  onClick={() => setAlert()}
                />
                <Button
                  buttonType={alert.confirmType || 'primary'}
                  round
                  autoFocus
                  size='flat'
                  title={alert.confirmText || t('general.yes')}
                  onClick={alert.handleConfirm}
                  disabled={alert.agreementText !== undefined && !agreement}
                  dataCy={'alert-confirm'}
                />
              </div>
              : null
            }
          </div>
        </div>
      </ModalMui>
    );
  }
  return <></>;
}
