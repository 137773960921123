import React from 'react';
import { Droppable } from 'react-beautiful-dnd';
import { t } from 'i18next';
import styles from './styles.module.scss';
import { Item } from '../Item';
import { MultipleAction } from '../../../@types/backgroundActions';
import Show from '../../Show';
import { EmptyList } from '../EmptyList';

type SectionProps = {
  sourceActions: Array<MultipleAction>;
  selectedActions: Array<MultipleAction>;
  setSourceActions: React.Dispatch<React.SetStateAction<Array<MultipleAction>>>;
  setSelectedActions: React.Dispatch<React.SetStateAction<Array<MultipleAction>>>;
};

export function Section(props: SectionProps) {
  return (
    <div className={ styles.container }>
      <p className={styles.title}>{t('procedureBox.actions.multipleActions.available')}</p>
      <p className={styles.message}>{t('procedureBox.actions.multipleActions.availableMessage')}</p>
      <Droppable droppableId="sourceList">
        {(provided, snapshot) => (
          <div
            className={`${styles.dragBox} ${snapshot.isDraggingOver ? styles.dragactive : ''}`}
            ref={provided.innerRef}
            {...provided.droppableProps}
          >
            {props.sourceActions?.map((item, index) => (
              <Item
                index={index}
                item={item}
                key={item.id}
              />
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
      <p className={styles.title}>{t('procedureBox.actions.multipleActions.selected')}</p>
      <p className={styles.message}>{t('procedureBox.actions.multipleActions.selectedMessage')}</p>
      <Droppable droppableId="selectedList">
        {(provided, snapshot) => (
          <div
            ref={provided.innerRef}
            {...provided.droppableProps}
            className={`${styles.dragBox}  ${snapshot.isDraggingOver ? styles.dragcomplete : ''}`}
          >
            <Show if={props.selectedActions.length > 0}>
              {props.selectedActions?.map((item, index) => (
                <Item
                  index={index}
                  item={item}
                  key={item.id}
                  isSelected
                />
              ))}
              {provided.placeholder}
            </Show>
            <Show if={props.selectedActions.length < 1}>
              <EmptyList />
              {provided.placeholder}
            </Show>
          </div>
        )}
      </Droppable>
    </div>
  );
}
