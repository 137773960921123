import styles from './styles.module.scss';

type ShowAttributeProps = {
  label: string;
  value: any;
};

export function ShowAttribute({ label, value } : ShowAttributeProps) {
  return (
    <div className={styles.item}>
      <div className={styles.title}>{label}</div>
      <div className={styles.desc}>{value}</div>
    </div>
  );
}
