import { Grid } from '@mui/material';
import { t } from 'i18next';
import { Trans } from 'react-i18next';
import moment from 'moment';
import { FaPlus } from 'react-icons/fa';
import { useContext } from 'react';
import { Procedure, InvolvedPeople, ProcedureDeadline } from '../../../../@types/model';
import { DraggableList } from '../../DraggableList';
import { Summary } from '../../Summary';
import { Markers } from '../../Markers';
import styles from './styles.module.scss';
import Format from '../../../../helpers/format';
import { Avatar } from '../../Avatar';
import { Deadline } from '../../../Deadline';
import { ProcedureActionsContext } from '../../../../context/procedureActionsContext';

type ExpandedProps = {
  procedure: Procedure;
  deadlines?: ProcedureDeadline[] | undefined;
  display: boolean;
};

export function Expanded(props: ExpandedProps) {
  const { openDeadline } = useContext(ProcedureActionsContext);
  const getGridProps = () => {
    if (props.display) {
      return { xs: 4, sm: 10, md: 16 };
    }
    return { xs: 4, sm: 10, md: 14 };
  };

  const getDeadline = () => {
    const procedureDeadline = props.procedure.procedure_deadlines;
    if (procedureDeadline) {
      return moment(procedureDeadline[0]?.deadline, 'DD/MM/YY');
    }
    return '';
  };

  const handleInterestedParts = () => {
    const classInterestedP = props.procedure.interested_parts.length > 10 ? `${styles.interestedPartsList} ${styles.scrowInList}` : styles.interestedPartsList;
    return (
      <div className={classInterestedP}>
        {props.procedure.interested_parts.length !== 1 && props.procedure.interested_parts.map((person: any) => <p className={styles.interestedPart}>{person}</p>)}
        {props.procedure.interested_parts.length > 10 && <a className={styles.viewMore}>{ t('procedureBox.procedureList.interested_parts_attributes.view_plus') }</a>}
      </div>
    );
  };

  const handlePeopleInvolved = () => {
    if (props.display) {
      return (
        <>
          <div className={ styles.dataAvatar }>
            <span className={ styles.label }>{t('procedureBox.procedureList.individual_name')}:</span>
            <Avatar
              name={ props.procedure.individual_name }
              color={props.procedure.individual_color}
              profile_picture={props.procedure.individual_profile_picture}
            />
          </div>

          <div className={styles.dataAvatar}>
            <span className={ styles.label }>{t('procedureBox.procedureList.involved_people')}:</span>
            <div className={ styles.avatarList }>
              { props.procedure.involved_people.map((people: InvolvedPeople) => (
                <Avatar
                  name={ people.name }
                  className={styles.avatar}
                  color={people.color}
                  profile_picture={people.profile_picture}
                />))
              }
            </div>
          </div>

          <div className={styles.dataAvatar}>
            <span className={ styles.label }>{t('procedureBox.procedureList.shared_people')}:</span>
            <div className={ styles.avatarList }>
              { props.procedure.shared_people.map((people: InvolvedPeople) => (
                <Avatar
                  name={ people.name }
                  className={styles.avatar}
                  color={people.color}
                  profile_picture={people.profile_picture}
                />))
              }
            </div>
          </div>
        </>
      );
    }
    return <></>;
  };

  const handleScheduleCollumn = () => {
    if (props.display) {
      return (
        <>
          <span className={ styles.valueTitlePhone }>{ t('procedureBox.procedureList.time') }</span>
          <div className={ styles.headerValuePhone }>
            <DraggableList
              procedure={ props.procedure }
              type='schedule'
              expandend
              isOpen
            />
          </div>
        </>
      );
    }
    return (
      <>
        { handleInterestedParts() }
      </>
    );
  };

  const handleDeadlines = () => {
    if (props.deadlines) {
      return (
        <>
          {
            props.deadlines.map((deadline) => (
              (deadline.individual_id === props.procedure.current_individual_id || !deadline.individual_id) && (
                <div key={deadline.id} className={styles.deadline}>
                  <Deadline procedure={props.procedure} procedureDeadline={deadline} />
                </div>
              )
            ))
          }
          <a
            className={styles.iconPlus}
            onClick={() => openDeadline(undefined, props.procedure)}
          >
            <FaPlus size={14}/>
          </a>
        </>
      );
    }
    return <></>;
  };

  const handleDataCollumn = () => {
    if (props.display) {
      return (
        <>
          <span className={ styles.valueTitlePhone }>{ t('procedureBox.procedureList.data') }</span>
          <div className={ styles.headerValuePhone }>
            <DraggableList
              procedure={ props.procedure }
              type='data'
              expandend
              isOpen
            />
          </div>
        </>
      );
    }
    return (
      <>
        <span className={styles.textInfo}>
          <Trans
            i18nKey='procedureCollections.procedureList.flux'
            components={{ bold: <strong /> }}
            values={{ flux: props.procedure.flux.name }}
          />
        </span>
        <span className={styles.textInfo}>
          <Trans
            i18nKey='procedureCollections.procedureList.created_at'
            components={{ bold: <strong /> }}
            tOptions={{ interpolation: { escapeValue: false } }}
            values={{ date: moment(new Date(props.procedure.created_at)).format('DD/MM/YY') }}
          />
        </span>
        <span className={styles.textInfo}>
          <Trans
            i18nKey='procedureCollections.procedureList.procedure_value'
            components={{ bold: <strong /> }}
            values={{ value: Format.currency(props.procedure.procedure_value) }}
          />
        </span>
        <span className={styles.textInfo}>
          <Trans
            i18nKey='procedureCollections.procedureList.origin_procedure_name'
            components={{ bold: <strong /> }}
            values={{ origin: props.procedure?.origin_procedure_name }}
          />
        </span>
        <span className={styles.textInfo}>
          <Trans
            i18nKey='procedureCollections.procedureList.audiences'
            components={{ bold: <strong /> }}
            tOptions={{ interpolation: { escapeValue: false } }}
            values={{ value: getDeadline() }}
          />
        </span>
        <Summary />
      </>
    );
  };

  return (
    <Grid
      container
      columns={getGridProps()}
      className={ styles.container }
      onClick={ (e) => e.stopPropagation() }
    >
      <Grid
        item
        className={ `${styles.value} ${styles.procedureNumber}` }
        xs={ 3 }
        md={ 3 }
        sm={ 3 }
      >
        <Markers
          expandend
          procedureId={props.procedure.id}
          markers={props.procedure.markers}
          priority={props.procedure.marker_priority}
          pendency={props.procedure.marker_pending}
        />
      </Grid>
      <Grid
        item
        className={ `${styles.value} ${styles.titleMobile}` }
        xs={ props.display ? 3 : 2 }
        md={ props.display ? 3 : 2 }
        sm={ props.display ? 3 : 2 }
      >
        <div className={ styles.valueWithTitle }>
        { handleDataCollumn() }
        </div>
      </Grid>
      <Grid
        item
        onClick={ (e) => e.stopPropagation() }
        className={ `${styles.value} ${styles.titleMobile}` }
        xs={ props.display ? 3 : 2 }
        md={ props.display ? 3 : 2 }
        sm={ props.display ? 3 : 2 }
      >
        <div className={ styles.valueWithTitle }>
          { handleScheduleCollumn() }
        </div>
      </Grid>
      <Grid
        item
        className={ `${styles.value} ${styles.titleMobile}` }
        xs={ 4 }
        md={ 2 }
        sm={ 4 }
        sx={{ display: { xs: 'none', sm: 'block', md: 'block' } }}
      >
        <div className={styles.involvedPeople}>
          { handlePeopleInvolved() }
        </div>
      </Grid>
      <Grid
        item
        className={ `${styles.value} ${styles.titleMobile}` }
        xs={ 2 }
        md={ 3 }
        sm={ 5 }
        sx={{ display: { xs: 'none', sm: 'block', md: 'block' } }}
      >
        <div>
          { handleDeadlines() }
        </div>
      </Grid>
    </Grid>
  );
}
