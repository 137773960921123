import { useContext, useEffect, useState } from 'react';
import { t } from 'i18next';
import Screen from '../../../helpers/screen';
import { Button } from '../../Button';
import { DocumentList } from '../../DocumentList';
import { SelectedProcedures } from '../../SelectedProcedures';
import ProcedureBoxService from '../../../services/procedureBoxService';
import styles from './styles.module.scss';
import { ProcedureActionsContext } from '../../../context/procedureActionsContext';
import { ProcedureCarousel } from '../../ForwardIpm/ProcedureCarousel';
import { AttachedDocument } from '../../../@types/config';
import { Batch, MultipleActionStep } from '../../../@types/backgroundActions';
import { BackgroundActionsContext } from '../../../context/backgroundActions';
import { Login } from '../Login';
import { CurrentUser } from '../../../@types/currentUser';

type PetitionIntermediaryProps = {
  multipleActions?: MultipleActionStep;
};

export function PetitionIntermediary(props: PetitionIntermediaryProps) {
  const { proceduresSeleted, removeProcedures, setModal } = useContext(ProcedureActionsContext);
  const currentUser: CurrentUser = JSON.parse(localStorage.getItem('currentUser')!);
  const { addBatch } = useContext(BackgroundActionsContext);

  const [documentsList, setDocumentsList] = useState([]);
  const [documentsSeleted, setDocumentsSeleted] = useState<AttachedDocument[]>([]);
  const [procedureId, setProcedureId] = useState(proceduresSeleted[0].id);
  const [protocolNumber, setProtocolNumber] = useState('');

  const isMobile = Screen.isMobile();

  const getIntermediatePetition = async () => {
    const response: any = await ProcedureBoxService.getIntermediatePetitionData(procedureId);

    const documents = response.documents_signed.map((document: any) => {
      return (
        {
          ...document,
          procedure_id: procedureId,
          type: document.type,
        }
      );
    });

    setProtocolNumber(response?.protocol);
    setDocumentsList(documents);
  };

  const getAllDocuments = () => {
    const allDocuments: any[] = [];
    for (let index = 0; index < proceduresSeleted.length; index += 1) {
      ProcedureBoxService.getInitialPetitionData(proceduresSeleted[index].id)
        .then((res) => {
          const docs = res.documents_signed.map((document: any) => {
            return { ...document, procedure_id: proceduresSeleted[index].id };
          });

          for (let i = 0; i < docs.length; i += 1) {
            allDocuments.push(docs[i]);
          }
        });
    }

    setDocumentsSeleted(allDocuments);
  };

  const getData = () => {
    const pjeData: any[] = [];
    proceduresSeleted.map((procedure: any) => {
      const documentsToPetition = documentsSeleted.filter((document: any) => document.procedure_id === procedure.id);
      const procedureToPetition = {
        procedure_id: procedure.id,
        attach_document_ids: documentsToPetition.map((doc: any) => doc.file_id),
      };

      pjeData.push(procedureToPetition);
    });

    return pjeData;
  };

  const handleSendIntermediatePetition = (password: string = '') => {
    const proceduresToPetition = getData();
    const body = {
      proceduresToPetition,
      password,
    };
    const procedures = proceduresSeleted;
    const type = 'intermediatePetition';
    const id = (Math.random() + 1).toString(36).substring(1);

    const batch: Batch = {
      id,
      procedures,
      type,
      count: proceduresSeleted.length,
      successList: [],
      failedList: [],
      body,
      multipleActions: props.multipleActions,
    };
    addBatch(batch);
    setModal(undefined);
    if (!props.multipleActions) {
      removeProcedures(procedures);
    }
  };

  useEffect(() => {
    setTimeout(() => {
      getIntermediatePetition();
    }, 200);
  }, [procedureId]);

  useEffect(() => {
    getAllDocuments();
  }, []);

  const openModalLogin = () => {
    setModal({
      visible: true,
      open: true,
      size: 'small',
      title: t('procedureBox.actions.initialPetition.modal.login.title'),
      children: <Login
                  submit={(password) => handleSendIntermediatePetition(password)}
                  goBack={() => setModal(undefined)} />,
      handleClose: () => setModal(undefined),
    });
  };

  const handlePetitionIntermediate = () => {
    if (currentUser.has_pje_user) {
      handleSendIntermediatePetition();
    } else {
      openModalLogin();
    }
  };

  return (
    <div className={styles.container}>
      <SelectedProcedures />

      <div className={styles.procedures}>
        <ProcedureCarousel
          isMobile={isMobile}
          onClick={(id) => setProcedureId(id)}
          procedures={proceduresSeleted}
        />
      </div>

      <div className={styles.header}>
        <p>{t('procedureBox.actions.intermediatePetition.modal.protocolNumber')}</p>
      </div>

      <div className={styles.protocolNumber}>
        <span>{protocolNumber}</span>
      </div>

      <header className={styles.header}>
        <p>{t('procedureBox.actions.intermediatePetition.modal.text')}</p>
      </header>

      <div className={styles.infos}>
        <span>{t('procedureBox.actions.intermediatePetition.modal.documentSelect')}</span>
        <span>{t('procedureBox.actions.intermediatePetition.modal.info')}</span>
      </div>

      <div className={styles.documents}>
        {
          documentsList.length > 0 && (
            <DocumentList
              documents={documentsSeleted}
              isMobile={isMobile}
              documentsList={documentsList}
              selectColor={'var(--primary'}
              sizeField
              removePagination
              onChange={(doc) => setDocumentsSeleted(doc)}
            />
          )
        }
      </div>

      <footer>
      { documentsSeleted.length > 0
          && <Button
          title={t('procedureBox.actions.initialPetition.modal.button')}
          onClick={() => handlePetitionIntermediate()}
          size='flat'
          buttonType='primary'
          round
        />}
      </footer>
    </div>
  );
}
