import { GenericMarker } from '../../@types/markers';
import { Procedure } from '../../@types/model';
import { MarkerItem } from '../ProcedureList/Markers/Menu/MarkerItem';

import styles from './styles.module.scss';

interface MarkerContentProps {
  procedureId: number;
  procedures?: Procedure[];
  priorityMarkers?: GenericMarker[];
  pendencyMarkers?: GenericMarker[];
  handleClose: () => void;
  isOpen: boolean;
}

export function MarkerContent(props: MarkerContentProps) {
  if (props.isOpen) {
    return <div className={styles.container} >
      <MarkerItem
        onChange={props.handleClose}
        type='priority'
        options={props.priorityMarkers}
        procedureId={props.procedureId}
        procedures={props.procedures}
        open
      />
      <MarkerItem
        onChange={props.handleClose}
        type='pendency'
        options={props.pendencyMarkers}
        procedures={props.procedures}
        procedureId={props.procedureId}
        open
      />
      <MarkerItem
        onChange={props.handleClose}
        type='custom'
        procedures={props.procedures}
        procedureId={props.procedureId}
        open
      />
    </div>;
  }

  return <></>;
}
