import { Grid } from '@mui/material';
import { t } from 'i18next';
import { useContext, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Button } from '../../../../components/Button';
import { Header } from '../../../../components/Header';
import { InputText } from '../../../../components/InputText';
import FormHelper from '../../../../helpers/form';
import NewProcessService from '../../../../services/newProcessService';
import { StreetTypes, EventTypes } from '../../../../helpers/enums';
import { InterestedParts } from '../InterestedParts';
import styles from './styles.module.scss';
import { InputDate } from '../../../../components/InputDate';
import Format from '../../../../helpers/format';
import { ProcedureActionsContext } from '../../../../context/procedureActionsContext';
import { Errors } from '../../../../@types/config';
import Select from '../../../../components/Select';
import { SelectGroup } from '../../../../components/SelectGroup';
import Show from '../../../../components/Show';
import { ProcedureType } from '../../../../@types/processRegistration';
import { Moment } from '../../../../config/moment';
import { ErrorField } from '../../../../components/ErrorField';

type NewJudicialProcessProps = {
  fluxId: number;
  closeModal: () => void;
  isProurma?: boolean;
  procedure: ProcedureType;
};

export function NewJudicialProcess(props: NewJudicialProcessProps) {
  const {
    handleSubmit,
    control,
    setValue,
    getValues,
    unregister,
    register,
    watch,
  } = useForm();
  const { addToasts } = useContext(ProcedureActionsContext);
  const [loading, setLoading] = useState(false);
  const [processNumberMessageError, setProcessNumberMessageError] = useState(false);
  const [errors, setErrors] = useState<Errors | null>(null);
  const [origins, setOrigins] = useState([]);
  const [subject, setSubject] = useState();
  const [subjects, setSubjects] = useState([]);
  const [passiveOptions, setPassiveOptions] = useState([]);
  const [activeOptions, setActiveOptions] = useState([]);
  const [individuals, setIndividuals] = useState([]);
  const [competences, setCompetences] = useState([]);
  const [classes, setClasses] = useState([]);
  const [eventType, setEventType] = useState<any>();
  const [courts, setCourts] = useState([]);
  const [parts, setParts] = useState<any>([]);
  const [cityAreas, setCityAreas] = useState([]);
  const watchCompetence = watch('competence_id');
  const judicialProps = props.procedure && props.procedure.judicial_process;
  const eventSelection = props.procedure?.judicial_process?.event_type !== null
    ? EventTypes()[1]
    : EventTypes()[0];

  useEffect(() => {
    setClasses([]);
    if (watchCompetence) {
      NewProcessService.getJudicialProcessClasses(watchCompetence)
        .then((res) => {
          setClasses(res.data.class_name_id);
        });
    }
  }, [watchCompetence]);

  const loadJudicialProcessData = () => {
    NewProcessService.getNewJudicialProcessData(props.fluxId)
      .then((res) => {
        setPassiveOptions(res.data.participation_id_passive);
        setActiveOptions(res.data.participation_id_active);
        setSubjects(res.data.major_subject_id);
        setOrigins(res.data.origin_id);
        setIndividuals(res.data.individual_id);
        setCompetences(res.data.competence_id);
        setCourts(res.data.court_id);
        setCityAreas(res.data.city_area);
      });

    NewProcessService.getJudicialProcessSubjects()
      .then((res) => {
        setSubjects(res.data);
      });
  };

  useEffect(() => {
    loadJudicialProcessData();
    if (props.procedure) {
      const judicialData = props.procedure.judicial_process;
      setSubject(judicialData.major_subject_data.value);
      setValue('process_number', judicialData.process_number);
      setValue('origin_id', judicialData.origin_data.value);
      setValue('competence_id', judicialData.competence_data.value);
      setValue('class_name_id', judicialData.class_data.value);
      setValue('share_price', judicialData.share_price);
      setValue('major_subject_id', judicialData.major_subject_data.value);
      setValue('attorney_id', judicialData.attorney_data.value);
      if (props.procedure.judicial_process.event_data !== null) {
        setEventType(0);
        setValue('event_attributes.process_number', judicialProps.event_data?.process_number);
        setValue('event_attributes.event_schedule', Moment(judicialProps.event_data?.event_date).format('DD/MM/YYYY HH:mm'));
      } else {
        setEventType(1);
      }
    }
  }, []);

  useEffect(() => {
    if (eventType === 0) {
      setValue('event_attributes.event_type', 0);
      setValue('event', true);
    } else {
      unregister('event_attributes');
      setValue('event', false);
    }
  }, [eventType]);

  const checkIfProcessNumberExists = async () => {
    const values = getValues();
    const processNumber = values.process_number.replace(/[^\d]+/g, '');
    const response = await NewProcessService.getJudicialProcessExist(processNumber);

    setProcessNumberMessageError(response);
  };

  const submit = (form: any) => {
    setLoading(true);
    setErrors(null);
    const sharePrice = Format.removeCurrencyFormat(form.share_price);
    const data = {
      flux_id: props.fluxId,
      judicial_process: {
        ...form,
        share_price: sharePrice,
      },
    };
    setParts([]);

    if (props.procedure) {
      const editData = {
        flux_id: props.fluxId,
        procedure_id: props.procedure.judicial_process?.procedure_id,
        judicial_process: {
          ...form,
          share_price: sharePrice,
        },
      };

      NewProcessService.editJudicialProcess(editData)
        .then((res) => {
          if (res.errors) {
            setErrors(res.errors);
          } else {
            props.closeModal();
            addToasts({
              type: 'success',
              text: FormHelper.customToastMessage(res.id, 'edit'),
            });
          }
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
          if (err.response.status === 400) {
            setErrors(err.response.data);
          }
        });
    } else {
      NewProcessService.createJudicialProcess(data)
        .then((res) => {
          if (res.errors) {
            setErrors(res.errors);
          } else {
            props.closeModal();
            addToasts({
              type: 'success',
              text: FormHelper.customToastMessage(res.id),
            });
          }
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
          if (err.status === 400) {
            setErrors(err.data);
          }
        });
    }
  };
  return (
    <form className={ styles.container } onSubmit={handleSubmit(submit)}>
      <Header text={t('procedureBox.newProcedure.newJudicialProcess.data')}/>
      <Grid
        container
        columns={{ xs: 1, sm: 10, md: 12 }}
      >
        <Grid
          item
          xs={ 1 }
          sm={ 5 }
          md={ 4 }
          className={styles.input}
        >
          <Controller
            control={control}
            name="process_number"
            render={({ field }) => (
              <InputText
                {...field}
                label={t('procedureBox.newProcedure.newJudicialProcess.form.process_number.label')}
                placeholder={t('procedureBox.newProcedure.newJudicialProcess.form.process_number.placeholder')}
                dataCy={'process_number'}
                onBlur={() => checkIfProcessNumberExists()}
                mask={'9999999-99.9999.9.99.9999'}
                required
                defaultValue={judicialProps && judicialProps.process_number}
              />
            )}
          />
          <Show if={processNumberMessageError}>
            <ErrorField
              text={t('procedureBox.newProcedure.newJudicialProcess.form.process_number.processExist')}
            />
          </Show>
        </Grid>

        <Grid
          item
          xs={ 1 }
          sm={ 5 }
          md={ 4 }
          className={styles.input}
        >
          <Controller
            control={control}
            name="origin_id"
            render={({ field }) => (
              <Select
                {...field}
                label={t('procedureBox.newProcedure.newJudicialProcess.form.origin_id.label')}
                placeholder={t('procedureBox.newProcedure.newJudicialProcess.form.origin_id.placeholder')}
                options={origins}
                dataCy='origin_procedure_id'
                returnValue
                required
                defaultValue={judicialProps && judicialProps.origin_data}
              />
            )}
          />
          {FormHelper.renderErrorField('origin_id', errors)}
        </Grid>

        <Grid
          item
          xs={ 1 }
          sm={ 5 }
          md={ 4 }
          className={styles.input}
        >
          <Controller
            control={control}
            name="attorney_id"
            render={({ field }) => (
              <Select
                {...field}
                label={t('procedureBox.newProcedure.newJudicialProcess.form.attorney_id.label')}
                placeholder={t('procedureBox.newProcedure.newJudicialProcess.form.attorney_id.placeholder')}
                options={individuals}
                dataCy='attorney_id'
                returnValue
                defaultValue={judicialProps && judicialProps.attorney_data}
              />
            )}
          />
          {FormHelper.renderErrorField('interested_part', errors)}
        </Grid>

        <Grid
          item
          xs={ 1 }
          sm={ 5 }
          md={ 4 }
          className={styles.input}
        >
          <Controller
            control={control}
            name="competence_id"
            render={({ field }) => (
              <Select
                {...field}
                label={t('procedureBox.newProcedure.newJudicialProcess.form.competence_id.label')}
                placeholder={t('procedureBox.newProcedure.newJudicialProcess.form.competence_id.placeholder')}
                options={competences}
                dataCy='competence_id'
                returnValue
                required
                defaultValue={judicialProps && judicialProps.competence_data}
              />
            )}
          />
          {FormHelper.renderErrorField('competence_id', errors)}
        </Grid>

        <Grid
          item
          xs={ 1 }
          sm={ 5 }
          md={ 4 }
          className={styles.input}
        >
          <Controller
            control={control}
            name="class_name_id"
            render={({ field }) => (
              <Select
                {...field}
                label={t('procedureBox.newProcedure.newJudicialProcess.form.class_name_id.label')}
                placeholder={t('procedureBox.newProcedure.newJudicialProcess.form.class_name_id.placeholder')}
                dataCy='class_name_id'
                options={classes}
                returnValue
                required
                defaultValue={judicialProps && judicialProps.class_data}
              />
            )}
          />
          {FormHelper.renderErrorField('class_name_id', errors)}
        </Grid>

        <Grid
          item
          xs={ 1 }
          sm={ 5 }
          md={ 4 }
          className={styles.input}
        >
          <Controller
            control={control}
            name="major_subject_id"
            render={({ field }) => (
              <SelectGroup
                label={t('procedureBox.newProcedure.newJudicialProcess.form.major_subject_id.label')}
                required
                actionMenu
                options={subjects}
                dataCy='major_subject_id'
                value={subject}
                actionForm={props?.procedure?.judicial_process ? 'edit' : 'create'}
                onChange={(val) => {
                  setSubject(val);
                  field.onChange(val);
                }}
              />
            )}
          />
          {FormHelper.renderErrorField('major_subject_id', errors)}
        </Grid>

        <Grid
          item
          xs={ 1 }
          sm={ 5 }
          md={ 4 }
          className={styles.input}
        >
          <Controller
            control={control}
            name="share_price"
            render={({ field }) => (
              <InputText
                {...field}
                label={t('procedureBox.newProcedure.newJudicialProcess.form.share_price.label')}
                placeholder={t('procedureBox.newProcedure.newJudicialProcess.form.share_price.placeholder')}
                type={'number'}
                dataCy='share_price'
                currency
                required
                value={judicialProps && judicialProps.share_price}
              />
            )}
          />
          {FormHelper.renderErrorField('share_price', errors)}
        </Grid>
      </Grid>

      <Show if={ props.fluxId !== undefined && props.fluxId === 38}>
        <Header text={t('procedureBox.newProcedure.newAdministrativeProurmaProcess.form.address')}/>

        <Grid
          container
          columns={{ xs: 1, sm: 10, md: 12 }}
        >
          <Grid
            item
            xs={ 1 }
            sm={ 5 }
            md={ 4 }
            className={styles.input}
          >
            <Controller
              control={control}
              name="address.cep"
              render={({ field }) => (
                <InputText
                  {...field}
                  label={t('procedureBox.newProcedure.newAdministrativeProurmaProcess.form.cep.label')}
                  mask='99.999-999'
                />
              )}
            />
            {FormHelper.renderErrorField('address.cep', errors)}
          </Grid>

          <Grid
            item
            xs={ 1 }
            sm={ 5 }
            md={ 4 }
            className={styles.input}
          >
            <Controller
              control={control}
              name="address.street_type"
              render={({ field }) => (
                <Select
                  {...field}
                  options={StreetTypes()}
                  label={t('procedureBox.newProcedure.newAdministrativeProurmaProcess.form.street_type.label')}
                  returnValue
                />
              )}
            />
            {FormHelper.renderErrorField('address.street_type', errors)}
          </Grid>

          <Grid
            item
            xs={ 1 }
            sm={ 5 }
            md={ 4 }
            className={styles.input}
          >
            <Controller
              control={control}
              name="address.street"
              render={({ field }) => (
                <InputText
                  {...field}
                  label={t('procedureBox.newProcedure.newAdministrativeProurmaProcess.form.street.label')}
                />
              )}
            />
            {FormHelper.renderErrorField('address.street', errors)}
          </Grid>

          <Grid
            item
            xs={ 1 }
            sm={ 5 }
            md={ 4 }
            className={styles.input}
          >
            <Controller
              control={control}
              name="address.address_number"
              render={({ field }) => (
                <InputText
                  {...field}
                  label={t('procedureBox.newProcedure.newAdministrativeProurmaProcess.form.address_number.label')}
                />
              )}
            />
            {FormHelper.renderErrorField('address.address_number', errors)}
          </Grid>

          <Grid
            item
            xs={ 1 }
            sm={ 5 }
            md={ 4 }
            className={styles.input}
          >
            <Controller
              control={control}
              name="address.allotment"
              render={({ field }) => (
                <InputText
                  {...field}
                  label={t('procedureBox.newProcedure.newAdministrativeProurmaProcess.form.allotment.label')}
                />
              )}
            />
            {FormHelper.renderErrorField('address.allotment', errors)}
          </Grid>

          <Grid
            item
            xs={ 1 }
            sm={ 5 }
            md={ 4 }
            className={styles.input}
          >
            <Controller
              control={control}
              name="address.city_area"
              render={({ field }) => (
                <Select
                  {...field}
                  options={cityAreas}
                  label={t('procedureBox.newProcedure.newAdministrativeProurmaProcess.form.city_area.label')}
                  returnValue
                />
              )}
            />
            {FormHelper.renderErrorField('address.city_area', errors)}
          </Grid>

          <Grid
            item
            xs={ 1 }
            sm={ 5 }
            md={ 4 }
            className={styles.input}
          >
            <Controller
              control={control}
              name="address.complement"
              render={({ field }) => (
                <InputText
                  {...field}
                  label={t('procedureBox.newProcedure.newAdministrativeProurmaProcess.form.complement.label')}
                />
              )}
            />
            {FormHelper.renderErrorField('address.complement', errors)}
          </Grid>

          <Grid
            item
            xs={ 1 }
            sm={ 5 }
            md={ 4 }
            className={styles.input}
          >
            <Controller
              control={control}
              name="address.address_reference"
              render={({ field }) => (
                <InputText
                  {...field}
                  label={t('procedureBox.newProcedure.newAdministrativeProurmaProcess.form.reference.label')}
                />
              )}
            />
            {FormHelper.renderErrorField('address.reference', errors)}
          </Grid>
        </Grid>
      </Show>

      <InterestedParts
        passiveOptions={passiveOptions}
        activeOptions={activeOptions}
        flux={props.fluxId}
        control={control}
        setValue={setValue}
        errors={errors}
        setErrors={setErrors}
        unregister={unregister}
        prefix='judicial_parts_attributes'
        list={parts}
        onChangeList={(list) => setParts(list)}
        isUniq={!!props.isProurma}
        procedure={props.procedure}
      />
      {FormHelper.renderErrorField('base', errors)}

      <Header text={t('procedureBox.newProcedure.newJudicialProcess.event_attributes.title')}/>
      <Grid
        container
        columns={{ xs: 1, sm: 10, md: 12 }}
      >
        <Grid
          item
          xs={ 1 }
          sm={ 5 }
          md={ 4 }
          className={styles.input}
        >
          <Select
            options={EventTypes()}
            onChange={(event) => setEventType(event?.value)}
            dataCy='event_type'
            label={t('procedureBox.newProcedure.newJudicialProcess.event_attributes.event_type.label')}
            placeholder={t('procedureBox.newProcedure.newJudicialProcess.event_attributes.event_type.placeholder')}
            defaultValue={props.procedure?.judicial_process?.event_data && eventSelection}
            required
          />
          {FormHelper.renderErrorField('event_attributes.event_type', errors)}
        </Grid>
        <input
          type='hidden'
          {...register('event')}
        />
        {
          eventType === 0 && (
            <>
              <input type='hidden' {...register('event_attributes.event_type')} />
              <input type='hidden' {...register('event_attributes.ignore_validations')} value={'true'} />
              <Grid
                item
                xs={ 1 }
                sm={ 5 }
                md={ 4 }
                className={styles.input}
              >
                <Controller
                  control={control}
                  name="event_attributes.individual_id"
                  render={({ field }) => (
                    <Select
                      {...field}
                      options={individuals}
                      label={t('procedureBox.newProcedure.newJudicialProcess.event_attributes.individual_id.label')}
                      placeholder={t('procedureBox.newProcedure.newJudicialProcess.event_attributes.individual_id.placeholder')}
                      returnValue
                      defaultValue={judicialProps && judicialProps.event_data?.attorney_data}
                      onChange={ (e) => field.onChange(e) }
                      required
                    />
                  )}
                />
                {FormHelper.renderErrorField('event_type.individual_id', errors)}
              </Grid>
              <Grid
                item
                xs={ 1 }
                sm={ 5 }
                md={ 4 }
                className={styles.input}
              >
                <Controller
                  control={control}
                  name="event_attributes.process_number"
                  render={({ field }) => (
                    <InputText
                      {...field}
                      label={t('procedureBox.newProcedure.newJudicialProcess.event_attributes.process_number.label')}
                      placeholder={t('procedureBox.newProcedure.newJudicialProcess.event_attributes.process_number.placeholder')}
                      defaultValue={judicialProps && judicialProps.event_data?.process_number}
                      required
                    />
                  )}
                />
                {FormHelper.renderErrorField('event_type.process_number', errors)}
              </Grid>
              <Grid
                item
                xs={ 1 }
                sm={ 5 }
                md={ 4 }
                className={styles.input}
              >
                <Controller
                  control={control}
                  name="event_attributes.court_id"
                  render={({ field }) => (
                    <Select
                      {...field}
                      options={courts}
                      label={t('procedureBox.newProcedure.newJudicialProcess.event_attributes.court_id.label')}
                      placeholder={t('procedureBox.newProcedure.newJudicialProcess.event_attributes.court_id.placeholder')}
                      returnValue
                      defaultValue={judicialProps && judicialProps.event_data?.court_data}
                      onChange={
                        (e) => field.onChange(e)
                      }
                      required
                    />
                  )}
                />
                {FormHelper.renderErrorField('event_type.court_id', errors)}
              </Grid>
              <Grid
                item
                xs={ 1 }
                sm={ 5 }
                md={ 4 }
                className={styles.input}
              >
                <Controller
                  control={control}
                  name="event_attributes.event_schedule"
                  render={({ field }) => (
                    <InputDate
                      {...field}
                      datetime
                      label={t('procedureBox.newProcedure.newJudicialProcess.event_attributes.event_schedule.label')}
                      placeholder={t('procedureBox.newProcedure.newJudicialProcess.event_attributes.event_schedule.placeholder')}
                      value={judicialProps && Moment(judicialProps.event_data?.event_date).format('DD/MM/YYYY HH:mm')}
                      required
                    />
                  )}
                />
                {FormHelper.renderErrorField('event_type.event_schedule', errors)}
              </Grid>
            </>
          )
        }
      </Grid>
      <div className={styles.submit}>
        <Button
          title={t('procedureBox.newProcedure.submit')}
          textCenter
          round
          buttonType='primary'
          size='flat'
          type='submit'
          disabled={loading}
          dataCy={'submit-edit-judicial-process'}
        />
      </div>
    </form>
  );
}
