import { t } from 'i18next';
import {
  FaArchive,
  FaShoePrints,
  FaFileAlt,
  FaFolder,
  FaHands,
  FaPaperclip,
  FaPaperPlane,
  FaFileInvoiceDollar,
  FaPenNib,
  FaTag,
  FaTrashAlt,
  FaBoxOpen,
  FaStickyNote,
  FaListAlt,
  FaClock,
  FaLink,
  FaSitemap,
  FaTimes,
  FaShareAlt,
} from 'react-icons/fa';
import { ImShuffle } from 'react-icons/im';
import LinkIcon from '@mui/icons-material/Link';
import { useContext, useState, useEffect } from 'react';
import { RoundButton } from '../../../RoundButton';
import { RectangularButton, Option } from '../../../RectangularButton';
import styles from './styles.module.scss';
import { ProcedureActionsContext } from '../../../../context/procedureActionsContext';
import Show from '../../../Show';
import { isVisible } from '../../../../helpers/actionsMenu';
import { SubProcedure } from '../SubProcedure';
import { Procedure } from '../../../../@types/model';
import { MarkerContent } from '../../../MarkerContent';
import { checkSendSpu } from '../../../../helpers/spu';
import { BackgroundActionsContext } from '../../../../context/backgroundActions';
import { Batch } from '../../../../@types/backgroundActions';
import ProcedureBoxService from '../../../../services/procedureBoxService';
import { judicialFluxes } from '../../../../helpers/menuItems';
import { isProcedureCollection } from '../../../../helpers/routeTranslate';
import { CurrentUser } from '../../../../@types/currentUser';

type ItemProps = {
  procedure?: Procedure;
};

export function Menu(props: ItemProps) {
  const disabled = false;
  const {
    openSigner,
    openCosigner,
    openReadyForProgress,
    openTramit,
    openDistributeProcesses,
    openChangeFlux,
    openEditProcedure,
    openCreateDocument,
    openAttachFile,
    openDeleteFile,
    openArchiveProcesses,
    openDeleteProcesses,
    handleForceTramit,
    openForwardIpm,
    openSpu,
    openRegistrationSpu,
    openJoinDocuments,
    openUnarchiveModal,
    openNotesModal,
    proceduresSeleted,
    openInitialPetition,
    openIntermediaryPetition,
    openMultipleActions,
    openDeadline,
    removeProcedures,
    openAttachProcedure,
    openShareProcedure,
    openUnattachProcedure,
    openAttachProcedureCitationIntimation,
    setAlert,
  } = useContext(ProcedureActionsContext);
  const box = props.procedure?.box || proceduresSeleted[0].box;
  const currentUser: CurrentUser = JSON.parse(localStorage.getItem('currentUser')!);
  const [open, setOpen] = useState(false);
  const { addBatch } = useContext(BackgroundActionsContext);
  const [disabledInitialPetition, setDisabledInitialPetition] = useState(true);
  const [disabledDeadline, setDisabledDeadline] = useState(true);
  const [disabledIntermediatePetition, setDisabledIntermediatePetition] = useState(true);

  const verifyFluxes = () => {
    const filingFlux = props.procedure?.flux.code === 'FLU0020';
    const executionFlux = props.procedure?.flux.code === 'FLU0003';

    if (filingFlux || proceduresSeleted[0]?.flux.code === 'FLU0020') {
      setDisabledInitialPetition(false);
    } else {
      setDisabledInitialPetition(true);
    }

    if (executionFlux || proceduresSeleted[0]?.flux.code === 'FLU0003') {
      setDisabledIntermediatePetition(false);
    } else {
      setDisabledIntermediatePetition(true);
    }
  };

  const showDeadline = (boxParam: string) => {
    return boxParam === 'inbox' || proceduresSeleted.length === 1;
  };

  useEffect(() => {
    const procedureIds = proceduresSeleted.map((procedure: Procedure) => procedure.id);

    verifyFluxes();

    ProcedureBoxService.verifyPetitionInPje(procedureIds).then((res: boolean) => {
      setDisabledInitialPetition(res);
    });

    if (proceduresSeleted.length === 1) {
      ProcedureBoxService.canViewDeadline(proceduresSeleted[0].id, proceduresSeleted[0].box).then((res: boolean) => {
        setDisabledDeadline(res);
      });
    }
  }, [proceduresSeleted]);

  useEffect(() => {
    verifyFluxes();
  }, []);

  const attach: Option[] = [
    {
      title: 'attachProcedure',
      action: openAttachProcedure,
      disabled: false,
    },
    {
      title: 'unattachProcedure',
      action: openUnattachProcedure,
      disabled: (proceduresSeleted.length > 1),
      disabledText: t('procedureBox.actions.attach.disabled'),
    },
  ];

  const court: Option[] = [
    {
      title: 'initialPetition',
      action: openInitialPetition,
      disabled: disabledInitialPetition,
    },
    {
      title: 'intermediatePetition',
      action: openIntermediaryPetition,
      disabled: disabledIntermediatePetition,
    },
  ];

  const signer: Option[] = [
    {
      title: 'signer',
      action: () => openReadyForProgress('signer', openSigner),
      disabled: false,
    },
    {
      title: 'cosigner',
      action: () => openReadyForProgress('cosigner', openCosigner),
      disabled: true,
    },
  ];

  const spu: Option[] = [
    {
      title: 'registrationOficialLetter',
      action: () => openRegistrationSpu(undefined),
      disabled: !checkSendSpu(proceduresSeleted[0]?.flux?.code),
    },
    {
      title: 'sendOficialLetter',
      action: () => openSpu(undefined),
      disabled: !checkSendSpu(proceduresSeleted[0]?.flux?.code),
    },
  ];

  const ipm: Option[] = [
    {
      title: 'forwardIPM.text',
      action: openForwardIpm,
      disabled: false,
    },
  ];

  const handleConfirm = (procedures: any[]) => {
    const body = {
      waiting: true,
    };

    const type = 'attachExtract';
    const id = (Math.random() + 1).toString(36).substring(1);

    const batch: Batch = {
      id,
      procedures,
      type,
      count: procedures.length,
      successList: [],
      failedList: [],
      body,
    };
    addBatch(batch);
    removeProcedures(procedures);
  };

  const handleAttachExtract = () => {
    const procedures = proceduresSeleted;
    const procedureNumbers = procedures.map((procedure) => procedure.process_number);

    setAlert({
      visible: true,
      title: t('procedureBox.actions.attachExtract.modal.alert.title'),
      text: procedureNumbers.join(', '),
      type: 'warning',
      cancelText: t('procedureBox.actions.attachExtract.modal.alert.cancel'),
      confirmText: t('procedureBox.actions.attachExtract.modal.alert.confirm'),
      handleConfirm: () => {
        setAlert(undefined);
        handleConfirm(procedures);
      },
    });
  };

  const getCurrentProcedure = () => {
    return props.procedure || proceduresSeleted[0];
  };

  const validateDistribute = () => {
    const procedure = getCurrentProcedure();
    const involvedPeople = procedure.involved_people.map((ip) => ip.name);
    return (involvedPeople.includes(currentUser.name) || procedure.status === 'archived');
  };

  const checkProceduresFluxes = () => {
    const procedures = proceduresSeleted;
    const codes = procedures.map((procedure) => (procedure.flux.code));
    if (codes.includes('FLU0003') || codes.includes('FLU0051')) {
      return true;
    }
    return false;
  };

  return (
  <>
    <div className={ styles.rightBorder }>
      <RoundButton
        className={ styles.button }
        onClick={() => openMultipleActions(props.procedure)}
        icon={<FaListAlt/>}
        tooltip={t('procedureBox.actions.multipleActions.title')}
        size='big'
      />
    </div>
    <Show if={isVisible(box, 'deadline') && showDeadline(box)}>
      <div className={ styles.rightBorder }>
        <RoundButton
          dataCy={'cypress-button-deadline-menu'}
          className={ styles.button }
          icon={<FaClock/>}
          tooltip={t('procedureBox.actions.deadline.title')}
          size='big'
          disabled={!disabledDeadline}
          disabledText={t('procedureBox.actions.deadline.disabled')}
          onClick={() => openDeadline(undefined, getCurrentProcedure())}
        />
      </div>
    </Show>

    <div className={ styles.rightBorder }>
      <Show if={isVisible(box, 'addOrRemoveMarker')}>
        <RoundButton
          className={ styles.button }
          onClick={() => setOpen(!open)}
          icon={<FaTag/>}
          tooltip={t('procedureBox.actions.add_or_remove_marker.title')}
          disabled={disabled}
          disabledText={t('procedureBox.actions.add_or_remove_marker.disabled')}
          size='big'
        />
        <MarkerContent
          handleClose={() => setOpen(false)}
          isOpen={open}
          procedureId={proceduresSeleted[0]?.id}
          procedures={proceduresSeleted}
        />
      </Show>
      <RoundButton
        dataCy={'cypress-menu-button-notes'}
        className={ styles.button }
        onClick={() => openNotesModal()}
        icon={<FaStickyNote/>}
        tooltip={t('procedureBox.actions.notes.title')}
        disabled={disabled}
        disabledText={t('procedureBox.actions.notes.disabled')}
        size='big'
      />
      <Show if={isVisible(box, 'tramit')}>
        <RoundButton
          className={ styles.button }
          icon={<FaPaperPlane/>}
          tooltip={t('procedureBox.actions.tramit.title')}
          disabled={disabled}
          disabledText={t('procedureBox.actions.tramit.disabled')}
          size='big'
          onClick={() => openTramit(undefined)}
          dataCy={'cypress-menu-button-tramit'}
        />
      </Show>
      <Show if={(isVisible(box, 'distributeProcess') || isProcedureCollection(window.location.pathname)) && validateDistribute()}>
        <RoundButton
          className={ styles.button }
          icon={<FaSitemap/>}
          tooltip={t('procedureBox.actions.distributeProcesses.title')}
          disabled={disabled}
          disabledText={t('procedureBox.distributeProcesses.disabled')}
          size='big'
          dataCy={'cypress-distribute-process'}
          onClick={() => openDistributeProcesses(undefined)}
        />
      </Show>
      <Show if={isVisible(box, 'shareProcedure')}>
        <RoundButton
          className={ styles.button }
          icon={<FaShareAlt/>}
          tooltip={t('procedureBox.actions.shareProcedure.title')}
          size='big'
          onClick={() => openShareProcedure()}
        />
      </Show>
      <Show if={isVisible(box, 'forceTramit')}>
        <RoundButton
          className={ styles.button }
          icon={<FaHands/>}
          tooltip={t('procedureBox.actions.forceTramit.title')}
          disabled={disabled}
          disabledText={t('procedureBox.actions.forceTramit.disabled')}
          size='big'
          onClick={() => handleForceTramit()}
        />
      </Show>
      <Show if={isVisible(box, 'prepareDocument')}>
        <RoundButton
            className={ styles.button }
            icon={<FaFileAlt/>}
            tooltip={t('procedureBox.actions.prepareDocument.title')}
            disabled={disabled}
            disabledText={t('procedureBox.actions.prepareDocument.disabled')}
            size='big'
            onClick={() => openCreateDocument(undefined)}
        />
      </Show>
      <Show if={isVisible(box, 'attachFiles')}>
        <RoundButton
          className={ styles.button }
          icon={<FaPaperclip/>}
          tooltip={t('procedureBox.actions.attachFiles.title')}
          disabled={disabled}
          disabledText={t('procedureBox.actions.attachFiles.disabled')}
          size='big'
          onClick={() => openAttachFile()}
        />
      </Show>
      <Show if={isVisible(box, 'signDocument')}>
        <RectangularButton
          options={signer}
          text={'signer'}
          size='micro'
          iconSize='iconMedium'
          icon={<FaPenNib/>}
        />
      </Show>
      <RectangularButton
        options={attach}
        text={'attach'}
        size='micro'
        icon={<FaLink/>}
        iconSize='iconMedium'
      />
      <Show if={isVisible(box, 'removeDocument')}>
        <RoundButton
          className={ styles.button }
          icon={<FaTrashAlt/>}
          tooltip={t('procedureBox.actions.removeDocument.title')}
          disabled={disabled}
          disabledText={t('procedureBox.actions.removeDocument.disabled')}
          size='big'
          onClick={() => openDeleteFile(undefined)}
        />
      </Show>
      <Show if={isVisible(box, 'unArchive')}>
        <RoundButton
          className={ styles.button }
          icon={<FaBoxOpen/>}
          tooltip={t('procedureBox.actions.unArchive.title')}
          disabled={disabled}
          disabledText={t('procedureBox.actions.unArchive.disabled')}
          size='big'
          onClick={() => openUnarchiveModal()}
        />
      </Show>
      <Show if={isVisible(box, 'archive')}>
        <RoundButton
          className={ styles.button }
          icon={<FaArchive/>}
          tooltip={t('procedureBox.actions.archive.title_procedure')}
          disabled={disabled}
          disabledText={t('procedureBox.actions.archive.disabled')}
          size='big'
          onClick={openArchiveProcesses}
        />
      </Show>
      <Show if={isVisible(box, 'delete')}>
        <RoundButton
          dataCy={'button-delete-procedure'}
          className={ styles.button }
          icon={<FaTimes/>}
          tooltip={t('procedureBox.actions.delete.title_procedure')}
          disabled={proceduresSeleted.length > 1}
          disabledText={t('procedureBox.actions.delete.disabled')}
          size='big'
          onClick={openDeleteProcesses}
        />
      </Show>
      <Show if={isVisible(box, 'joinDocuments')}>
        <RoundButton
          className={ styles.button }
          icon={<FaFolder/>}
          tooltip={t('procedureBox.actions.joinDocuments.title')}
          disabled={disabled}
          disabledText={t('procedureBox.actions.joinDocuments.disabled')}
          size='big'
          onClick={() => openJoinDocuments(undefined)}
        />
      </Show>
      <Show if={isVisible(box, 'attachCitationIntimation') && proceduresSeleted.length === 1}>
        <RoundButton
          className={ styles.button }
          icon={<LinkIcon/>}
          tooltip={t('procedureBox.actions.attachCitationIntimation.title')}
          disabled={disabled}
          size='big'
          onClick={() => openAttachProcedureCitationIntimation()}
        />
      </Show>
    </div>

    <div className={ styles.rightBorder }>
      <Show if={isVisible(box, 'editProcedure')}>
        <RoundButton
          className={ styles.button }
          icon={<FaShoePrints/>}
          tooltip={t('procedureBox.actions.editProcedure.title')}
          disabled={disabled}
          disabledText={t('procedureBox.actions.editProcedure.disabled')}
          size='big'
          dataCy={'cypress-button-edit-procedure'}
          onClick={() => openEditProcedure()}
        />
      </Show>
      <Show if={isVisible(box, 'changeFlux')}>
        <RoundButton
          className={ styles.button }
          icon={<ImShuffle className={styles.resizer}/>}
          tooltip={t('procedureBox.actions.changeFlux.title')}
          disabled={disabled}
          disabledText={t('procedureBox.actions.changeFlux.disabled')}
          size='big'
          onClick={() => openChangeFlux()}
        />
      </Show>
      <Show if={(checkProceduresFluxes() && isVisible(box, 'attachExtract'))}>
        <RoundButton
          className={ styles.button }
          icon={<FaFileInvoiceDollar/>}
          tooltip={'Anexar extrato da dívida'}
          disabled={disabled}
          disabledText={t('procedureBox.actions.archive.disabled')}
          size='big'
          onClick={handleAttachExtract}
        />
      </Show>
      <Show if={isVisible(box, 'createSubprocess')}>
        <SubProcedure
          className={ styles.button }
          responsive={false}
          onlyRequestCalculation={judicialFluxes.slice(0, -1).includes(props.procedure?.flux.code || proceduresSeleted[0].flux.code)}
          disabled={proceduresSeleted.length > 1
            || (props.procedure !== undefined
              ? !judicialFluxes.includes(props.procedure?.flux.code)
              : !judicialFluxes.includes(proceduresSeleted[0].flux.code)
            )
          }
        />
      </Show>
    </div>

    <div className={ styles.btnBlock }>
      <Show if={isVisible(box, 'court')}>
        <RectangularButton
          dataCy={'cypress-court-option'}
          options={court}
          text={'court'}
          size='medium'
        />
      </Show>
      <Show if={isVisible(box, 'spu')}>
        <RectangularButton
          options={spu}
          text={'spu'}
          size='medium'
          disabled={proceduresSeleted.length > 1}
          tooltip={t('procedureBox.actions.spu.disabledButton')}
        />
      </Show>
      <Show if={isVisible(box, 'ipm')}>
        <RectangularButton
          options={ipm}
          text={'ipm'}
          size='medium'
        />
      </Show>
    </div>
  </>
  );
}
