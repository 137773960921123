import { Grid } from '@mui/material';
import { useEffect, useState } from 'react';
import { t } from 'i18next';
import { Controller, useForm } from 'react-hook-form';
import styles from './styles.module.scss';
import Select from '../../Select';
import ProcedureBoxService from '../../../services/procedureBoxService';
import { OptionType, TramitRequest } from '../../../@types/config';
import { Individual } from '../../../@types/model';
import { Button } from '../../Button';
import { parseField, parseIndividual, parseInstitution } from '../../../helpers/selectOption';
import { InputText } from '../../InputText';
import { Header } from '../../Header';
import Show from '../../Show';

const defaultInstitution = { label: 'Procuradoria Geral do Município de Fortaleza', value: 1 };
const emptyValue = { label: '', value: null };

type FormProps = {
  suggestion?: Individual;
  tramitForm?: TramitRequest;
  onChange: () => void;
  handleConfirm: (individual: TramitRequest | undefined) => void;
  isCitationIntimationForm?: boolean;
};

export function Form(props: FormProps) {
  const { control, handleSubmit, setValue } = useForm();

  const [institutions, setInstitutions] = useState([]);
  const [institution, setInstitution] = useState<OptionType>(parseInstitution(props.tramitForm));
  const [fields, setFields] = useState([]);
  const [fieldTramit, setFieldTramit] = useState<OptionType>(parseField(props.tramitForm));
  const [individuals, setIndividuals] = useState([]);
  const [individual, setIndividual] = useState<OptionType>(parseIndividual(props.tramitForm));
  const loadInstituitions = () => {
    ProcedureBoxService.getInstitutions().then((e) => {
      setInstitutions(e.map((i: any) => { return { label: i.name, value: i.id }; }));
    });
  };

  const loadFields = () => {
    if (institution) {
      const institutionId = props.suggestion ? props.suggestion.institution_id : institution.value;
      setFields([]);
      setIndividuals([]);
      ProcedureBoxService.getFieldsByInstitution(institutionId).then((e) => {
        setFields(e.map((i: any) => { return { label: i.name, value: i.id }; }));
      });
    }
  };

  const loadIndividuals = () => {
    if (fieldTramit) {
      setIndividuals([]);
      ProcedureBoxService.getIndividualsByMainFieldTramit(fieldTramit.value).then((e) => {
        setIndividuals(e.map((i: any) => { return { label: i.name, value: i.id }; }));
      });
    }
  };

  useEffect(() => {
    loadInstituitions();
    setInstitution(defaultInstitution);

    if (props.tramitForm !== undefined) {
      setValue('observation', props.tramitForm.observation);
    }
  }, []);

  useEffect(() => {
    setFieldTramit(emptyValue);
    setIndividual(emptyValue);
    loadFields();
  }, [institution, props.suggestion]);

  useEffect(() => {
    setIndividual(emptyValue);
    loadIndividuals();
  }, [fieldTramit]);

  const handleInstitution = (value: OptionType | null) => {
    if (value) {
      props.onChange();
      setInstitution(value);
    }
  };

  const handleField = (value: OptionType | null) => {
    if (value) {
      props.onChange();
      setFieldTramit(value);
    }
  };

  const handleIndividual = (value: OptionType | null) => {
    if (value) {
      props.onChange();
      setIndividual(value);
    }
  };

  const mountRequest = (data: any) => {
    let tramitRequest: TramitRequest | undefined;

    const { observation } = data;

    if (props.suggestion) {
      tramitRequest = { individual: props.suggestion, observation };
    }

    if (individual !== emptyValue && fieldTramit !== emptyValue && institution !== emptyValue) {
      tramitRequest = {
        individual: {
          field_id: parseInt(fieldTramit.value, 10),
          field_name: fieldTramit.label,
          individual_id: parseInt(individual.value, 10),
          individual_name: individual.label,
          institution_id: parseInt(institution.value, 10),
          institution_name: institution.label,
        },
        observation,
      };
    }

    if (tramitRequest) {
      props.handleConfirm(tramitRequest);
      setValue('observation', tramitRequest.observation);
    }
  };

  const tramitCitationIntimation = (data: any) => {
    const { observation } = data;
    const tramitRequest = {
      individual: {
        field_id: parseInt(fieldTramit.value, 10),
        field_name: fieldTramit.label,
      },
      observation,
    };
    props.handleConfirm(tramitRequest as TramitRequest);
  };

  return (
    <form className={ styles.container } onSubmit={props.isCitationIntimationForm ? handleSubmit(tramitCitationIntimation) : handleSubmit(mountRequest)}>
      <div className={styles.header}>
        <Header
          text={t('procedureBox.actions.tramit.modal.form.title')}
        />
      </div>

      <Grid
        container
        columns={{ xs: 1, sm: 3, md: 3 }}
        className={styles.content}
      >
        <Grid
          item
          xs={1}
          sm={1}
          md={1}
          className={styles.select}
        >
          <Select
            options={institutions}
            label={t('procedureBox.actions.tramit.modal.form.institution')}
            placeholder={t('procedureBox.actions.tramit.modal.form.institutionPlaceholder')}
            value={props.suggestion ? { value: props.suggestion.institution_id, label: props.suggestion.institution_name } : institution}
            defaultValue={defaultInstitution}
            onChange={handleInstitution}
            required
          />
        </Grid>

        <Grid
          item
          xs={1}
          sm={1}
          md={!props.isCitationIntimationForm ? 1 : 2}
          className={styles.select}
        >
          <Select
            options={fields}
            label={t('procedureBox.actions.tramit.modal.form.field')}
            placeholder={t('procedureBox.actions.tramit.modal.form.fieldPlaceholder')}
            value={props.suggestion ? { value: props.suggestion.field_id, label: props.suggestion.field_name } : fieldTramit}
            onChange={handleField}
            required
          />
        </Grid>

        <Show if={!props.isCitationIntimationForm}>
          <Grid
            item
            xs={1}
            sm={1}
            md={1}
            className={styles.select}
          >
            <Select
              options={individuals}
              label={t('procedureBox.actions.tramit.modal.form.individual')}
              placeholder={t('procedureBox.actions.tramit.modal.form.individualPlaceholder')}
              value={props.suggestion ? { value: props.suggestion.individual_id, label: props.suggestion.individual_name } : individual}
              onChange={handleIndividual}
              disabled={fieldTramit.label.length === 0}
              required
            />
          </Grid>
        </Show>

        <Grid
          item
          xs={1}
          sm={3}
          md={3}
          className={styles.observation}
        >
          <Controller
            control={control}
            name={'observation'}
            render={({ field }) => (
              <InputText
                {...field}
                label={t('procedureBox.actions.tramit.modal.form.observation')}
                placeholder={t('procedureBox.actions.tramit.modal.form.observationPlaceholder')}
              />
            )}
          />
        </Grid>

        <Grid
          item
          xs={1}
          sm={3}
          md={3}
          className={styles.submit}
        >
          <Button
            dataCy={'cypress-button-submit-tramit'}
            title={t('procedureBox.actions.tramit.modal.form.submit')}
            type='submit'
            textCenter
            round
            buttonType='primary'
            size='flat'
            disabled={
              props.isCitationIntimationForm
                ? !(institution !== emptyValue && fieldTramit !== emptyValue)
                : !((props.suggestion || (individual !== emptyValue && institution !== emptyValue && fieldTramit !== emptyValue)))}
          />
        </Grid>
      </Grid>
    </form>
  );
}
