import { ArchiveAlert } from './ArchiveAlert';
import { DeleteDocumentAlert } from './DeleteDocumentAlert';
import { AttachDocumentAlert } from './AttachDocumentAlert';
import { SignerAlert } from './SignerAlert';
import { CosignerAlert } from './CosignerAlert';
import styles from './styles.module.scss';
import { TramitAlert } from './TramitAlert';
import { JoinDocumentAlert } from './JoinDocumentAlert';
import { ForceTramitAlert } from './ForceTramitAlert';
import { UnarchiveAlert } from './UnarchiveAlert';
import { PrepareDocumentAlert } from './PrepareDocumentAlert';
import { JudicialOficioAlert } from './JudicialOficioAlert';
import { ForwardIPMAlert } from './ForwardIPMAlert';
import { CreateDocumentWaiting } from './Waiting/CreateDocumentWaiting';
import { AttachDocumentWaiting } from './Waiting/AttachDocumentWaiting';
import { SendPetitionInitial } from './SendPetitionInitial';
import { SpuAlert } from './SpuAlert';
import { RegistrationSpuAlert } from './RegistrationSpuAlert';
import { SendPetitionIntermediate } from './SendPetitionIntermediate';
import { AttachExtractAlert } from './AttachExtractAlert';
import { ChangeFluxAlert } from './ChangeFluxAlert';
import { SendOrderPayment } from './SendOrderPayment';
import { RequestCalculate } from './RequestCalculate';
import { AttachProcedureAlert } from './AttachProcedureAlert';
import { DistributionAlert } from './DistributionAlert';
import { ShareAlert } from './ShareAlert';
import { UnattachProcedureAlert } from './UnattachProcedureAlert';

export function LoadingBottomAlerts() {
  return (
    <div className={ styles.container }>
      <ArchiveAlert />
      <DeleteDocumentAlert />
      <SignerAlert />
      <CosignerAlert />
      <TramitAlert />
      <ForceTramitAlert />
      <UnarchiveAlert />
      <DistributionAlert />
      <AttachDocumentAlert />
      <AttachExtractAlert />
      <AttachProcedureAlert />
      <UnattachProcedureAlert />
      <ChangeFluxAlert />
      <JoinDocumentAlert />
      <PrepareDocumentAlert />
      <ForwardIPMAlert />
      <SendPetitionInitial />
      <SendPetitionIntermediate />
      <JudicialOficioAlert />
      <SpuAlert />
      <RegistrationSpuAlert />
      <CreateDocumentWaiting />
      <AttachDocumentWaiting />
      <SendOrderPayment />
      <RequestCalculate />
      <ShareAlert />
    </div>
  );
}
