import { useContext, useState } from 'react';
import { Trans } from 'react-i18next';
import { t } from 'i18next';
import { Batch, BatchType, MultipleActionStep } from '../../@types/backgroundActions';
import { AttachDocument as AttachDocumentType, AttachedDocumentResquet } from '../../@types/config';
import { BackgroundActionsContext } from '../../context/backgroundActions';
import { ProcedureActionsContext } from '../../context/procedureActionsContext';
import { SelectedProcedures } from '../SelectedProcedures';
import { Form } from './Form';
import ProcedureBoxService from '../../services/procedureBoxService';

type AttachDocumentProps = {
  closeModal: () => void;
  documents?: AttachDocumentType[];
  waiting?: boolean;
  joinDocuments?: boolean;
  tramit?: boolean;
  multipleActions?: MultipleActionStep;
  reload?: boolean;
};

export function AttachDocument(props: AttachDocumentProps) {
  const [documents, setDocuments] = useState<AttachDocumentType[]>(props.documents ?? []);
  const { addBatch } = useContext(BackgroundActionsContext);
  const {
    proceduresSeleted,
    removeProcedures,
    setAlert,
    citationIntimationSelected,
    setCitationIntimation,
    addToasts,
    setModal,
  } = useContext(ProcedureActionsContext);

  const handleConfirm = (type: BatchType) => {
    props.closeModal();
    const procedures = proceduresSeleted;
    const id = (Math.random() + 1).toString(36).substring(1);
    const files = documents.map((doc) => { return { archive: doc.file, status: doc.type, signed: doc.sign }; });
    const body = {
      files,
      documents,
      in_bulk: procedures.length > 1,
      waiting: props.waiting,
      tramit: props.tramit,
    };
    const batch: Batch = {
      id,
      procedures,
      type,
      count: procedures.length,
      successList: [],
      failedList: [],
      body,
      multipleActions: props.multipleActions,
      reload: Boolean(props.reload),
    };
    addBatch(batch);
    if (!props.multipleActions) {
      removeProcedures(procedures);
    }
  };

  const handleConfirmCitationIntimation = () => {
    const files = documents.map((doc) => { return { archive: doc.file, status: doc.type, signed: doc.sign }; });

    if (citationIntimationSelected) {
      const data: AttachedDocumentResquet = {
        id: citationIntimationSelected.id,
        in_bulk: true,
        attach_documents: files as any,
      };

      setAlert({
        visible: true,
        type: 'warning',
        title: t('procedureBox.actions.citation_intimation.attach_file.title'),
        text: t('procedureBox.actions.citation_intimation.attach_file.text'),
        confirmText: t('general.yes'),
        confirmType: 'primary',
        cancelText: t('general.no'),
        cancelType: 'default',
        handleConfirm: () => {
          setAlert({
            visible: true,
            title: t('procedureBox.actions.citation_intimation.attach_file.load.title'),
            text: t('procedureBox.actions.citation_intimation.attach_file.load.text'),
            type: 'loading',
          });
          ProcedureBoxService.attachFilesCitationIntimation(data).then((r) => {
            setAlert(undefined);
            setCitationIntimation(undefined);
            addToasts({
              type: 'success',
              text: t('procedureBox.actions.citation_intimation.attach_file.toast'),
            });
            setModal(undefined);
          });
        },
      });
    }
  };

  const handleJoinDocuments = () => {
    props.closeModal();
    setAlert();
    handleConfirm('joinDocuments');
  };

  const renderTitle = () => {
    if (proceduresSeleted.length > 1) {
      return (
        <Trans
          i18nKey='procedureBox.actions.joinDocuments.modal.alertConfirmation.titleManyProcedures'
          components={{ bold: <strong /> }}
          values={{ number: proceduresSeleted.length, count: documents.length }}
        />
      );
    }
    return (
      <Trans
        i18nKey='procedureBox.actions.joinDocuments.modal.alertConfirmation.title'
        components={{ bold: <strong /> }}
        values={{ number: proceduresSeleted[0].process_number, count: documents.length }}
      />
    );
  };

  const renderText = () => {
    if (proceduresSeleted.length > 1) {
      return (
        <Trans
          i18nKey='procedureBox.actions.joinDocuments.modal.alertConfirmation.textManyProcedures'
          components={{ bold: <strong /> }}
          values={{ number: proceduresSeleted.length, count: documents.length }}
        />
      );
    }
    return (
      <Trans
        i18nKey='procedureBox.actions.joinDocuments.modal.alertConfirmation.text'
        components={{ bold: <strong /> }}
        values={{ number: proceduresSeleted[0].process_number, count: documents.length }}
      />
    );
  };

  const showAlert = () => {
    setAlert({
      visible: true,
      handleConfirm: () => handleJoinDocuments(),
      title: renderTitle(),
      text: renderText(),
    });
  };

  const handleForm = () => {
    if (props.joinDocuments) {
      showAlert();
    } else if (citationIntimationSelected?.id) {
      handleConfirmCitationIntimation();
    } else {
      handleConfirm('attachFiles');
    }
  };

  return (
    <div>
      <SelectedProcedures />
      <Form
        onChange={(val) => setDocuments(val)}
        handleConfirm={handleForm}
        files={documents}
        joinDocuments={props.joinDocuments}
      />
    </div>
  );
}
