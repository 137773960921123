import { useContext, useEffect, useState } from 'react';
import moment from 'moment';
import { useTranslation, Trans } from 'react-i18next';
import { Grid } from '@mui/material';
import { GroupFilter } from './GroupFilter';
import { OptionType, SelectFilter } from '../../SelectFilter';
import Select from '../../Select';
import { ProcedureMarkersContext } from '../../../context/procedureMarkersContext';
import {
  getPendingKey, getPriorityKey, getMarkerLabel, getMarkerColor,
} from '../../../helpers/markers';
import styles from './styles.module.scss';
import ProcedureBoxService from '../../../services/procedureBoxService';
import { LastStep, Flux, Field } from '../../../@types/advancedFilters';
import { FilterButtons } from './FilterButtons';
import { QueryContext } from '../../../context/queryContext';
import { AutoCompleteRemote } from './AutoComplete';
import DashboardService from '../../../services/dashboardService';
import { EitherFilter } from './EitherFilter';
import { RoundFilter } from './RoundFilter';
import { DateTimePicker } from '../../DateTimePicker';
import { alphabeticalOrder } from '../../../helpers/alphabeticalOrder';

type FilterAdvancedProps = {
  isOpen: boolean;
  closeFilter: () => void;
};

export function FilterAdvanced(props: FilterAdvancedProps) {
  const { updateAdvancedFilter } = useContext(QueryContext);
  const { availableMarkers } = useContext(ProcedureMarkersContext);
  const { t } = useTranslation();
  const [lastSteps, setLastSteps] = useState<OptionType[]>([]);
  const [fluxes, setFluxes] = useState<OptionType[]>([]);
  const [fields, setFields] = useState<OptionType[]>([]);
  const [subjectList, setSubjectList] = useState<OptionType[]>([]);
  const loadLastSteps = (lastStepsTemp: LastStep[]) => {
    const lastStepsList = lastStepsTemp.map((option: LastStep) => {
      const obj = { label: option.product_generated, value: option.progresses_step_id };
      return obj;
    });
    setLastSteps(lastStepsList);
  };

  const loadFluxes = (fluxesTemp: Flux[]) => {
    const fluxList = fluxesTemp.map((option: Flux) => {
      const obj = { label: option.name, value: option.flux_id };
      return obj;
    });
    setFluxes(fluxList);
  };

  const loadFields = (fieldsTemp: Field[]) => {
    const fluxList = fieldsTemp.map((option: Field) => {
      const obj = { label: option.name, value: option.field_id };
      return obj;
    });
    setFields(fluxList);
  };

  const loadSubjects = (subjects: []) => {
    const subjectsListOrder = alphabeticalOrder(subjects);
    const subjectListOptions = subjectsListOrder.map((subject) => {
      const obj = { label: subject, value: Math.floor(Math.random() * 3000) };

      return obj;
    });

    setSubjectList(subjectListOptions);
  };

  const loadAdvancedFilter = async () => {
    const advancedFilters = await ProcedureBoxService.getProcedureAdvancedFilters();
    loadLastSteps(advancedFilters.data.last_steps);
    loadFluxes(advancedFilters.data.fluxes);
    loadFields(advancedFilters.data.fields);
    loadSubjects(advancedFilters.data.subject_generals);
  };

  const mountTimeBoxOption = (lessThan: boolean, id :string, time :number, period :any) => {
    let valueObj = {};
    if (period) {
      const date = moment().subtract(time, period).calendar();
      if (lessThan) {
        valueObj = { individual_update_gteq: date };
      } else {
        valueObj = { individual_update_lteq: date };
      }
    }
    const obj = { label: t(`procedureBox.filterAdvanced.timeBox.select.${id}`), value: valueObj };
    return obj;
  };

  const loadTimeBox = [
    { label: t('procedureBox.filterAdvanced.timeBox.select.irrelevant'), value: { individual_update_gteq: [], individual_update_lteq: [] } },
    mountTimeBoxOption(true, 'less_than_one_month', 1, 'months'),
    mountTimeBoxOption(false, 'more_than_one_month', 1, 'months'),
    mountTimeBoxOption(false, 'more_than_one_year', 1, 'years'),
    mountTimeBoxOption(false, 'more_than_two_years', 2, 'years'),
    mountTimeBoxOption(false, 'more_than_three_years', 3, 'years'),
  ];

  const loadProcedureValue = [
    { label: t('procedureBox.filterAdvanced.procedureValue.placeholder'), value: { procedure_value_gteq: [], procedure_value_lteq: [] } },
    { label: t('procedureBox.filterAdvanced.procedureValue.select.option1'), value: { procedure_value_gteq: [], procedure_value_lteq: '6000' } },
    { label: t('procedureBox.filterAdvanced.procedureValue.select.option2'), value: { procedure_value_gteq: '6000', procedure_value_lteq: '30000' } },
    { label: t('procedureBox.filterAdvanced.procedureValue.select.option3'), value: { procedure_value_gteq: '30000', procedure_value_lteq: '50000' } },
    { label: t('procedureBox.filterAdvanced.procedureValue.select.option4'), value: { procedure_value_gteq: '50000', procedure_value_lteq: [] } },
  ];

  const statusProcedure = [
    { label: t('procedureBox.filterAdvanced.statusProcedure.placeholder'), value: [] },
    { label: t('procedureBox.filterAdvanced.statusProcedure.progress'), value: [0, 1] },
    { label: t('procedureBox.filterAdvanced.statusProcedure.finished'), value: [2] },
    { label: t('procedureBox.filterAdvanced.statusProcedure.archived'), value: [4] },
  ];

  const priorityMarkers = availableMarkers.priority_markers.map((marker) => { return { label: getMarkerLabel(marker), value: getPriorityKey(marker.marker_priority) }; });
  const priorityMarkersColors = availableMarkers.priority_markers.map((marker) => { return { value: getPriorityKey(marker.marker_priority), color: getMarkerColor(marker) }; });

  const pendenciesMarkers = availableMarkers.pending_markers.map((marker) => { return { label: getMarkerLabel(marker), value: getPendingKey(marker.marker_pending) }; });
  const pendenciesMarkersColors = availableMarkers.pending_markers.map((marker) => { return { value: getPendingKey(marker.marker_pending), color: getMarkerColor(marker) }; });

  const publicCustom = availableMarkers.public_markers.map((marker) => { return { label: getMarkerLabel(marker), value: marker.id }; });
  const publicCustomColors = availableMarkers.public_markers.map((marker) => { return { value: marker.id, color: getMarkerColor(marker) }; });

  const privateCustom = availableMarkers.private_markers.map((marker) => { return { label: getMarkerLabel(marker), value: marker.id }; });
  const privateCustomColors = availableMarkers.private_markers.map((marker) => { return { value: marker.id, color: getMarkerColor(marker) }; });

  useEffect(() => {
    loadAdvancedFilter();
  }, []);

  return (
    <div className={`${styles.container} ${!props.isOpen ? styles.openFilterAdvanced : ''}`}>
      <h3 className={styles.title}>
        <Trans
          i18nKey='procedureBox.filterAdvanced.title'
          components={{ bold: <strong /> }}
        />
      </h3>
      <GroupFilter subtitle={t('procedureBox.filterAdvanced.data')} >
        <Grid
          spacing={8}
          container
          columns={{ xs: 1, sm: 10, md: 12 }}
        >
          <Grid
            item
            className={ styles.filterItem }
            xs={ 12 }
            md={ 4 }
            sm={ 4 }
          >
            <SelectFilter
              id='step_id_in'
              label={t('procedureBox.filterAdvanced.lastSteps.title')}
              options={lastSteps}
              placeholder={t('procedureBox.filterAdvanced.lastSteps.placeholder')}
              tooltip={t('procedureBox.filterAdvanced.tooltips.last_step')} />
          </Grid>
          <Grid
            item
            className={ styles.filterItem }
            xs={ 12 }
            md={ 4 }
            sm={ 4 }
          >
            <SelectFilter
              id='flux_id_in'
              label={t('procedureBox.filterAdvanced.fluxes.title')}
              options={fluxes}
              placeholder={t('procedureBox.filterAdvanced.fluxes.placeholder')}
              tooltip={t('procedureBox.filterAdvanced.tooltips.flux')} />
          </Grid>
          <Grid
            item
            className={ styles.filterItem }
            xs={ 12 }
            md={ 4 }
            sm={ 4 }
          >
            <AutoCompleteRemote
              id='procedure_numbers_num_cont'
              title='Processos'
              placeholder={t('dashboard.digitalProcesses.productivityChart.placeholderProcess')}
              tooltip={t('procedureBox.filterAdvanced.tooltips.procedure')}
              getUrl={DashboardService.getProcedures}/>
          </Grid>
        </Grid>

        <Grid
          spacing={8}
          container
          columns={{ xs: 1, sm: 10, md: 12 }}
          className={ styles.filterContainer }
          >
          <Grid
            item
            className={ styles.filterItem }
            xs={ 12 }
            md={ 4 }
            sm={ 4 }
          >
            <AutoCompleteRemote
              id='interested_part_procedures_name_cont'
              title='Parte Interessada'
              tooltip={t('procedureBox.filterAdvanced.tooltips.interested_part')}
              placeholder='Digite o nome'
              getUrl={DashboardService.getInterestedPartFilter}/>
          </Grid>
          <Grid
            item
            className={ styles.filterItem }
            xs={ 12 }
            md={ 4 }
            sm={ 4 }
          >
            <SelectFilter
              id='individual_main_field_id_in'
              label={t('procedureBox.filterAdvanced.fields.title')}
              options={fields}
              placeholder={t('procedureBox.filterAdvanced.fields.placeholder')}
              tooltip={t('procedureBox.filterAdvanced.tooltips.field')} />
          </Grid>
          <Grid
            item
            className={ styles.filterItem }
            xs={ 12 }
            md={ 4 }
            sm={ 4 }
          >
            <RoundFilter
              id='interested_part_procedures_participation_id_in'
              title={t('procedureBox.filterAdvanced.municipalPart.title')}
              tooltip={<Trans
                i18nKey='procedureBox.filterAdvanced.tooltips.municipal_part'
                components={{ bold: <strong />, br: <br /> }}
              />}

              />
          </Grid>
        </Grid>

        <Grid
          spacing={8}
          container
          columns={{ xs: 1, sm: 10, md: 12 }}
          className={ styles.filterContainer }
          >
          <Grid
            item
            className={ styles.filterItem }
            xs={ 12 }
            md={ 4 }
            sm={ 4 }
          >
            <Select
              onChange={(value) => (value !== null ? (updateAdvancedFilter('', '', value.value)) : () => {})}
              label={t('procedureBox.filterAdvanced.procedureValue.title')}
              tooltip={t('procedureBox.filterAdvanced.tooltips.value')}
              defaultValue={loadProcedureValue[0]}
              options={loadProcedureValue} />
          </Grid>
          <Grid
            item
            className={ styles.filterItem }
            xs={ 12 }
            md={ 4 }
            sm={ 4 }
          >
            <AutoCompleteRemote
              id='individual_name_cont'
              title={t('procedureBox.filterAdvanced.responsible.title')}
              placeholder={t('procedureBox.filterAdvanced.responsible.placeholder')}
              tooltip={t('procedureBox.filterAdvanced.tooltips.individual')}
              getUrl={DashboardService.getIndividualsFilter}/>
          </Grid>
          <Grid
            item
            className={ styles.filterItem }
            xs={ 12 }
            md={ 4 }
            sm={ 4 }
          >
            <EitherFilter
              id='cda_procedures_procedure_id_not_null'
              title={t('procedureBox.filterAdvanced.hasCdas.title')}
              tooltip={t('procedureBox.filterAdvanced.tooltips.linked_cdas')}
              />
          </Grid>
        </Grid>

        <Grid
          spacing={8}
          container
          className={ styles.filterContainer }
          columns={{ xs: 1, sm: 10, md: 12 }}
        >
          <Grid
            item
            className={ styles.filterItem }
            xs={ 12 }
            md={ 4 }
            sm={ 4 }
          >
            <Select
              onChange={(value) => (value !== null ? (updateAdvancedFilter('status_in', value.value)) : () => {})}
              label={t('procedureBox.filterAdvanced.statusProcedure.title')}
              tooltip={t('procedureBox.filterAdvanced.tooltips.situation')}
              defaultValue={statusProcedure[0]}
              options={statusProcedure} />
          </Grid>

          <Grid
            item
            className={ styles.filterItem }
            xs={ 12 }
            md={ 4 }
            sm={ 4 }
          >
            <AutoCompleteRemote
              id='interested_part_document_cont'
              title={t('procedureBox.filterAdvanced.interestedPartDocument.title')}
              placeholder={t('procedureBox.filterAdvanced.interestedPartDocument.placeholder')}
              tooltip={t('procedureBox.filterAdvanced.tooltips.interestedPartDocument')} />
          </Grid>
          <Grid
            item
            className={ styles.filterItem }
            xs={ 12 }
            md={ 3 }
            sm={ 4 }
          >
            <EitherFilter
              id='was_seen_true'
              title={t('procedureBox.filterAdvanced.wasSeen.title')}
              tooltip={t('procedureBox.filterAdvanced.tooltips.wasSeen')}
              />
          </Grid>
        </Grid>

        <Grid
          spacing={8}
          container
          className={ styles.filterContainer }
          columns={{ xs: 1, sm: 10, md: 12 }}
        >
          <Grid
            item
            className={ styles.filterItem }
            xs={ 12 }
            md={ 4 }
            sm={ 4 }
          >
            <SelectFilter
              id='subject_general_eq'
              label={t('procedureBox.filterAdvanced.subjects.title')}
              options={subjectList}
              placeholder={t('procedureBox.filterAdvanced.subjects.placeholder')}
              tooltip=''
              subject />
          </Grid>
        </Grid>
      </GroupFilter>
      <GroupFilter subtitle={t('procedureBox.filterAdvanced.markers.title')} >
        <Grid
          spacing={8}
          container
          columns={{ xs: 1, sm: 10, md: 12 }}
        >
          <Grid
            item
            className={ styles.filterItem }
            xs={ 12 }
            md={ 3 }
            sm={ 4 }
          >
            <SelectFilter
              id='marker_priority_in'
              label={t('procedureBox.filterAdvanced.markers.priority.title')}
              tooltip={t('procedureBox.filterAdvanced.markers.priority.tooltip')}
              options={priorityMarkers}
              markersColors={priorityMarkersColors}
              placeholder={t('procedureBox.filterAdvanced.markers.irrelevant')} />
          </Grid>
          <Grid
            item
            className={ styles.filterItem }
            xs={ 12 }
            md={ 3 }
            sm={ 4 }
          >
            <SelectFilter
              id='marker_pending_in'
              label={t('procedureBox.filterAdvanced.markers.pendencies.title')}
              tooltip={t('procedureBox.filterAdvanced.markers.pendencies.tooltip')}
              options={pendenciesMarkers}
              markersColors={pendenciesMarkersColors}
              placeholder={t('procedureBox.filterAdvanced.markers.irrelevant')} />
          </Grid>
          <Grid
            item
            className={ styles.filterItem }
            xs={ 12 }
            md={ 3 }
            sm={ 4 }
          >
            <SelectFilter
              id='markers_id_in'
              label={t('procedureBox.filterAdvanced.markers.public_custom.title')}
              tooltip={t('procedureBox.filterAdvanced.markers.public_custom.tooltip')}
              options={publicCustom}
              markersColors={publicCustomColors}
              placeholder={t('procedureBox.filterAdvanced.markers.irrelevant')} />
          </Grid>
          <Grid
            item
            className={ styles.filterItem }
            xs={ 12 }
            md={ 3 }
            sm={ 4 }
          >
            <SelectFilter
              id='markers_id_in'
              label={t('procedureBox.filterAdvanced.markers.private_custom.title')}
              tooltip={t('procedureBox.filterAdvanced.markers.private_custom.tooltip')}
              options={privateCustom}
              markersColors={privateCustomColors}
              placeholder={t('procedureBox.filterAdvanced.markers.irrelevant')} />
          </Grid>
        </Grid>
      </GroupFilter>
      <GroupFilter subtitle={t('procedureBox.filterAdvanced.schedule')} >
        <Grid
          spacing={8}
          container
          columns={{ xs: 1, sm: 10, md: 12 }}
        >
          <Grid
            item
            className={ styles.filterItem }
            xs={ 12 }
            md={ 3 }
            sm={ 4 }
          >
            <Select
              onChange={(value) => (value !== null ? (updateAdvancedFilter('', '', value.value)) : () => {})}
              label={t('procedureBox.filterAdvanced.timeBox.title')}
              tooltip={t('procedureBox.filterAdvanced.tooltips.timebox')}
              defaultValue={loadTimeBox[0]}
              options={loadTimeBox} />
          </Grid>
          <Grid
            item
            className={ styles.filterItem }
            xs={ 12 }
            md={ 3 }
            sm={ 4 }
          >
            <DateTimePicker
              label={t('procedureBox.filterAdvanced.procedureCreatedAt.title')}
              placeholder={t('procedureBox.filterAdvanced.procedureCreatedAt.placeholder')}
              tooltip={t('procedureBox.filterAdvanced.tooltips.procedureCreatedAt')}
              filterKey='created_at'
              />
          </Grid>
          <Grid
            item
            className={ styles.filterItem }
            xs={ 12 }
            md={ 3 }
            sm={ 4 }
          >
            <EitherFilter
              id='procedure_deadline_deadline_present'
              title={t('procedureBox.filterAdvanced.timeBox.deadline')}
              tooltip={t('procedureBox.filterAdvanced.tooltips.deadline')}
              />
          </Grid>
        </Grid>
      </GroupFilter>
      <GroupFilter subtitle={t('procedureBox.filterAdvanced.details.title')} >
        <Grid
          spacing={8}
          container
          columns={{ xs: 1, sm: 10, md: 12 }}
        >
          <Grid
            item
            className={ styles.filterItem }
            xs={ 12 }
            md={ 3 }
            sm={ 4 }
          >
            <EitherFilter
              id='procedure_notes_note_present'
              title={t('procedureBox.filterAdvanced.details.notes')}
              tooltip={t('procedureBox.filterAdvanced.tooltips.notes')}
              />
          </Grid>
          <Grid
            item
            className={ styles.filterItem }
            xs={ 12 }
            md={ 3 }
            sm={ 4 }
          >
            <EitherFilter
              id='progresses_tramit_observation_present'
              title={t('procedureBox.filterAdvanced.details.observations')}
              tooltip={t('procedureBox.filterAdvanced.tooltips.observations')}
              />
          </Grid>
          <Grid
            item
            className={ styles.filterItem }
            xs={ 12 }
            md={ 3 }
            sm={ 4 }
          >
            <EitherFilter
              id='list_activity_description_present'
              title={t('procedureBox.filterAdvanced.details.resume')}
              tooltip={t('procedureBox.filterAdvanced.tooltips.resume')}
              />
          </Grid>
        </Grid>
      </GroupFilter>
      <FilterButtons closeFilter={props.closeFilter} />
    </div>
  );
}
